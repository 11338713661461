import * as crypto from "crypto-browserify";
import { Buffer } from 'buffer';
window.Buffer = Buffer;

const ALGORITHM = "aes-256-gcm";
const IV_LENGTH = 16;
const SALT_LENGTH = 64;
const TAG_LENGTH = 16;
const TAG_POSITION = SALT_LENGTH + IV_LENGTH;
const ENCRYPTED_POSITION = TAG_POSITION + TAG_LENGTH;

const DO_DEBUG = true;

export class AES_GCM {
  constructor(private secret: string) {}
  getKey(salt: Buffer): Promise<Buffer> {
    return new Promise((resolve, reject) => {
      crypto.pbkdf2(this.secret, salt, 100000, 32, "sha512", (err, derivedKey) => {
        if (err) {
          reject(err);
        } else {
          resolve(derivedKey);
        }
      });
    });
  }
  
  async encrypt(plainText: string) {
    const iv = Buffer.from(crypto.randomBytes(IV_LENGTH));
    const salt = Buffer.from(crypto.randomBytes(SALT_LENGTH));
    
    // Wait for the derived key
    const key = await this.getKey(salt);
    const cipher = crypto.createCipheriv(ALGORITHM, key, iv);
    const plainTextCipher = Buffer.concat([
      cipher.update(String(plainText), "utf8"),
      cipher.final()
    ]);
    
    const tag = cipher.getAuthTag();
    const data = Buffer.concat([salt, iv, tag, plainTextCipher]);
    const base64Data = data.toString("base64");
  
    if (DO_DEBUG) {
      console.log(`Salt (${salt.length} bytes): ${salt.toString('hex')}`);
      console.log(`IV (${iv.length} bytes): ${iv.toString('hex')}`);
      console.log(`Tag (${tag.length} bytes): ${tag.toString('hex')}`);
      console.log(`Derived key: ${key.toString('hex')}`);
      console.log(`Encrypted Data base64: ${base64Data}`);
      console.log(`Encrypted data length: ${data.length} bytes, hex ${data.toString('hex')}`);
    }
  
    return base64Data;
  }

  async decrypt(cipherText: string): Promise<string> {
    // Convert the base64-encoded string back to a Buffer.
    const dataBuffer = Buffer.from(cipherText, "base64");
  
    // Extract the salt, IV, tag, and encrypted data using the known lengths.
    const salt = dataBuffer.subarray(0, SALT_LENGTH);
    const iv = dataBuffer.subarray(SALT_LENGTH, SALT_LENGTH + IV_LENGTH);
    const tag = dataBuffer.subarray(SALT_LENGTH + IV_LENGTH, SALT_LENGTH + IV_LENGTH + TAG_LENGTH);
    const encrypted = dataBuffer.subarray(SALT_LENGTH + IV_LENGTH + TAG_LENGTH);
  
    // Asynchronously derive the key using the provided salt.
    const key = await this.getKey(salt);
  
    // Initialize the decipher with the algorithm, key, and IV.
    const decipher = crypto.createDecipheriv(ALGORITHM, key, iv);
    decipher.setAuthTag(tag);
  
    // Decrypt the encrypted data.
    let decrypted: Buffer;
    try {
      decrypted = Buffer.concat([decipher.update(encrypted), decipher.final()]);
    } catch (error) {
      console.error("Decryption failed:", error);
      throw error;
    }
  
    // Return the decrypted plaintext.
    return decrypted.toString("utf8");
  }  
  
}
