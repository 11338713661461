// Devices.tsx

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ColumnsType } from 'antd/es/table';
import { debounce } from "lodash";
import { LockTwoTone, UnlockTwoTone } from "@ant-design/icons";
import {
  AutoComplete,
  Badge,
  Button,
  Card,
  Form,
  Input,
  notification,
  Select,
  Switch,
  Table,
  Tag,
  DatePicker,
  Space,
} from "antd";
import { useRecoilState } from "recoil";
import { userAtom, userSecretsAtom } from "../../common/Atoms";
import { getDateWithTooltip } from "../../utils/utils";
import AxiosApiInstance from "../../common/Interceptors";
import ModalPromptMasterKey from "../../common/ModalPromptMasterKey";
import { URL_API_V1 } from "../../constants/global";
import DevicesModalComponent from "./DeviceModalViewForm";
import { usePIIFields } from "../../contexts/PIIFieldsContext";
import { useDeviceCharts } from "contexts/DeviceChartsContext";
import { useDevice } from "contexts/DeviceContext";
import OpenPortsCell from "./OpenPortsCell";
import { EventsTableProvider } from "contexts/EventsTableContext";
import { TimeRangeProvider } from "contexts/TimeRangeContext";
import ConnectionsScatterChart from "./ConnectionScatterChart"; // Existing Connections Chart
import { Device, OpenPort } from "../../types/DeviceTypes";
import ActivityChart from "./ActivityChart"; // Import the ActivityChart component
import BandwidthChart from "./BandwidthChart"; // New Bandwidth Chart Component
import ConnectionsChart from "./ConnectionsChart"; // New Connections Chart Component
import GlobalBandwidthChart from "./GlobalBandwidthChart"; // Global Bandwidth Chart

const { RangePicker } = DatePicker;
const { Option } = Select;

function getIPAddressVersion(row: Device) {
  return (
    <Tag key={row.ipVersion}>
      {row.ipVersion === "ipV4"
        ? "IPv4"
        : row.ipVersion === "ipV6"
        ? "IPv6"
        : "Unknown"}
    </Tag>
  );
}

function Devices() {
  const { axiosApiInstance } = AxiosApiInstance();
  const [user] = useRecoilState(userAtom);
  const [userSecrets, setUserSecrets] = useRecoilState(userSecretsAtom);
  const [ipAddressOptions, setIpAddressOptions] = useState<string[]>([]);
  const {
    selectedItem,
    setSelectedItem,
    showDeviceModal,
    setShowDeviceModal,
    selectedHost,
    setSelectedHost,
  } = useDevice();
  const { doUpdateChart } = useDeviceCharts();
  const {
    maskingTechniques,
    getIconIfPiiFieldDevices,
    decryptedPiiFieldsDevices,
  } = usePIIFields();
  const [columns, setColumns] = useState<ColumnsType<Device>>([]);
  const { showModalMasterKey, setShowModalMasterKey } = usePIIFields();
  const [form] = Form.useForm();
  const [filters, setFilters] = useState({
    ipVersion: ["ipV4", "ipV6"],
    ipAddress: "",
    ports: [],
    timeRange: [],
  });

  const [pagination, setPagination] = useState({
    current: 1,       // page number
    pageSize: 10,     // items per page
    total: 0,         // total items across all pages
  });

  // State for open ports
  const [openPortsMap, setOpenPortsMap] = useState<{
    [ipAddress: string]: { loading: boolean; ports: OpenPort[]; error?: string };
  }>({});

  // State for selected chart type
  const [selectedChartType, setSelectedChartType] = useState<'bandwidth' | 'connections'>('bandwidth');
  const allDevicesMetricsEndpoint = `${URL_API_V1}/r/${user.organization}/metrics/devices`;
  const devicesConnectionsMetricsEndpoint = `${URL_API_V1}/r/${user.organization}/metrics/devices-connections`;

  function handleViewDeviceClick(deviceRow: Device) {
    setSelectedItem(deviceRow);
    setShowDeviceModal(true);
    setSelectedHost(deviceRow.ipAddress); // Sets selectedHost to the clicked IP
    console.log("handleViewDeviceClick: ", deviceRow);
  }

  // Initialize columns
  const defaultColumns: ColumnsType<Device> = useMemo(
    () => [
      {
        title: "IP Address",
        dataIndex: "ipAddress",
        key: "ipAddress",
        sorter: (a: Device, b: Device) => a.ipAddress.localeCompare(b.ipAddress),
        render: (text: string, row: Device) => (
          <span>
            {text}
            {/* Optional: Additional rendering */}
          </span>
        ),
      },
      {
        title: "IP Version",
        dataIndex: "ipVersion",
        key: "ipVersion",
        sorter: (a: Device, b: Device) => a.ipVersion.localeCompare(b.ipVersion),
        render: (text: string, row: Device) => getIPAddressVersion(row),
      },
      {
        title: "Open ports",
        dataIndex: "openPorts",
        key: "openPorts",
        sorter: (a: Device, b: Device) =>
          (a.openPorts ? a.openPorts.length : 0) -
          (b.openPorts ? b.openPorts.length : 0),
        render: (text: OpenPort[], row: Device) => (
          <OpenPortsCell
            ipAddress={row.ipAddress}
            organization={user.organization}
            openPortsMap={openPortsMap}
            setOpenPortsMap={setOpenPortsMap}
          />
        ),
      },
      {
        title: "First seen",
        dataIndex: "createdAt",
        key: "createdAt",
        sorter: (a: Device, b: Device) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        render: (text: string) => getDateWithTooltip(text),
      },
      {
        title: "Last seen",
        dataIndex: "updatedAt",
        key: "updatedAt",
        sorter: (a: Device, b: Device) =>
          new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
        render: (text: string) => getDateWithTooltip(text),
      },
      {
        title: "Activity",
        key: "activity",
        render: (_: any, record: Device) => (
          <ActivityChart deviceIp={record.ipAddress} />
        ),
      },
      {
        title: "Actions",
        key: "actions",
        render: (_: any, record: Device) => (
          <Button type="link" onClick={() => handleViewDeviceClick(record)}>
            View
          </Button>
        ),
      },
    ],
    [user.organization, decryptedPiiFieldsDevices, openPortsMap]
  );

  useEffect(() => {
    setColumns(defaultColumns);
  }, [defaultColumns]);

  const [props, setProps] = useState<{
    loading: boolean;
    hasData: boolean;
    data: Device[];
    showPIIsInClear: boolean;
    total: number;
  }>({
    loading: true,
    hasData: false,
    data: [],
    showPIIsInClear: false,
    total: 0,
  });

  const handleFormValuesChange = (changedValues: any, allValues: any) => {
    if (!allValues.ipAddress) {
      setSelectedHost(undefined);
    }

    setFilters({
      ipVersion: allValues.ipVersion || ["ipV4", "ipV6"],
      ipAddress: allValues.ipAddress || "",
      ports: allValues.ports || [],
      timeRange: allValues.timeRange || [],
    });
  };

  const handleFormSubmit = (values: any) => {
    if (values.ipAddress) {
      setSelectedHost(values.ipAddress);
    } else {
      setSelectedHost(undefined);
    }

    // Reset pagination to page 1
    setPagination(prev => ({
      ...prev,
      current: 1,
    }));

    // Fetch devices with page 1
    fetchDevices({ ...values }, 1, pagination.pageSize);
  };

  const fetchDevices = useCallback(
    async (values: any, page: number = 1, limit: number = 10) => { // Default to page 1, limit 10
      if (!user.organization) {
        notification.error({
          message: "Organization Not Selected",
          description: "Please select an organization before fetching devices.",
          key: "organization-not-selected",
        });
        setProps((prevProps) => ({ ...prevProps, loading: false }));
        return;
      }

      doUpdateChart();
      setProps((prevProps) => ({ ...prevProps, loading: true }));

      const { ipAddress, ports, timeRange } = values;

      // Format time range
      let formattedTimeRange = {};
      if (timeRange && timeRange.length === 2) {
        formattedTimeRange = {
          startTime: timeRange[0].toISOString(),
          endTime: timeRange[1].toISOString(),
        };
      }

      // Build query parameters
      const params = {
        ipVersion: ["ipV4", "ipV6"],
        ipAddress: ipAddress || undefined,
        ports: ports && ports.length > 0 ? ports.join(",") : undefined,
        ...formattedTimeRange,
        page: page,
        limit: limit,
      };

      try {
        // Fetch devices from API
        const response = await axiosApiInstance.get(
          `${URL_API_V1}/r/${user.organization}/devices/`,
          { params }
        );

        console.log("API Response:", response.data);

        if (response && response.status === 200) {
          const { devices, total } = response.data;

          // Update props and pagination state
          setProps((prevProps) => ({
            ...prevProps,
            data: devices,
            total: total, // Use the `total` value from the API response
            loading: false,
          }));
          setPagination((prev) => ({
            ...prev,
            total: total, // Sync pagination total with the API response
            current: page, // Set current page to the fetched page
            pageSize: limit, // Update page size if it has changed
          }));
        }
      } catch (error) {
        console.error("Error fetching devices:", error);
        setProps((prevProps) => ({ ...prevProps, loading: false }));
        notification.error({
          message: "Error fetching devices",
          description: "Unable to fetch devices data.",
          key: "error-fetch-devices",
          duration: 2,
        });
      }
    },
    [pagination.current, pagination.pageSize, user.organization]
  );

  const processedData = useMemo(() => {
    return (props.data || []).map((row) => {
      const piiDeviceMatch = decryptedPiiFieldsDevices.find(
        (item) =>
          item.encryptedValue === row.ipAddress ||
          item.decryptedValue === row.ipAddress
      );

      if (piiDeviceMatch) {
        return {
          ...row,
          ipAddress: piiDeviceMatch.showInClear
            ? piiDeviceMatch.decryptedValue
            : piiDeviceMatch.encryptedValue,
        };
      }

      return row;
    });
  }, [props.data, decryptedPiiFieldsDevices]);

  const handleSearch = useMemo(
    () =>
      debounce((value: string) => {
        const uniqueData = props.data.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.ipAddress === item.ipAddress)
        );

        console.log("uniqueData: ", uniqueData);
        const ipAddressSuggestions = uniqueData
          .map((row) => row.ipAddress)
          .filter((ipAddress) => ipAddress.includes(value));
        setIpAddressOptions(ipAddressSuggestions);
      }, 300),
    [props.data]
  );

  useEffect(() => {
    return () => {
      handleSearch.cancel();
    };
  }, [handleSearch]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      ipVersion: ["ipV4", "ipV6"],
    }));
  }, []);

  // **Modified useEffect to fetch devices with initial filters and reset page**
  useEffect(() => {
    form
      .validateFields()
      .then((vals) => {
        fetchDevices(vals, pagination.current, pagination.pageSize);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [fetchDevices, form]); // Added fetchDevices and form to dependencies

  function onShowInClear(checked: boolean) {
    if (checked && userSecrets.DECRYPTION_KEYS_OK !== true) {
      setShowModalMasterKey(true);
    }

    setProps((prevProps) => ({ ...prevProps, showPIIsInClear: checked }));
  }

  const promptMasterKey = () => {
    if (userSecrets.DECRYPTION_KEYS_OK !== true) {
      setShowModalMasterKey(true);
    }
  };

  const clearMasterKey = () => {
    setUserSecrets({
      ...userSecrets,
      DECRYPTION_KEYS_OK: false,
      DECRYPTION_KEYS: {},
    });
  };

  const showLockIcon =
    userSecrets.DECRYPTION_KEYS_OK !== true ? (
      <LockTwoTone onClick={promptMasterKey} />
    ) : (
      <UnlockTwoTone twoToneColor="#52c41a" onClick={clearMasterKey} />
    );

  // Derive port options dynamically or define them statically
  const portOptions = useMemo(() => {
    const portsSet = new Set<number>();

    props.data.forEach((device) => {
      device.openPorts?.forEach((port) => {
        const portNumber = Number(port.port); // Normalize to a number
        if (!isNaN(portNumber)) {
          portsSet.add(portNumber);
        }
      });
    });

    const deduplicatedPorts = Array.from(portsSet);
    console.log("Deduplicated Ports:", deduplicatedPorts); // Debugging
    return deduplicatedPorts.sort((a, b) => a - b); // Sort in ascending order
  }, [props.data]);

  return (
    <>
      <Card title="Devices" style={{ margin: "0px 25px 0px 25px" }}>
        {/* Search/Filter Section */}
        <Card style={{ marginBottom: "20px" }}>
          <Form
            form={form}
            onFinish={handleFormSubmit}
            onValuesChange={handleFormValuesChange}
            layout="inline"
          >
            <Form.Item
              name="ipAddress"
              rules={[{ required: false }]}
              style={{ marginRight: 8 }}
            >
              <AutoComplete
                onSearch={handleSearch}
                placeholder="IP Address"
                options={ipAddressOptions.map((ip) => ({ value: ip }))}
                allowClear
                style={{ width: 200 }}
              >
                <Input />
              </AutoComplete>
            </Form.Item>

            {/* Ports Filter */}
            <Form.Item
              name="ports"
              rules={[{ required: false }]}
              style={{ marginRight: 8 }}
            >
              <Select
                mode="tags"
                allowClear
                placeholder="Select Ports"
                style={{ width: 200 }}
              >
                {portOptions.map(port => (
                  <Option key={port} value={port}>
                    {port}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {/* Time Range Filter */}
            <Form.Item
              name="timeRange"
              rules={[{ required: false }]}
              style={{ marginRight: 8 }}
            >
              <RangePicker
                showTime
                format="YYYY-MM-DD HH:mm:ss"
                style={{ width: 300 }}
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </Form>
        </Card>

        {/* Chart Selection Controls */}
        <Card style={{ marginBottom: "20px" }}>
          <Space style={{ marginBottom: 16 }}>
            <Button
              type={selectedChartType === 'bandwidth' ? 'primary' : 'default'}
              onClick={() => setSelectedChartType('bandwidth')}
            >
              Bandwidth
            </Button>
            <Button
              type={selectedChartType === 'connections' ? 'primary' : 'default'}
              onClick={() => setSelectedChartType('connections')}
            >
              Number of Connections
            </Button>
          </Space>

          {selectedChartType === 'bandwidth' ? (
            <GlobalBandwidthChart deviceIp={selectedHost  || ''} />
          ) : (
            <ConnectionsChart 
              deviceIp={selectedHost || ''} 
            />
          )}
        </Card>

        {/* Filters and Summary Section */}
        <div style={{ marginBottom: "20px", textAlign: "right" }}>
          <span style={{ marginRight: "2em" }}>
            Show in clear:{" "}
            <Switch
              checked={props.showPIIsInClear}
              onChange={onShowInClear}
            />
          </span>
          <span style={{ marginRight: "2em" }}>{showLockIcon}</span>
          <span>
            Total:{" "}
            <Badge
              showZero
              count={props.total}
              overflowCount={10000000}
              style={{ backgroundColor: "gray" }}
            />
          </span>
        </div>

        {/* **Modified Table Pagination to Reset Page to 1 on New Filters** */}
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={processedData}
          loading={props.loading}
          pagination={{
            pageSize: pagination.pageSize,
            current: pagination.current,
            total: pagination.total, // Updated to use dynamic total from the API response
            onChange: (page, pageSize) => {
              setPagination((prev) => ({ ...prev, current: page, pageSize }));
              fetchDevices({ ...filters }, page, pageSize); // Pass current filters, new page, and new pageSize
            },
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50", "100"],
          }}
        />
      </Card>

      <EventsTableProvider>
        <TimeRangeProvider>
          <DevicesModalComponent
            device={{
              ipAddress: selectedItem?.ipAddress || "",
              createdAt: selectedItem?.createdAt || "",
              updatedAt: selectedItem?.updatedAt || "",
            }}
          />
        </TimeRangeProvider>
      </EventsTableProvider>
      <ModalPromptMasterKey
        isShowing={showModalMasterKey}
        hide={() => setShowModalMasterKey(!showModalMasterKey)}
      />
    </>
  );
}

export default Devices;
