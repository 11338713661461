import React, { useEffect } from "react";
import { Spin, Tag, Tooltip, List } from "antd";
import AxiosApiInstance from "../../common/Interceptors";
import { URL_API_V1 } from "../../constants/global";
import { Device, OpenPort } from "../../types/DeviceTypes";

interface OpenPortsMap {
  [ipAddress: string]: {
    loading: boolean;
    ports: OpenPort[];
    error?: string;
  };
}

interface OpenPortsCellProps {
  ipAddress: string;
  organization?: string;
  openPortsMap: OpenPortsMap;
  setOpenPortsMap: React.Dispatch<React.SetStateAction<OpenPortsMap>>;
}

const OpenPortsCell: React.FC<OpenPortsCellProps> = ({
  ipAddress,
  organization,
  openPortsMap,
  setOpenPortsMap,
}) => {
  const { axiosApiInstance } = AxiosApiInstance();

  useEffect(() => {
    let isMounted = true;

    // Skip if we have data for this IP
    if (openPortsMap[ipAddress]) {
      return;
    }

    // Mark it loading
    setOpenPortsMap((prev) => ({
      ...prev,
      [ipAddress]: { loading: true, ports: [] },
    }));

    // Fetch data
    const fetchOpenPorts = async () => {
      try {
        const response = await axiosApiInstance.get(
          `${URL_API_V1}/r/${organization}/devices/${ipAddress}`
        );
        const device = response.data.devices?.[0]; // Adjusted to access the nested structure
        if (response.status === 200 && device && Array.isArray(device.openPorts)) {
          if (isMounted) {
            setOpenPortsMap((prev) => ({
              ...prev,
              [ipAddress]: { loading: false, ports: device.openPorts },
            }));
          }
        } else if (isMounted) {
          setOpenPortsMap((prev) => ({
            ...prev,
            [ipAddress]: { loading: false, ports: [], error: "" },
          }));
        }
      } catch (error) {
        if (isMounted) {
          setOpenPortsMap((prev) => ({
            ...prev,
            [ipAddress]: { loading: false, ports: [], error: "Failed to load ports" },
          }));
        }
      }
    };

    fetchOpenPorts();
    return () => {
      isMounted = false;
    };
  }, []);
  // Notice: openPortsMap is NOT in dependencies

  // console.log("Rendering OpenPortsCell for IP:", ipAddress);

  // Now check the data
  const openPortsData = openPortsMap[ipAddress];

  if (!openPortsData || openPortsData.loading) {
    return <Spin size="small" />;
  }

  if (openPortsData.error) {
    return <Tag color="red">{openPortsData.error}</Tag>;
  }

  const { ports } = openPortsData;
  const displayPorts = ports.slice(0, 3);
  const remainingPorts = ports.length - displayPorts.length;

  return (
    <span>
      {ports.length} open port{ports.length !== 1 ? "s" : ""}:
      {displayPorts.map((portObj) => (
        <Tag key={portObj.port} color="blue">
          {portObj.port}
        </Tag>
      ))}
      {remainingPorts > 0 && (
        <Tooltip
          title={
            <List
              size="small"
              dataSource={ports}
              renderItem={(portObj) => (
                <List.Item>
                  <Tag color="blue">{portObj.port}</Tag>
                  {portObj.protocol && <span style={{ marginLeft: 8 }}>{portObj.protocol}</span>}
                  {portObj.service && <span style={{ marginLeft: 8 }}>{portObj.service}</span>}
                </List.Item>
              )}
            />
          }
        >
          <Tag>...</Tag>
        </Tooltip>
      )}
    </span>
  );
};

export default OpenPortsCell;
