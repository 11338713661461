// ActivityChart.tsx

import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { Spin, notification } from 'antd';
import { URL_API_V1 } from '../../constants/global';
import AxiosApiInstance from '../../common/Interceptors';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import { styleModeAtom, userAtom } from '../../common/Atoms';

interface ActivityChartProps {
  deviceIp: string;
}

interface ConnectionDataPoint {
  distinct_connection_count: number;
  src_ip: string;
  window_start: string;
}

const ActivityChart: React.FC<ActivityChartProps> = ({ deviceIp }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [chartData, setChartData] = useState<ConnectionDataPoint[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [user] = useRecoilState(userAtom);
  const { axiosApiInstance } = AxiosApiInstance();
  const [themeState] = useRecoilState(styleModeAtom);
  const isDarkTheme = themeState.theme === 'dark';

  const fetchActivityData = async () => {
    if (!user.organization) {
      notification.error({
        message: 'Organization Not Selected',
        description: 'Please select an organization before fetching device activity.',
        key: 'organization-not-selected-activity',
      });
      setLoading(false);
      return;
    }

    try {
      const response = await axiosApiInstance.get(
        `${URL_API_V1}/r/${user.organization}/metrics/devices-connections`,
        { params: { src_ip: deviceIp } }
      );

      if (response && response.status === 200) {
        setChartData(response.data);
      } else {
        notification.error({
          message: 'Error fetching activity data',
          description: 'Unable to fetch activity data.',
          key: 'error-fetch-activity-data',
          duration: 2,
        });
      }
    } catch (error) {
      console.error('Error fetching activity data:', error);
      notification.error({
        message: 'Error fetching activity data',
        description: 'An error occurred while fetching activity data.',
        key: 'error-fetch-activity-data',
        duration: 2,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchActivityData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceIp]);

  useEffect(() => {
    if (!chartRef.current || chartData.length === 0) return;

    const myChart = echarts.init(chartRef.current, isDarkTheme ? 'dark' : 'light');

    const options: echarts.EChartsOption = {
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => {
          if (params.length === 0) return '';
          const data = params[0].data;
          return `
            <div>
              <strong>${moment(data[0]).format('HH:mm')}</strong><br/>
              Connections: ${data[1]}
            </div>
          `;
        },
        axisPointer: {
          type: 'cross',
        },
      },
      grid: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        containLabel: false,
      },
      xAxis: {
        type: 'time',
        show: false,
      },
      yAxis: {
        type: 'value',
        show: false,
      },
      series: [
        {
          type: 'line',
          showSymbol: false,
          data: chartData.map((point) => [
            moment(point.window_start).valueOf(),
            point.distinct_connection_count,
          ]),
          areaStyle: {
            color: isDarkTheme ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)',
          },
          lineStyle: {
            color: '#1890ff',
          },
        },
      ],
    };

    myChart.setOption(options);

    const resizeHandler = () => {
      myChart.resize();
    };
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
      myChart.dispose();
    };
  }, [chartData, isDarkTheme]);

  if (loading) {
    return <Spin size="small" />;
  }

  if (chartData.length === 0) {
    return <span>No Activity</span>;
  }

  return <div ref={chartRef} style={{ width: '100%', height: '50px' }} />;
};

export default React.memo(ActivityChart);
