import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  Card,
  Tooltip,
  Badge,
  Button,
  Switch,
  Pagination,   // <-- Import from 'antd'
  Progress,
} from "antd";
import {
  CaretDownOutlined,
  CaretRightOutlined,
  LockTwoTone,
  UnlockTwoTone,
  MenuOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useRecoilState } from "recoil";

import { styleModeAtom, userAtom } from "../../common/Atoms";
import { useEventsTableContext } from "../../contexts/EventsTableContext";
import { usePIIFields } from "../../contexts/PIIFieldsContext";
import TableHeader from "./TableHeader";

import { userSecretsAtom } from "common/Atoms";
import { FIELD_TO_EXCLUDE } from "hooks/getEvents";
import { PAGE_SIZE } from "./HistogramV3";
import { Content } from "antd/lib/layout/layout";

const FIXED_FIELDS = [
  { key: 'ingestion_id', label: 'ID' },
  { key: '_timestamp', label: 'Timestamp' },
  { key: 'meta_ss_event_type', label: 'Event Type' }
] as const;

const Separator = ({ isCompact }: { isCompact?: boolean }) => (
  <div
    style={{
      width: isCompact ? "2px" : "4px",
      height: isCompact ? "2px" : "4px",
      backgroundColor: "#d9d9d9",
      margin: isCompact ? "0 2px" : "0 4px",
      flexShrink: 0,
    }}
  />
);

interface EventCardLayoutProps {
  fetchEvents: (
    startTime: number,
    endTime: number,
    offset: number,
    pageSize: number,
    queryTerms?: any
  ) => Promise<any>;
  setTableData: React.Dispatch<React.SetStateAction<any[]>>;
  tableData: any[];
  totalCount: number;
  startTime: number;
  endTime: number;
  searchVersion: number;
  queryTerms: any;
}

const EventCardLayout: React.FC<EventCardLayoutProps> = ({
  fetchEvents,
  setTableData,
  tableData,
  totalCount,
  startTime,
  endTime,
  searchVersion,
  queryTerms,
}) => {
  // Keep track of page, pageSize, and loading
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [loading, setLoading] = useState(false);

  const [themeState] = useRecoilState(styleModeAtom);
  const textColor =
  themeState.theme === "dark"
    ? "white"
    : "black";
  

  // Context values (some might be unused here)
  const {
    isLoading,
    isSelectFieldsViewVisible,
    setIsSelectFieldsViewVisible,
  } = useEventsTableContext();

  // PII logic
  const {
    decryptedPiiFields,
    showPIIsInClear,
    setShowPIIsInClear,
    showModalMasterKey,
    setShowModalMasterKey,
  } = usePIIFields();

  const [userSecrets, setUserSecrets] = useRecoilState(userSecretsAtom);

  // Flatten the "events" field
  const flattenedData = useMemo(() => {
    return tableData.map((record) => {
      const flatRecord = { ...record };
      if (typeof record.events === "string") {
        record.events.split("\n").forEach((line) => {
          const [k, v] = line.split(": ");
          if (k && k.trim() !== "") {
            flatRecord[k.trim()] = v ? v.trim() : "";
          }
        });
      }
      return flatRecord;
    });
  }, [tableData]);

  // Expand/collapse logic
  const [expandedRows, setExpandedRows] = useState<Set<string | number>>(new Set());
  const [expandAll, setExpandAll] = useState(false);

  const toggleExpand = (id: string | number) => {
    setExpandedRows(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const toggleExpandAll = () => {
    setExpandAll((prev) => !prev);
    if (!expandAll) {
      // expand everything
      const allIds = flattenedData.map(
        (item, idx) => item.id || item.key || idx
      );
      setExpandedRows(new Set(allIds));
    } else {
      setExpandedRows(new Set());
    }
  };

  // Single function to load data for the *current* page
  const loadMoreData = useCallback(
    async (pageNumber: number, pageSizeNumber: number, currentQueryTerms: any) => {
      if (loading) return;

      setLoading(true);
      const offset = (pageNumber - 1) * pageSizeNumber;
      try {
        const result = await fetchEvents(
          startTime,
          endTime,
          offset,
          pageSizeNumber,
          currentQueryTerms
        );
        if (result && result.data) {
          setTableData(result.data);
        }
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false);
      }
    },
    [fetchEvents, startTime, endTime, loading, setTableData]
  );

  /**
   * Whenever page, pageSize, queryTerms, or searchVersion changes,
   * call loadMoreData with the *latest* page & pageSize.
   */
  useEffect(() => {
    loadMoreData(page, pageSize, queryTerms);
  }, [page, pageSize, queryTerms, searchVersion]);

  // If you need to handle decrypted fields, do so here:
  useEffect(() => {
    // ...
  }, [decryptedPiiFields]);

  // Helper to display lines from item.events
  const renderEventItems = (lineString: string) => {
    if (!lineString) return null;
    const lines = lineString.split("\n")
      .filter(line => line.trim())
      .map(line => {
        const [key, ...valueParts] = line.split(": ");
        return {
          key: key?.trim(),
          value: valueParts.join(": ").trim()
        };
      });

    return (
      <div style={{ 
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        gap: '8px',
        width: '100%',
        maxWidth: '100%',
      }}>
        {lines.map((item, idx) => (
          <div
            key={idx}
            style={{ 
              display: 'flex',
              padding: '4px 8px',
              fontSize: '13px',
              lineHeight: '1.4',
              borderBottom: '1px solid rgba(0,0,0,0.06)',
              gap: '8px',
              width: '100%',
            }}
          >
            <div style={{ 
              fontWeight: 500,
              color: 'rgba(0,0,0,0.65)',
              flexShrink: 0,
              minWidth: '80px',
              maxWidth: '80px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
              {item.key}:
            </div>
            <div style={{ 
              flex: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
              {item.value}
            </div>
          </div>
        ))}
      </div>
    );
  };

  // === New: Pagination event handlers ===
  const handlePaginationChange = (newPage: number, newPageSize?: number) => {
    // If user changes just the page (e.g., next page click), newPageSize might be undefined
    // fallback to the existing pageSize
    setPage(newPage);
    if (newPageSize && newPageSize !== pageSize) {
      setPageSize(newPageSize);
    }
  };

  const handlePageSizeChange = (current: number, newSize: number) => {
    // 'current' here is the new page index after changing page size
    setPage(current);
    setPageSize(newSize);
  };

  // Add new state for promoted fields
  const [promotedFields, setPromotedFields] = useState<string[]>([]);

  // Add handler for promoting fields
  const handlePromoteField = (fieldKey: string) => {
    setPromotedFields(prev => [...new Set([...prev, fieldKey])]);
  };

  // Define exact column widths
  const COLUMN_WIDTHS = {
    expandIcon: '32px',
    id: '140px',
    timestamp: '200px',
    eventType: '160px',
    promoted: '160px', // default width for promoted fields
  } as const;

  // Helper function to generate grid template columns with exact widths
  const getGridTemplateColumns = (promotedFieldsCount: number) => {
    return `${COLUMN_WIDTHS.expandIcon} ${COLUMN_WIDTHS.id} ${COLUMN_WIDTHS.timestamp} ${COLUMN_WIDTHS.eventType} ${`${COLUMN_WIDTHS.promoted} `.repeat(promotedFieldsCount).trim()}`;
  };

  // Common styles for grid container
  const gridContainerStyle = (promotedFieldsCount: number) => ({
    display: "grid",
    gridTemplateColumns: getGridTemplateColumns(promotedFieldsCount),
    gap: "4px",
    alignItems: "center",
    width: "100%",
    maxWidth: "100%",
  });

  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      {/* Expand/Collapse All Button */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "4px 8px",
        }}
      >
        <div
          style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: "4px" }}
          onClick={toggleExpandAll}
        >
          {expandAll ? (
            <>
              <CaretDownOutlined style={{ fontSize: "12px", color: textColor }} />
              <span style={{ color: textColor }}>Collapse All</span>
            </>
          ) : (
            <>
              <CaretRightOutlined style={{ fontSize: "12px", color: textColor }} />
              <span style={{ color: textColor }}>Expand All</span>
            </>
          )}
        </div>
      </div>

      {/* Header Card */}
      <Card
        size="default"
        style={{
          width: "100%",
          marginBottom: "8px",
          borderRadius: "8px",
          boxShadow: "0 1px 2px rgba(0, 0, 0, 0.03)",
          backgroundColor: "rgba(0, 0, 0, 0.02)",
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
        bodyStyle={{
          padding: "12px",
          width: "100%",
          maxWidth: "100%",
          overflow: "hidden" // Prevent content from causing width issues
        }}
      >
        <div style={{
          ...gridContainerStyle(promotedFields.length),
          fontWeight: 600,
        }}>
          {/* Empty space for expand icon */}
          <div style={{ width: COLUMN_WIDTHS.expandIcon }} />
          
          {/* Fixed field names */}
          {FIXED_FIELDS.map(field => (
            <div key={field.key} style={{ 
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            }}>
              {field.label}
            </div>
          ))}

          {/* Promoted field names */}
          {promotedFields.map(fieldKey => (
            <div key={fieldKey} style={{ 
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            }}>
              {fieldKey}
              <div
                onClick={() => setPromotedFields(prev => prev.filter(f => f !== fieldKey))}
                style={{ 
                  cursor: 'pointer',
                  padding: '0 4px',
                  opacity: 0.6,
                  transition: 'opacity 0.2s',
                  flexShrink: 0
                }}
                onMouseEnter={(e) => e.currentTarget.style.opacity = '1'}
                onMouseLeave={(e) => e.currentTarget.style.opacity = '0.6'}
              >
                ×
              </div>
            </div>
          ))}
        </div>
      </Card>

      {isLoading || loading ? (
        <div style={{ textAlign: "center", marginTop: 20, color:textColor }}>Loading...</div>
      ) : (
        <>
          {/* Render each record as a Card */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "4px",
              padding: "4px",
            }}
          >
            {flattenedData.map((item, idx) => {
              const itemId = item.id || item.key || idx;

              return (
                <Card
                  key={itemId}
                  style={{
                    width: "100%",
                    marginBottom: "4px",
                    borderRadius: "4px",
                    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.03)",
                    cursor: "pointer",
                  }}
                  bodyStyle={{
                    padding: "12px",
                    width: "100%",
                    maxWidth: "100%",
                    overflow: "hidden"
                  }}
                  onClick={() => toggleExpand(itemId)}
                >
                  <div style={{ 
                    display: "flex", 
                    flexDirection: "column", 
                    gap: "4px",
                    width: "100%"
                  }}>
                    <div style={gridContainerStyle(promotedFields.length)}>
                      {/* Expand icon */}
                      <div style={{ width: COLUMN_WIDTHS.expandIcon }}>
                        <div style={{
                          position: 'relative',
                          width: '24px',
                          height: '24px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: 0,
                          margin: 0
                        }}>
                          {expandedRows.has(itemId) ? (
                            <MinusOutlined
                              style={{ 
                                fontSize: '12px',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 2,
                                cursor: 'pointer'
                              }}
                            />
                          ) : (
                            <PlusOutlined
                              style={{ 
                                fontSize: '12px',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 2,
                                cursor: 'pointer'
                              }}
                            />
                          )}
                        </div>
                      </div>

                      {/* Fixed fields */}
                      {FIXED_FIELDS.map(field => (
                        <Tooltip key={field.key} title={`${field.label}: ${item[field.key]}`}>
                          <div style={{ 
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                          }}>
                            <span style={{ fontWeight: 500 }}>{item[field.key]}</span>
                          </div>
                        </Tooltip>
                      ))}

                      {/* Promoted fields */}
                      {promotedFields.map(fieldKey => (
                        <div key={fieldKey} style={{ 
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap"
                        }}>
                          <Tooltip title={`${fieldKey}: ${item[fieldKey]}`}>
                            <span style={{ fontWeight: 500 }}>{item[fieldKey]}</span>
                          </Tooltip>
                        </div>
                      ))}
                    </div>

                    {/* Expanded content */}
                    {expandedRows.has(itemId) && (
                      <div 
                        style={{ 
                          marginTop: "2px", 
                          width: "100%", 
                          maxWidth: "100%",
                        }}
                        onClick={(e: React.MouseEvent) => e.stopPropagation()}
                      >
                        <div style={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                          gap: '4px',
                          width: '100%'
                        }}>
                          {Object.entries(item)
                            .filter(([k]) => 
                              !FIELD_TO_EXCLUDE.includes(k) &&
                              !["ingestion_id", "meta_ss_event_type", "other"].includes(k) &&
                              !promotedFields.includes(k)
                            )
                            .map(([key, value]) => (
                              <Tooltip 
                                key={key} 
                                title={
                                  <div 
                                    onClick={() => handlePromoteField(key)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    Add as field
                                  </div>
                                }
                                mouseEnterDelay={0.5}
                              >
                                <div style={{ 
                                  padding: '4px 8px',
                                  borderBottom: '1px solid rgba(0,0,0,0.06)',
                                  fontSize: '13px',
                                  lineHeight: '1.4',
                                  display: 'flex',
                                  gap: '8px',
                                }}>
                                  <div style={{ 
                                    fontWeight: 500,
                                    flexShrink: 0,
                                    minWidth: '100px',
                                    maxWidth: '100px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                  }}>
                                    {key}:
                                  </div>
                                  <div style={{ 
                                    flex: 1,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                  }}>
                                    {typeof value === 'object' 
                                      ? JSON.stringify(value, null, 2)
                                      : String(value)
                                    }
                                  </div>
                                </div>
                              </Tooltip>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                </Card>
              );
            })}
          </div>

          {/* Pagination Component (Ant Design) */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "4px 8px",
            }}
          >
            <Pagination
              current={page}
              pageSize={pageSize}
              total={totalCount}
              showSizeChanger
              showTotal={(total) => `Total ${total} items`}
              onChange={handlePaginationChange}
              onShowSizeChange={handlePageSizeChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default EventCardLayout;
