// EventsV3.tsx

import { Badge, Button, Card, Dropdown, Menu, Switch } from "antd";
import { LockTwoTone, MenuOutlined, SettingOutlined, UnlockTwoTone } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import { FIELD_TO_EXCLUDE, useFetchEvents } from "hooks/getEvents";
import ModalPromptMasterKey from "common/ModalPromptMasterKey";
import useEventFields from "hooks/getEventsAllFields";
import React, { useEffect, useState } from "react";
import EventDetailsDrawer from "./EventDetailsDrawer";
import EventSelectFieldsViewDrawer from "./EventSelectFieldsViewDrawer";
import EventTable from "./EventsTable";
import { useEventsTableContext } from "../../contexts/EventsTableContext";
import HistogramV3 from "./HistogramV3";
import { usePIIFields } from "../../contexts/PIIFieldsContext";
import SearchComponent from "./SearchComponent";
import { useTimeRange } from "../../contexts/TimeRangeContext";
import EventsTable from "./EventsTable";
import ExpandableTable from "./EventsTableExpandableRows";
import DynamicColumns from "./EventsTableDynamicColumns";
import EventCardLayout from "./EventsTableCardLayout";
import { OrderedComponent } from "types/EventsTableTypes";
import { userSecretsAtom } from "common/Atoms";
import { styleModeAtom, userAtom } from "../../common/Atoms";
import { useRecoilState } from "recoil";
import TableHeader from "./TableHeader";

const EventsV3 = () => {
  const { setPage, selectedEntry, setSelectedEntry } = useEventsTableContext();
  const [searchVersion, setSearchVersion] = useState(0);

  const {
    queryTerms,
    setQueryTerms,
    timeRangeType,
    setTimeRangeType,
    selectedAbsoluteDates,
    setSelectedAbsoluteDates,
    selectedRelativeTime,
  } = useTimeRange();

  const {
    searchComponentRef,
    calculateTimeRange,
    appendEqualsFilter,
  } = useEventsTableContext();

  const { startTime: startTimeInitial, endTime: endTimeInitial } =
    calculateTimeRange(
      timeRangeType,
      selectedRelativeTime,
      selectedAbsoluteDates
    );
  const [startTime, setStartTime] = useState(startTimeInitial);
  const [endTime, setEndTime] = useState(endTimeInitial);

  const { events, totalCount, error, histogram, fetchEvents } =
    useFetchEvents(queryTerms);
  const { eventFields, getAllFields } = useEventFields();
  const { piiFields, decryptedPiiFields, showPIIsInClear, setShowPIIsInClear, decryptValue } =
    usePIIFields();

  const { showModalMasterKey, setShowModalMasterKey } = usePIIFields();
  const { continuousView, setContinuousView, isSelectFieldsViewVisible, setIsSelectFieldsViewVisible } =
    useEventsTableContext();
  const [isEventDetailViewVisible, setIsEventDetailViewVisible] =
    useState(false);

  const [filterFieldsText, setFilterFieldsText] = useState("");

  const [tableData, setTableData] = useState<any[]>([]);

  type ViewType = "EventTable" | "ExpandableTable" | "DynamicColumns" | "EventCardLayout";

  const VIEW_TYPE_LABELS: Record<ViewType, string> = {
    EventTable: "Hybrid",
    ExpandableTable: "Expandable Rows",
    DynamicColumns: "Columns",
    EventCardLayout: "Card Layout",
  };


  const [viewType, setViewType] = useState<ViewType>("EventTable");
  
  const [userSecrets, setUserSecrets] = useRecoilState(userSecretsAtom);
  const [themeState] = useRecoilState(styleModeAtom);

  const textColor =
  themeState.theme === "dark"
    ? "white"
    : "black";

  useEffect(() => {
    //console.log("EventsV3 useEffect events decryptedPiiFields: ", decryptedPiiFields);

    events.forEach((row) => {
      let rowIngestionId = row["ingestion_id"];

      if (showPIIsInClear) {
        piiFields.forEach((piiField) => {
          let fieldName = "payload_" + piiField["fieldName"];
          if (fieldName in row) {
            const decryptedValue = decryptValue(piiField["fieldName"], row[fieldName]);
            row[fieldName] = decryptedValue;
          }
        });
      } else {
        // Decrypt PII fields
        let decryptedValues = decryptedPiiFields.filter(
          (item: any) => item["recordId"] === rowIngestionId
        );
        for (let decryptedPiiFieldValue of decryptedValues) {
          let fieldName = "payload_" + decryptedPiiFieldValue["fieldName"];
          if (fieldName in row) {
            const showInClear = decryptedPiiFieldValue.showInClear;
            const clearValue = decryptedPiiFieldValue.decryptedValue;
            const hiddenValue = decryptedPiiFieldValue.encryptedValue;
            row[fieldName] = showInClear ? clearValue : hiddenValue;
          }
        }
      }
    });

    const updatedData = events.map((item, idx) => {
      const other = Object.entries(item)
        .filter(([key]) => !FIELD_TO_EXCLUDE.includes(key))
        .map(([key, value]) => `${key}: ${value}`)
        .join(", ");
      const seq = 1 + idx;
      return { ...item, key: item.ingestion_id, other, seq };
    });
    setTableData([...updatedData]);
  }, [events, decryptedPiiFields]);

  /**
   * Perform a search by updating startTime, endTime, and incrementing searchVersion.
   * This triggers the EventTable to fetch new data.
   */
  const performSearch = () => {
    console.log("Performing search");
    const { startTime, endTime } = calculateTimeRange(
      timeRangeType,
      selectedRelativeTime,
      selectedAbsoluteDates
    );
    setStartTime(startTime);
    setEndTime(endTime);
    setPage(1);
    setSearchVersion((prev) => prev + 1); // Increment to trigger data reload
  };

  /**
   * Initial data load on component mount.
   */
  useEffect(() => {
    getAllFields();
    performSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handle visibility of event detail view based on selected entry.
   */
  useEffect(() => {
    if (isEmpty(selectedEntry)) {
      setIsEventDetailViewVisible(false);
    } else {
      // Force drawer to close and reopen even for the same entry
      setIsEventDetailViewVisible(false);
      setTimeout(() => setIsEventDetailViewVisible(true), 0);
    }
  }, [selectedEntry]);  

  /**
   * Utility function to check if an object is empty.
   */
  const isEmpty = (obj: object) =>
    Object.keys(obj).length === 0 && obj.constructor === Object;

  const promptMasterKey = () => {
    if (userSecrets.DECRYPTION_KEYS_OK !== true) {
      setShowModalMasterKey(true);
    }
  };

  const clearMasterKey = () => {
    setUserSecrets({ ...userSecrets, DECRYPTION_KEYS_OK: false, DECRYPTION_KEYS: {} });
  };


  const viewOptions = [
    { key: "EventTable", label: "Hybrid" },
    { key: "ExpandableTable", label: "Expandable Rows" },
    { key: "DynamicColumns", label: "Columns" },
    { key: "EventCardLayout", label: "Card Layout" },
  ];

  const viewMenu = (
    <Menu>
      {viewOptions.map((option) => (
        <Menu.Item key={option.key} onClick={() => setViewType(option.key as ViewType)}>
          {option.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleMenuClick = (e) => {
    setViewType(e.key as ViewType);
  };

  const menuProps = {
    items: viewOptions,
    onClick: handleMenuClick,
  };

  const showLockIcon =
    userSecrets.DECRYPTION_KEYS_OK !== true ? (
      <LockTwoTone onClick={promptMasterKey} />
    ) : (
      <UnlockTwoTone twoToneColor="#52c41a" onClick={clearMasterKey} />
    );

  const leftItems: OrderedComponent[] = [
    {
      component: (
        <Button
          type="primary"
          size="small"
          icon={<MenuOutlined />}
          onClick={() =>
            setIsSelectFieldsViewVisible(!isSelectFieldsViewVisible)
          }
          title="Choose fields to display in the table"
        >
          Select Fields
        </Button>
      ),
      order: 1,
    },
    // Uncomment if needed
    // {
    //   component: (
    //     <Select
    //       defaultValue={continuousView}
    //       style={{ width: 180 }}
    //       size="small"
    //       onChange={() => setContinuousView(!continuousView)}
    //     >
    //       <Select.Option value={false}>Pagination View</Select.Option>
    //       <Select.Option value={true}>Continuous View</Select.Option>
    //     </Select>
    //   ),
    //   order: 2,
    // },
  ];

  // Define your rightItems with the correct structure
  const rightItems: OrderedComponent[] = [
    {
      component: (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Content style={{ marginRight: '8px', color: textColor }}>Show in clear: </Content>
            <Switch
              title="Show PII fields in clear text"
              checked={showPIIsInClear}
              onChange={() => setShowPIIsInClear(!showPIIsInClear)}
            />
          </div>
        </>
      ),
      order: 1,
    },
    {
      component: (
        <span style={{ marginRight: "1em", marginLeft: "1em" }}>{showLockIcon}</span>
      ),
      order: 2,
    },
    {
      component: (
        <Badge 
          overflowCount={99999999}
          count={totalCount}
          showZero
          style={{ backgroundColor: "gray" }}
        />
      ),
      order: 3,
    },
    {
      component: (
        <Dropdown menu={menuProps}>
          <Button>
            {VIEW_TYPE_LABELS[viewType]} <SettingOutlined />
          </Button>
        </Dropdown>
      ),
      order: 4,
    }
  ];
  <TableHeader leftItems={leftItems} rightItems={rightItems} />

  return (
    <>
      <Card title="Events" style={{ margin: "0px 25px 0px 25px" }}>
        <Content>
          <Card>
            <SearchComponent
              ref={searchComponentRef}
              loadData={performSearch}
              onTimeChange={() => console.log("onTimeChange")}
              availableFieldsParam={["ANY", ...eventFields]}
              queryTerms={queryTerms}
              setQueryTerms={setQueryTerms}
            />
          </Card>
          <Content>
            <div
              style={{
                padding: 4,
                position: "absolute",
                display: "flex",
                width: "100%",
              }}
            >
              <EventSelectFieldsViewDrawer
                isVisible={isSelectFieldsViewVisible}
                onClose={() => setIsSelectFieldsViewVisible(false)}
                filterFieldsText={filterFieldsText}
                setFilterFieldsText={setFilterFieldsText}
              />
              <EventDetailsDrawer
                isVisible={isEventDetailViewVisible}
                onClose={() => {
                  setIsEventDetailViewVisible(false);
                  // Clear selected entry when closing drawer
                  setSelectedEntry({});
                }}
                selectedEntry={selectedEntry}
              />

            </div>
            <Content style={{ padding: "0px", minHeight: 100 }}>
          <HistogramV3
            queryTerms={queryTerms}
            startTime={startTime}
            endTime={endTime}
            setStartTime={setStartTime}
            setEndTime={setEndTime}
            currentData={histogram}
            setTableData={setTableData}
            fetchEvents={fetchEvents}
          />
          <div style={{ height: "100%", overflow: "auto" }}>
          { <TableHeader leftItems={leftItems} rightItems={rightItems} /> }
          {/* Conditionally render the table based on viewType */}
          {viewType === "EventTable" && (
            <EventTable
              totalCount={totalCount}
              fetchEvents={fetchEvents}
              setTableData={setTableData}
              tableData={tableData}
              startTime={startTime}
              endTime={endTime}
              searchVersion={searchVersion}
              queryTerms={queryTerms}
              setSelectedEntry={setSelectedEntry}
            />
          )}
          {viewType === "ExpandableTable" && (
            <ExpandableTable
              // Pass necessary props
              totalCount={totalCount}
              fetchEvents={fetchEvents}
              setTableData={setTableData}
              tableData={tableData}
              startTime={startTime}
              endTime={endTime}
              searchVersion={searchVersion}
              queryTerms={queryTerms}
            />
          )}
          {viewType === "DynamicColumns" && (
            <DynamicColumns
              totalCount={totalCount}
              fetchEvents={fetchEvents}
              setTableData={setTableData}
              tableData={tableData}
              startTime={startTime}
              endTime={endTime}
              searchVersion={searchVersion}
              queryTerms={queryTerms}
            />
          )}
          {viewType === "EventCardLayout" && (
            <EventCardLayout
              totalCount={totalCount}
              fetchEvents={fetchEvents}
              setTableData={setTableData}
              tableData={tableData}
              startTime={startTime}
              endTime={endTime}
              searchVersion={searchVersion}
              queryTerms={queryTerms}
            />
          )}
          </div>
          <ModalPromptMasterKey
            isShowing={showModalMasterKey}
            hide={() => setShowModalMasterKey(!showModalMasterKey)}
          />
            </Content>
          </Content>
        </Content>
      </Card>
    </>
  );
};

export default EventsV3;
