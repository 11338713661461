import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Table, Tag, Button, Switch, Badge, Tooltip, Progress } from 'antd';
import {
  UnlockTwoTone,
  LockTwoTone,
  MenuOutlined,
  StarFilled,
  CaretRightOutlined,
  CaretDownOutlined,
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout';
import { useRecoilState } from 'recoil';
import { userSecretsAtom } from 'common/Atoms';
import { PAGE_SIZE } from './HistogramV3';
import { usePIIFields } from '../../contexts/PIIFieldsContext';
import { useEventsTableContext } from '../../contexts/EventsTableContext';
import {
  getEventTypeColor,
  getSeverityColor,
  getSorter,
} from '../../utils/helpers';
import ModalPromptMasterKey from 'common/ModalPromptMasterKey';
import TableHeader from './TableHeader';
import { renderEventItems } from '../../utils/renderEventItems';
import { useSort } from '../../hooks/useSort';
import { usePromotableColumns, EventRecord } from '../../utils/usePromotableColumns';
import { SortOrder, ColumnsType } from 'antd/es/table/interface';
import { styleModeAtom, userAtom } from "../../common/Atoms";
import AdaptiveTags from './AdaptiveTags';

interface ExpandableTableProps {
  fetchEvents: (
    startTime: number,
    endTime: number,
    page: number,
    pageSize: number,
    queryTerms?: any
  ) => Promise<any>;
  setTableData: React.Dispatch<React.SetStateAction<any[]>>;
  tableData: any[];
  totalCount: number;
  startTime: number;
  endTime: number;
  searchVersion: number;
  queryTerms: any;
  spacing?: 'small' | 'middle';
  fontFamily?: string;
}

const FIXED_COLUMNS: string[] = ['id', '_timestamp', 'meta_ss_event_type'];

const ExpandableTable: React.FC<ExpandableTableProps> = ({
  fetchEvents,
  startTime,
  endTime,
  searchVersion,
  queryTerms,
  totalCount,
  tableData,
  setTableData,
  spacing = 'small',
  fontFamily = '',
}) => {
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [page, setPage] = useState(1);
  const [expandedRows, setExpandedRows] = useState<React.Key[]>([]);
  const [expandAll, setExpandAll] = useState(false);
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const [hoverProgress, setHoverProgress] = useState<number>(0);
  const [hoverTimer, setHoverTimer] = useState<NodeJS.Timeout | null>(null);

  const [themeState] = useRecoilState(styleModeAtom);
  const textColor = themeState.theme === "dark" ? "white" : "black";

  // Master key via Recoil
  const [userSecrets, setUserSecrets] = useRecoilState(userSecretsAtom);

  // PII Fields
  const { decryptedPiiFields, showPIIsInClear, setShowPIIsInClear } = usePIIFields();
  const {
    maskingTechniques,
    piiFields,
    getIconIfPiiField,
    showModalMasterKey,
    setShowModalMasterKey,
  } = usePIIFields();

  // Events Table Context
  const { isSelectFieldsViewVisible, setIsSelectFieldsViewVisible } = useEventsTableContext();

  // Sorting & Promotable columns
  const { sortField, sortOrder, handleSort } = useSort();
  const { allEventKeys, promotedKeys, handlePromoteKey } = usePromotableColumns(tableData, FIXED_COLUMNS);

  // Log table data
  useEffect(() => {
    console.log('Table Data:', JSON.stringify(tableData));
  }, [tableData]);

  /** Expand/Collapse All **/
  const toggleExpandAllHandler = useCallback(() => {
    setExpandAll((prev) => !prev);
    // If previously collapsed, expand all; else collapse
    setExpandedRows((prev) =>
      expandAll ? [] : tableData.map((item) => item.key)
    );
    console.log(`Toggled expandAll to ${!expandAll}`);
  }, [expandAll, tableData]);

  /** Data Fetching **/
  const loadMoreData = useCallback(
    async (pageNumber: number, pageSizeNumber: number, currentQueryTerms: any) => {
      if (loading) return;
      setLoading(true);
      console.log(`Loading page ${pageNumber} with pageSize ${pageSizeNumber}`);
      try {
        const result = await fetchEvents(
          startTime,
          endTime,
          pageNumber,
          pageSizeNumber,
          currentQueryTerms
        );
        if (result && result.data) {
          setTableData(result.data);
          setPage(pageNumber);
          setPageSize(pageSizeNumber);
          console.log(`Loaded data for page ${pageNumber}:`, result.data);
        }
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setLoading(false);
      }
    },
    [fetchEvents, startTime, endTime, loading]
  );

  // Initial data load
  useEffect(() => {
    loadMoreData(1, pageSize, queryTerms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVersion, pageSize, queryTerms]);

  /** Flattened Data Transformation **/
  const flattenedData = useMemo(() => {
    return tableData.map((record) => {
      const flatRecord = { ...record };
      // **Removed deletion of 'other' to retain the Event column data**
      delete flatRecord.events;

      const eventPairs = (record.events || "")
        .split("\n")
        .reduce((acc, line) => {
          const [key, value] = line.split(": ");
          if (key && key.trim() !== "") {
            acc[key.trim()] = value ? value.trim() : "";
          } else {
            console.warn(`Empty or invalid key found in line: "${line}"`);
          }
          return acc;
        }, {} as Record<string, any>);

      return { ...flatRecord, ...eventPairs };
    });
  }, [tableData]);

  /** Table Columns **/
  const columns: ColumnsType<any> = useMemo(() => {
    const fixedCols: ColumnsType<any> = [
      {
        title: 'ID',
        dataIndex: 'ingestion_id',
        key: 'ingestion_id',
        width: 100,
        ...getSorter('ingestion_id'),
        sortDirections: ['ascend', 'descend'] as SortOrder[],
        render: (text: string) => {
          const trimmedText = text
            ? `${text.slice(0, 3)}...${text.slice(-4)}`
            : '-';
          return (
            <Tooltip title={text}>
              <span style={{ cursor: 'pointer' }}>{trimmedText}</span>
            </Tooltip>
          );
        },
      },
      {
        title: 'Timestamp',
        dataIndex: '_timestamp',
        key: '_timestamp',
        width: 160,
        ...getSorter('_timestamp'),
        sortDirections: ['ascend', 'descend'] as SortOrder[],
        render: (rawTimestamp: number) => {
          if (!rawTimestamp) return '-';
          const guessedMillis =
            rawTimestamp > 10_000_000_000_000
              ? Math.floor(rawTimestamp / 1000)
              : rawTimestamp; // heuristic
          const date = new Date(guessedMillis);
          if (isNaN(date.getTime())) {
            console.warn('Invalid date from rawTimestamp:', rawTimestamp);
            return <span>- (invalid date)</span>;
          }
          return <span>{date.toLocaleString()}</span>;
        },
      },
      {
        title: 'Type',
        dataIndex: 'meta_ss_event_type',
        key: 'meta_ss_event_type',
        width: 120,
        ...getSorter('eventType'),
        sortDirections: ['ascend', 'descend'] as SortOrder[],
        render: (type: string) => (
          <Tag color={getEventTypeColor(type)}>{type || '-'}</Tag>
        ),
      },
      {
        title: 'Event',
        dataIndex: 'other',
        key: 'other',
        width: '45%',
        ...getSorter('events'),
        sortDirections: ['ascend', 'descend'] as SortOrder[],
        onCell: () => ({
          style: {
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            maxWidth: '45vw',
            padding: '0px',
          },
        }),
        render: (events: string, record: any) => {
          const eventTags = events?.split(",")
            .map(pair => {
              const [key, ...valueParts] = pair.split(": ");
              if (!key || !key.trim()) return null;
              return {
                label: `${key.trim()}: ${valueParts.join(": ").trim()}`,
                tooltip: `${key.trim()}: ${valueParts.join(": ").trim()}`
              };
            })
            .filter(Boolean) as { label: string; tooltip: string }[];

          if (!eventTags?.length) return '-';

          return (
            <div style={{ 
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              overflow: 'hidden'
            }}>
              <AdaptiveTags
                tags={eventTags}
                tagColor={() => 'default'}
                maxDisplay={1}
                showTooltip={true}
              />
            </div>
          );
        },
      },
    ];

    return fixedCols;
  }, [getSorter]);

  /** 
   * Row Expansion 
   */
  const expandableConfig = useMemo(
    () => ({
      expandIcon: ({ expanded, onExpand, record }: any) => {
        const isHovered = hoveredRow === record.key;
        return (
          <div style={{ 
            position: 'relative',
            width: '24px',
            height: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0,
            margin: 0
          }}>
            {expanded ? (
              <MinusOutlined
                onClick={(e) => onExpand(record, e)}
                style={{ 
                  fontSize: '12px',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 2
                }}
              />
            ) : (
              <PlusOutlined
                onClick={(e) => onExpand(record, e)}
                style={{ 
                  fontSize: '12px',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 2
                }}
              />
            )}
            {isHovered && !expanded && (
              <Progress
                type="circle"
                percent={hoverProgress}
                size={24}
                showInfo={false}
                strokeWidth={4}
                strokeColor={{
                  '0%': '#1677ff',
                  '100%': '#1677ff'
                }}
                trailColor="rgba(22, 119, 255, 0.15)"
                style={{
                  position: 'absolute',
                  top: '45%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 1
                }}
              />
            )}
          </div>
        );
      },
      expandedRowRender: (record: any) => {
        // Gather fields not in FIXED_COLUMNS and not 'other'
        const extraFields = Object.keys(record).filter(
          (k: string) => !FIXED_COLUMNS.includes(k) && k !== 'other'
        );

        return (
          <div style={{ padding: '8px 16px', fontFamily }}>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: `repeat(auto-fit, minmax(250px, 1fr))`,
                gap: '0px',
                width: '100%'
              }}
            >
              {extraFields.map((fieldKey) => (
                <div
                  key={fieldKey}
                  style={{ 
                    padding: '0px 0px',
                    borderBottom: '1px solid rgba(0,0,0,0.06)',
                    wordBreak: 'break-word',
                    fontSize: '14px',
                    lineHeight: '1.4',
                    display: 'flex',
                    alignItems: 'baseline',
                    gap: '2px'
                  }}
                >
                  <strong style={{ 
                    flexShrink: 0,
                  }}>
                    {fieldKey}:
                  </strong>
                  <span style={{ 
                    flex: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                    {typeof record[fieldKey] === 'object'
                      ? JSON.stringify(record[fieldKey], null, 2)
                      : record[fieldKey]}
                  </span>
                </div>
              ))}
            </div>
          </div>
        );
      },
      rowExpandable: (record: any) =>
        Object.keys(record).some(
          (k: string) => !FIXED_COLUMNS.includes(k) && k !== 'other'
        ),
      expandRowByClick: true,
      expandedRowKeys: expandedRows,
      onExpandedRowsChange: (expandedKeys: readonly React.Key[]) => {
        setExpandedRows([...expandedKeys]);
      },
    }),
    [expandedRows, fontFamily, hoveredRow, hoverProgress]
  );

  /** 
   * Pagination 
   */
  const tableChangeHandler = useCallback(
    async (page: number, pageSize: number) => {
      // Convert page -> offset
      const offset = (page - 1) * pageSize;
      try {
        const result = await fetchEvents(
          startTime,
          endTime,
          offset,
          pageSize,
          queryTerms
        );
        setTableData(result.hits);
        setPage(page);
        setPageSize(pageSize);
      } catch (err) {
        console.error(err);
      }
    },
    [fetchEvents, startTime, endTime, queryTerms]
  );

  const handleRowHover = (record: any, entering: boolean) => {
    if (entering) {
      setHoveredRow(record.key);
      setHoverProgress(0);
      
      const startTime = Date.now();
      const duration = 1500; // Exactly 1.5 seconds
      
      const timer = setInterval(() => {
        const elapsed = Date.now() - startTime;
        const progress = Math.min((elapsed / duration) * 100, 100);
        setHoverProgress(progress);
        
        if (progress >= 100) {
          clearInterval(timer);
          setTimeout(() => {
            setHoverProgress(0);
            setHoveredRow(null);
            setExpandedRows(prev => 
              prev.includes(record.key) 
                ? prev 
                : [...prev, record.key]
            );
          }, 100);
        }
      }, 16); // 60fps for smooth animation
      
      setHoverTimer(timer);
    } else {
      if (hoverTimer) {
        clearInterval(hoverTimer);
        setHoverTimer(null);
      }
      setHoverProgress(0);
      setHoveredRow(null);
    }
  };

  return (
    <>
      {/* Expand/Collapse All */}
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '8px 16px' }}>
        <div
          style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
          onClick={toggleExpandAllHandler}
        >
          {expandAll ? (
            <>
              <CaretDownOutlined style={{ fontSize: '12px' }} />
              <Content>Collapse All</Content>
            </>
          ) : (
            <>
              <CaretRightOutlined style={{ fontSize: '12px' }} />
              <Content>Expand All</Content>
            </>
          )}
        </div>
      </div>

      <Table<any>
        columns={columns}
        dataSource={flattenedData}
        className={`custom-table spacing-${spacing} expandable-table`}
        pagination={{
          pageSize: 10,
          current: page,
          total: totalCount,
          onChange: tableChangeHandler,
        }}
        style={{ width: '100%', tableLayout: 'fixed' }}
        scroll={{ x: undefined }}
        size={spacing === 'small' ? 'small' : 'middle'}
        loading={loading}
        rowKey={(record) => record.key}
        expandable={expandableConfig}
        onRow={(record) => ({
          onMouseEnter: () => handleRowHover(record, true),
          onMouseLeave: () => handleRowHover(record, false),
          style: {
            cursor: 'pointer',
          }
        })}
      />

      <ModalPromptMasterKey
        visible={showModalMasterKey}
        onCancel={() => setShowModalMasterKey(false)}
      />
    </>
  );
};

export default ExpandableTable;
