import { useState, useCallback } from 'react';

export const useSort = () => {
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const handleSort = useCallback((field) => {
    if (sortField === field) {
      // If clicking the same field, toggle order
      setSortOrder((currentOrder) => 
        currentOrder === 'ascend' ? 'descend' : 'ascend'
      );
    } else {
      // If clicking a new field, set it and start with ascend
      setSortField(field);
      setSortOrder('ascend');
    }
  }, [sortField]);

  return {
    sortField,
    sortOrder,
    handleSort,
  };
};
