// AdaptiveTags.tsx
import React from 'react';
import { Tag, Tooltip } from 'antd';

interface TagItem {
  label: string;
  tooltip: string;
}

interface AdaptiveTagsProps {
  tags: TagItem[];
  tagColor: (tag: TagItem) => string;
  maxDisplay?: number;
  showTooltip?: boolean;
}

const AdaptiveTags: React.FC<AdaptiveTagsProps> = ({
  tags,
  tagColor,
  maxDisplay = 1,
  showTooltip = true,
}) => {
  const displayTags = tags.slice(0, maxDisplay);
  const remaining = tags.length - maxDisplay;

  const formatTooltipContent = (items: TagItem[]) => {
    // Split items into two balanced columns
    const midPoint = Math.ceil(items.length / 2);
    const leftColumn = items.slice(0, midPoint);
    const rightColumn = items.slice(midPoint);

    return (
      <div style={{ 
        display: 'flex',
        gap: '0px',
        maxWidth: '600px',
        padding: '8px'
      }}>
        {/* Left Column */}
        <div style={{ 
          flex: 1,
          minWidth: '200px',
          fontSize: '14px',
          lineHeight: '1.6'
        }}>
          {leftColumn.map((item, idx) => (
            <div 
              key={`left-${idx}`} 
              style={{ 
                padding: '0px 0',
                borderBottom: idx < leftColumn.length - 1 ? '1px solid rgba(255,255,255,0.2)' : 'none',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {item.tooltip}
            </div>
          ))}
        </div>
        {/* Right Column */}
        {rightColumn.length > 0 && (
          <div style={{ 
            flex: 1,
            minWidth: '200px',
            fontSize: '14px',
            lineHeight: '1.6'
          }}>
            {rightColumn.map((item, idx) => (
              <div 
                key={`right-${idx}`} 
                style={{ 
                  padding: '0px 0',
                  borderBottom: idx < rightColumn.length - 1 ? '1px solid rgba(255,255,255,0.2)' : 'none',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {item.tooltip}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div style={{ 
      display: 'flex', 
      gap: '0px', 
      alignItems: 'center',
      width: '100%',
      overflow: 'hidden'
    }}>
      {displayTags.map((tag, index) => (
        <Tooltip 
          key={index} 
          title={showTooltip ? tag.tooltip : null}
          placement="right"
          overlayStyle={{ maxWidth: 'none' }}
        >
          <Tag 
            color={tagColor(tag)}
            style={{
              maxWidth: 'calc(100% - 80px)',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {tag.label}
          </Tag>
        </Tooltip>
      ))}
      {remaining > 0 && (
        <Tooltip 
          title={formatTooltipContent(tags.slice(maxDisplay))}
          placement="right"
          overlayStyle={{ 
            maxWidth: 'none',
            minWidth: '500px' // Ensure enough space for both columns
          }}
          overlayInnerStyle={{
            padding: '0px',
            borderRadius: '6px'
          }}
        >
          <Tag style={{ flexShrink: 0 }}>+{remaining} more</Tag>
        </Tooltip>
      )}
    </div>
  );
};

export default AdaptiveTags;
