import {
  DesktopOutlined,
  FileSearchOutlined,
  LogoutOutlined,
  PieChartOutlined,
  SettingOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { HddOutlined } from "@ant-design/icons";
import { FaLock } from "react-icons/fa";
import { FaCogs, FaDownload } from 'react-icons/fa';
import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userAtom, currentMenuItemAtom } from "../../common/Atoms";
import "./Navbar.css";

const { Sider } = Layout;

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useRecoilState(userAtom);
  const [currentMenuItem, setCurrentMenuItemAtom] = useRecoilState(currentMenuItemAtom);

  const onCollapse = (collapsed: boolean) => {
    setCurrentMenuItemAtom({ ...currentMenuItem, collapsed });
  };

  const onOpenChange = (keys: string[]) => {
    setCurrentMenuItemAtom({ ...currentMenuItem, openKeys: keys });
  };

  const handleClick = ({ keyPath }: any) => {
    let menuItem = keyPath[0];
    let openKeys = keyPath.slice(1).reverse();

    if (menuItem === "logout") {
      menuItem = "dashboard";
      handleLogout();
    } else {
      setCurrentMenuItemAtom({
        ...currentMenuItem,
        currentMenuItem: menuItem,
        openKeys: openKeys,
      });
    }
  };

  const handleLogout = () => {
    try {
      localStorage.clear();
      sessionStorage.clear();

      setCurrentMenuItemAtom({
        ...currentMenuItem,
        currentMenuItem: "dashboard",
        openKeys: [],
      });
      setUser({
        ...user,
        is_authenticated: false,
        token: "",
        refresh: "",
        is_2fa_authenticated: false,
      });
      navigate("/login");
    } catch (error) {
      console.error("Error during logout: ", error);
    }
  };

  type MenuItem = Required<MenuProps>["items"][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const items: MenuProps["items"] = [
    getItem(
      <Link to="/dashboard">Dashboard</Link>,
      "dashboard",
      <PieChartOutlined />
    ),
    getItem(
      <Link to="/events">Events</Link>,
      "events",
      <FileSearchOutlined />
    ),
    getItem(
      <Link to="/devices">Devices</Link>,
      "devices",
      <DesktopOutlined />
    ),
    getItem("Settings", "settings", <SettingOutlined />, [
      getItem(
        <Link to="/profile">Profile</Link>,
        "settings/profile",
        <UserOutlined />
      ),
      getItem(
        <Link to="/organizations">Organizations</Link>,
        "settings/organizations",
        <ShopOutlined />
      ),
      getItem(
        <Link to="/users">Users</Link>,
        "settings/users",
        <TeamOutlined />
      ),
      // Setup submenu under Settings
      getItem("Setup", "settings/setup", <FaCogs />, [
        getItem(
          <Link to="/app-settings/setup">Agent Setup</Link>,
          "settings/setup/wizard"
        ),
        getItem(
          <Link to="/app-settings/privacy/privacy">Privacy</Link>,
          "settings/setup/privacy"
        ),
        getItem(
          <Link to="/app-settings/privacy/encryption">Encryption</Link>,
          "settings/setup/encryption"
        ),
        getItem(
          <Link to="/app-settings/privacy/api-keys">API Keys</Link>,
          "settings/setup/api-keys"
        ),
        getItem(
          <Link to="/app-settings/privacy/certificates">Certificates</Link>,
          "settings/setup/certificates"
        ),
      ]),
      getItem(
        <Link to="/app-settings/datausage">Disk Usage</Link>,
        "settings/datausage",
        <HddOutlined />
      ),
    ]),
    getItem(
      <span onClick={handleLogout}>Logout</span>,
      "logout",
      <LogoutOutlined />
    ),
  ];
  
  

  // useEffect to monitor route changes
  useEffect(() => {
    let newOpenKeys: string[] = [];
  
    // If route is within any Settings area, open the "settings" parent
    if (
      location.pathname === "/profile" ||
      location.pathname === "/organizations" ||
      location.pathname === "/users" ||
      location.pathname.startsWith("/app-settings")
    ) {
      newOpenKeys.push("settings");
    }
  
    // If route is within the Setup section, also open the Setup submenu
    if (location.pathname.startsWith("/app-settings")) {
      newOpenKeys.push("settings/setup");
    }
  
    // Map the current route to a selected key
    let newSelectedKey = "";
    switch (location.pathname) {
      case "/profile":
        newSelectedKey = "settings/profile";
        break;
      case "/organizations":
        newSelectedKey = "settings/organizations";
        break;
      case "/users":
        newSelectedKey = "settings/users";
        break;
      case "/app-settings/setup":
        newSelectedKey = "settings/setup/wizard";
        break;
      case "/app-settings/datausage":
        newSelectedKey = "settings/datausage";
        break;
      default:
        // For privacy-related routes, check by prefix
        if (location.pathname.startsWith("/app-settings/privacy/encryption")) {
          newSelectedKey = "settings/setup/encryption";
        } else if (location.pathname.startsWith("/app-settings/privacy/api-keys")) {
          newSelectedKey = "settings/setup/api-keys";
        } else if (location.pathname.startsWith("/app-settings/privacy/certificates")) {
          newSelectedKey = "settings/setup/certificates";
        } else if (location.pathname.startsWith("/app-settings/privacy/privacy")) {
          newSelectedKey = "settings/setup/privacy";
        } else {
          // Fallback: remove leading slash from pathname
          newSelectedKey = location.pathname.substring(1);
        }
    }
  
    setCurrentMenuItemAtom((prev) => ({
      ...prev,
      openKeys: newOpenKeys,
      currentMenuItem: newSelectedKey,
    }));
  }, [location.pathname]);

  return (
<Sider collapsible collapsed={currentMenuItem.collapsed} onCollapse={onCollapse}>
  <Menu
    onClick={handleClick}
    onOpenChange={onOpenChange}
    openKeys={currentMenuItem.openKeys}
    selectedKeys={[currentMenuItem.currentMenuItem]}
    mode="inline"
    items={items}
  />
</Sider>
  );
}
