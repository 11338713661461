import { Tag } from "antd";
import { ExpandOutlined } from "@ant-design/icons";
import { usePIIFields } from "../../contexts/PIIFieldsContext";

const FIELD_NAME_TIMESTAMP = "_timestamp";
const FIELD_NAME_EVENT_TYPE = "meta_ss_event_type";
const FIELD_NAME_INGESTION_ID = "ingestion_id";

interface EventDetailsRendererProps {
  text: string;
  event: any;
  setSelectedEntry: (event: any) => void;
}

export const EventDetailsRenderer: React.FC<EventDetailsRendererProps> = ({
  text,
  event,
  setSelectedEntry,
}) => {
  // Handler for clicking the expansion icon
  const handleExpandClick = (e: React.MouseEvent) => {
    // Stop propagation to avoid triggering other click events in parent elements
    e.stopPropagation();
    setSelectedEntry(event);
  };

  const {
    maskingTechniques,
    piiFields,
    decryptedPiiFields,
    isLoading,
    showPIIsInClear,
    getIconIfPiiField,
  } = usePIIFields();

  if (!text) {
    return <div>-</div>;
  }

  return (
    <div
      style={{
        position: "relative", // to allow absolute positioning of the icon
        overflowWrap: "anywhere",
        maxWidth: "100%",
        paddingTop: "20px" // leave space for the icon
      }}
    >
      {/* Expansion icon at the top right */}
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          cursor: "pointer",
          padding: "5px",
          zIndex: 1,
        }}
        onClick={handleExpandClick}
      >
        <ExpandOutlined title="Expand Details" />
      </div>
      
      {/* Main content rendered as tags */}
      <div>
        {text.split(", ").map((entry, index) => {
          const [fullKey, value] = entry.split(": ");
          const key = fullKey.replace(/^(payload_|meta_)/, "");
          return (
            <span key={`${key}-${index}`}>
              <Tag
                style={{
                  border: "none",
                  padding: "0",
                  margin: "0 5px 0 0",
                }}
                title={fullKey}
                color="geekblue"
              >
                {key}:
                <Tag
                  style={{
                    border: "none",
                    padding: "0",
                    margin: "0",
                    whiteSpace: "normal",
                    wordBreak: "break-all",
                  }}
                  title={value}
                >
                  {value}
                  {getIconIfPiiField(
                    "EventDetailsRenderer used to render columns",
                    fullKey,
                    value,
                    event[FIELD_NAME_INGESTION_ID]
                  )}
                </Tag>
              </Tag>
            </span>
          );
        })}
      </div>
    </div>
  );
};
