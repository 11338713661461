// EventTable.tsx

import { Badge, Button, Select, Switch, Table } from "antd";
import { LockTwoTone, UnlockTwoTone, MenuOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useEventsTableContext } from "../../contexts/EventsTableContext";
import TableHeader from "./TableHeader";
import { OrderedComponent } from "types/EventsTableTypes";
import { usePIIFields } from "../../contexts/PIIFieldsContext";
import { FIELD_TO_EXCLUDE } from "hooks/getEvents";
import { Content } from "antd/lib/layout/layout";
import { userSecretsAtom } from "common/Atoms";
import { styleModeAtom, userAtom } from "../../common/Atoms";
import { useRecoilState } from "recoil";
import { PAGE_SIZE } from "./HistogramV3";

interface EventTableProps {
  fetchEvents: (
    startTime: number,
    endTime: number,
    page: number,
    pageSize: number,
    queryTerms?: any
  ) => Promise<any>;
  setTableData: React.Dispatch<React.SetStateAction<any[]>>; // Updated type
  tableData: any[];
  totalCount: number;
  startTime: number;
  endTime: number;
  searchVersion: number;
  queryTerms: any;
  setSelectedEntry: React.Dispatch<React.SetStateAction<any>>;
}

const EventTable: React.FC<EventTableProps> = ({
  fetchEvents,
  totalCount,
  setTableData,
  tableData,
  startTime,
  endTime,
  searchVersion,
  queryTerms,
  setSelectedEntry,
}) => {

  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  
  const [userSecrets, setUserSecrets] = useRecoilState(userSecretsAtom);

  const [themeState] = useRecoilState(styleModeAtom);
  const textColor =
  themeState.theme === "dark"
    ? "white"
    : "black";
  
  const {
    maskingTechniques,
    piiFields,
    getIconIfPiiField,
    showModalMasterKey,
    setShowModalMasterKey,
  } = usePIIFields();

  const {
    page,
    setPage,
    tableColumns,
    tableFields,
    isSelectFieldsViewVisible,
    setIsSelectFieldsViewVisible,
    setContinuousView,
    isLoading,
  } = useEventsTableContext();
  const { decryptedPiiFields, showPIIsInClear, setShowPIIsInClear } =
    usePIIFields();

  let mouseDownX: number | null = null;
  let mouseDownY: number | null = null;

  const loadMoreData = useCallback(
    async (pageNumber: number, pageSizeNumber: number, currentQueryTerms: any) => {
      if (loading) return;
  
      setLoading(true);
      const offset = (pageNumber - 1) * pageSizeNumber; // Calculate offset using passed parameters
      
      console.log(`Loading page ${pageNumber} with offset ${offset}`); // Debug log
      
      try {
        const result = await fetchEvents(
          startTime,
          endTime,
          offset,
          pageSizeNumber,
          currentQueryTerms
        );
        
        if (result && result.data) {
          setTableData(result.data);
          setPage(pageNumber);
          setPageSize(pageSizeNumber);
        }
      } catch (error) {
        console.error("Error loading data:", error);
      }
  
      setLoading(false);
    },
    [loading, fetchEvents, startTime, endTime] // Remove page and pageSize from dependencies
  );

  useEffect(() => {
    loadMoreData(1, pageSize, queryTerms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVersion]);

  useEffect(() => {
    if (tableData) {
      let modifiedData = false;
      tableData.forEach((row) => {
        let rowIngestionId = row["ingestion_id"];
        if (rowIngestionId in decryptedPiiFields) {
          const decryptedRecordKeyValues = decryptedPiiFields["recordId"];

          // Uncomment and implement if necessary
          // Object.keys(decryptedRecordKeyValues).forEach((fieldName) => {
          //   const value = decryptedRecordKeyValues[fieldName]
          //   console.log(fieldName, " => ", value)
          //   const fieldNamePayloadPrefixed = "payload_" + fieldName
          //   if(fieldNamePayloadPrefixed in row) {
          //     row[fieldNamePayloadPrefixed] = value
          //     modifiedData = true;
          //   }
          // })
        }
      });
      if (modifiedData) {
        const updatedData = tableData.map((item, idx) => {
          const other = Object.entries(item)
            .filter(([key]) => !FIELD_TO_EXCLUDE.includes(key))
            .map(([key, value]) => `${key}: ${value}`)
            .join(", ");
          const seq = 1 + idx;
          return { ...item, key: item.ingestion_id, other, seq };
        });
        setTableData([...updatedData]);
      }
    }
  }, [decryptedPiiFields, tableData]);

  useEffect(() => {
    console.log("Current page state:", page);
  }, [page]);

  useEffect(() => {}, [showPIIsInClear]);

  const processRawData = (rawData: any[]) => {
    return rawData.map((item, idx) => {
      const other = Object.entries(item)
        .filter(([key]) => !FIELD_TO_EXCLUDE.includes(key))
        .map(([key, value]) => `${key}: ${value}`)
        .join(', ');
      const seq = 1 + idx;
      return { ...item, key: item.ingestion_id, other, seq };
    });
  };

  const tableChangeHandler = useCallback(
    async (page: number, pageSize: number) => {
      if (loading) return;
  
      const offset = (page - 1) * pageSize;
  
      console.log('Pagination params:', {
        page,
        pageSize,
        offset,
      });
  
      setLoading(true);
      try {
        const result = await fetchEvents(
          startTime,
          endTime,
          offset,
          pageSize,
          queryTerms
        );
  
        if (result && result.hits) {
          const processedData = processRawData(result.hits);
          setTableData(processedData);
          setPage(page);
          setPageSize(pageSize);
        } else {
          console.error("No data returned from fetchEvents");
        }
      } catch (error) {
        console.error("Error loading page:", error);
      } finally {
        setLoading(false);
      }
    },
    [fetchEvents, startTime, endTime, queryTerms, loading]
  );

  return (
    <>
      {isLoading ? (
        <div style={{ textAlign: "center" }}>Loading...</div>
      ) : (
        <Table
          dataSource={tableData}
          columns={tableColumns}
          pagination={{
            current: page,
            pageSize: pageSize,
            total: totalCount,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            onChange: tableChangeHandler,
            onShowSizeChange: tableChangeHandler,
          }}
          loading={loading}
          onRow={(record) => ({
            onMouseDown: (e) => {
              mouseDownX = e.clientX;
              mouseDownY = e.clientY;
            },
            onMouseUp: (e) => {
              // Check if any text is selected
              const selectedText = window.getSelection()?.toString();
              if (selectedText && selectedText.trim().length > 0) {
                // If text is selected, do nothing
                mouseDownX = null;
                mouseDownY = null;
                return;
              }
              
              const threshold = 5; // pixels
              if (
                mouseDownX !== null &&
                mouseDownY !== null &&
                Math.abs(e.clientX - mouseDownX) < threshold &&
                Math.abs(e.clientY - mouseDownY) < threshold
              ) {
                // Only trigger the detailed view if it was a simple click (without dragging)
                //setSelectedEntry(record);
              }
              // Reset the mouse positions
              mouseDownX = null;
              mouseDownY = null;
            },
          })}
          
        />
      )}

    </>
  );
};

export default EventTable;
