// GlobalBandwidthChart.tsx

import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as echarts from 'echarts';
import { Spin, notification } from 'antd';
import moment from 'moment-timezone'; // Ensure timezone support
import { useRecoilState } from 'recoil';
import AxiosApiInstance from '../../common/Interceptors';
import { styleModeAtom, userAtom } from '../../common/Atoms';
import { URL_API_V1 } from '../../constants/global';
import { DEFAULT_DATETIME_FORMAT, TIMEZONE } from '../../constants/user';
import { useDeviceCharts } from '../../contexts/DeviceChartsContext';
import { EChartsOption, ScatterSeriesOption } from 'echarts'; // Import necessary types

interface BandwidthDataPoint {
  _field: string;
  _measurement: string;
  _start: string;
  _stop: string;
  _time: string;
  _value: number;
  dst_ip: string;
  result: string;
  src_ip: string;
  table: number;
}

interface DataPoint {
  x: number;
  y: number;
  src_ip?: string;
  dst_ip?: string;
  field: string;
  table: number;
}

interface GlobalBandwidthChartProps {
  deviceIp: string;
}

const GlobalBandwidthChart: React.FC<GlobalBandwidthChartProps> = ({ deviceIp }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstanceRef = useRef<echarts.ECharts | null>(null); // Ref to store ECharts instance
  const [chartData, setChartData] = useState<DataPoint[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [user] = useRecoilState(userAtom);
  const { axiosApiInstance } = AxiosApiInstance();
  const [themeState] = useRecoilState(styleModeAtom);
  const isDarkTheme = themeState.theme === 'dark';

  const { updateChart } = useDeviceCharts();

  // Define the unit and scaling factor
  type UnitType = 'KB' | 'MB';
  const UNIT: UnitType = 'MB' as UnitType; // Change to 'KB' if needed
  const SCALING_FACTOR = UNIT === 'KB' ? 1024 : 1024 * 1024; // 1 KB = 1024 bytes, 1 MB = 1048576 bytes

  /**
   * Aggregates data by time and field, retaining per-IP information.
   */
  const aggregateData = useCallback(
    (data: BandwidthDataPoint[], scalingFactor: number): DataPoint[] => {
      if (data.length === 0) return [];

      return data
        .map(item => ({
          x: moment(item._time).valueOf(),
          y: item._value / scalingFactor,
          src_ip: item.src_ip,
          dst_ip: item.dst_ip,
          field: item._field,
          table: item.table,
        }))
        .sort((a, b) => a.x - b.x); // Ensure chronological order
    },
    []
  );

  /**
   * Fetches bandwidth data for all devices.
   */
  const fetchBandwidthData = useCallback(async () => {
    if (!user.organization) {
      notification.error({
        message: 'Organization Not Selected',
        description: 'Please select an organization before fetching bandwidth data.',
        key: 'organization-not-selected-bandwidth',
      });
      setLoading(false);
      return;
    }

    try {
      const endpoint = `${URL_API_V1}/r/${user.organization}/metrics/devices/${deviceIp}`; // Ensure this endpoint returns all devices' bandwidth data
      
      // Prepare query parameters
      const params: any = {};

      if (deviceIp) {
        params.src_ip = deviceIp; // Filter by src_ip if deviceIp is provided
      }
      
      //const response = await axiosApiInstance.get(endpoint, { params });
      const response = await axiosApiInstance.get(endpoint);

      console.log("Bandwidth status:", response.status, "Bandwidth data:", response.data);
      if (response && response.status === 200) {
        const aggregatedData = aggregateData(response.data, SCALING_FACTOR);
        setChartData(aggregatedData);
      } else {
        notification.error({
          message: 'Error fetching bandwidth data',
          description: 'Unable to fetch bandwidth data.',
          key: 'error-fetch-bandwidth-data',
          duration: 2,
        });
      }
    } catch (error) {
      console.error('Error fetching bandwidth data:', error);
      notification.error({
        message: 'Error fetching bandwidth data',
        description: 'An error occurred while fetching bandwidth data.',
        key: 'error-fetch-bandwidth-data',
        duration: 2,
      });
    } finally {
      setLoading(false);
    }
  }, [user.organization, aggregateData, SCALING_FACTOR, deviceIp]);

  /**
   * Fetch data on component mount and when `updateChart` changes.
   */
  useEffect(() => {
    fetchBandwidthData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateChart]);

  /**
   * useRef to track the previous theme.
   */
  const previousThemeRef = useRef<boolean>(isDarkTheme);

  /**
   * Initialize and manage the ECharts instance and event listeners.
   * This useEffect runs when the theme changes.
   */
  useEffect(() => {
    if (!chartRef.current) return;

    // Initialize chart if not already initialized
    if (!chartInstanceRef.current) {
      chartInstanceRef.current = echarts.init(chartRef.current, isDarkTheme ? 'dark' : 'light');
    } else if (previousThemeRef.current !== isDarkTheme) {
      // Theme has changed, dispose and re-initialize the chart
      chartInstanceRef.current.dispose();
      chartInstanceRef.current = echarts.init(chartRef.current, isDarkTheme ? 'dark' : 'light');
    }

    // Update the previous theme ref
    previousThemeRef.current = isDarkTheme;

    const myChart = chartInstanceRef.current;

    /**
     * Define the ECharts option.
     * The initial set is empty; options will be set in the next useEffect.
     */
    const options: EChartsOption = {
      backgroundColor: isDarkTheme ? '#2c343c' : '#ffffff', // Adjust background based on theme
      tooltip: {
        trigger: 'item', // Handle single data points
        formatter: (params: any) => {
          if (!params || typeof params !== 'object') return '';

          const data = params.data;
          const time = moment(data.value[0]).tz(TIMEZONE).format(DEFAULT_DATETIME_FORMAT);
          const valueOriginal = data.value[1] * SCALING_FACTOR;
          const valueFormatted =
            UNIT === 'KB'
              ? (valueOriginal / 1024).toFixed(2)
              : (valueOriginal / (1024 * 1024)).toFixed(2);

          const tooltipText = `
            <strong>${params.seriesName.toUpperCase()}</strong><br/>
            Time: ${time}<br/>
            Source: ${data.src_ip || 'N/A'}<br/>
            Destination: ${data.dst_ip || 'N/A'}<br/>
            Value: ${valueOriginal.toFixed(2)} bytes (${valueFormatted} ${UNIT})
          `;

          return tooltipText;
        },
        axisPointer: {
          type: 'cross',
        },
        backgroundColor: isDarkTheme ? '#333' : '#fff',
        textStyle: {
          color: isDarkTheme ? '#fff' : '#000',
        },
      },
      grid: {
        left: '10%',
        right: '10%',
        bottom: '15%',
        top: '10%',
        containLabel: true,
      },
      xAxis: {
        type: 'time',
        name: 'Time',
        axisLabel: {
          formatter: (value: number) => moment(value).format('HH:mm'),
          color: isDarkTheme ? '#ffffff' : '#000000',
        },
        splitLine: { show: true, lineStyle: { color: isDarkTheme ? '#444' : '#eee' } },
        splitNumber: 6,
        min: 'dataMin',
        max: 'dataMax',
      },
      yAxis: {
        type: 'value',
        name: `Bandwidth (${UNIT})`,
        axisLabel: {
          formatter: (value: number) => `${value} ${UNIT}`,
          color: isDarkTheme ? '#ffffff' : '#000000',
        },
        splitLine: { show: true, lineStyle: { color: isDarkTheme ? '#444' : '#eee' } },
      },
      series: [
        {
          name: 'RX',
          type: 'scatter',
          symbol: 'circle',
          symbolSize: 8,
          itemStyle: {
            color: '#1890ff',
            opacity: 1,
          },
          emphasis: {
            label: {
              show: false,
            },
          },
          data: [], // Initially empty, to be updated in setOption
        },
        {
          name: 'TX',
          type: 'scatter',
          symbol: 'diamond',
          symbolSize: 8,
          itemStyle: {
            color: '#a18cd1',
            opacity: 1,
          },
          emphasis: {
            label: {
              show: false,
            },
          },
          data: [], // Initially empty, to be updated in setOption
        },
      ] as ScatterSeriesOption[],
      legend: {
        data: ['RX', 'TX'],
        textStyle: {
          color: isDarkTheme ? '#ffffff' : '#000000',
        },
      },
      toolbox: {
        feature: {
          saveAsImage: { title: 'Save as Image' },
        },
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
        },
        {
          start: 0,
          end: 100,
        },
      ],
    };

    // Set the option on the current chart instance
    myChart.setOption(options, { notMerge: true });

    /**
     * Handle mouseover event to adjust opacity based on src_ip.
     * Only highlight datapoints with the same src_ip as the hovered one.
     */
    const handleMouseOver = (params: any) => {
      if (params && params.data && params.data.src_ip) {
        const hoveredSrcIp = params.data.src_ip;

        if (!chartInstanceRef.current) return; // Ensure chart is not disposed

        // Update the opacity of each series data point based on src_ip
        myChart.setOption({
          series: [
            {
              // RX series
              data: chartData
                .filter((point) => point.field === 'rx')
                .map((point) => ({
                  value: [point.x, point.y],
                  src_ip: point.src_ip,
                  dst_ip: point.dst_ip,
                  itemStyle: {
                    opacity: point.src_ip === hoveredSrcIp ? 1 : 0.2,
                  },
                })),
            },
            {
              // TX series
              data: chartData
                .filter((point) => point.field === 'tx')
                .map((point) => ({
                  value: [point.x, point.y],
                  src_ip: point.src_ip,
                  dst_ip: point.dst_ip,
                  itemStyle: {
                    opacity: point.src_ip === hoveredSrcIp ? 1 : 0.2,
                  },
                })),
            },
          ],
        });
      }
    };

    /**
     * Handle mouseout event to reset opacity.
     */
    const handleMouseOut = () => {
      if (!chartInstanceRef.current) return; // Ensure chart is not disposed

      myChart.setOption({
        series: [
          {
            // RX series
            data: chartData
              .filter((point) => point.field === 'rx')
              .map((point) => ({
                value: [point.x, point.y],
                src_ip: point.src_ip,
                dst_ip: point.dst_ip,
                itemStyle: {
                  opacity: 1,
                },
              })),
          },
          {
            // TX series
            data: chartData
              .filter((point) => point.field === 'tx')
              .map((point) => ({
                value: [point.x, point.y],
                src_ip: point.src_ip,
                dst_ip: point.dst_ip,
                itemStyle: {
                  opacity: 1,
                },
              })),
          },
        ],
      });
    };

    // Remove existing event listeners to prevent multiple bindings
    myChart.off('mouseover', handleMouseOver);
    myChart.off('mouseout', handleMouseOut);

    // Attach event listeners
    myChart.on('mouseover', handleMouseOver);
    myChart.on('mouseout', handleMouseOut);

    // Handle responsive resizing
    const resizeHandler = () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.resize();
      }
    };
    window.addEventListener('resize', resizeHandler);

    // Cleanup on unmount or theme change
    return () => {
      window.removeEventListener('resize', resizeHandler);
      myChart.off('mouseover', handleMouseOver);
      myChart.off('mouseout', handleMouseOut);
      myChart.dispose();
      chartInstanceRef.current = null;
    };
  }, [isDarkTheme, chartData]);

  /**
   * Update the chart when chartData changes.
   */
  useEffect(() => {
    if (!chartInstanceRef.current) return;

    const myChart = chartInstanceRef.current;

    // Update the series data
    myChart.setOption({
      series: [
        {
          // RX series
          data: chartData
            .filter((point) => point.field === 'rx')
            .map((point) => ({
              value: [point.x, point.y],
              src_ip: point.src_ip,
              dst_ip: point.dst_ip,
              itemStyle: {
                opacity: 1,
              },
            })),
        },
        {
          // TX series
          data: chartData
            .filter((point) => point.field === 'tx')
            .map((point) => ({
              value: [point.x, point.y],
              src_ip: point.src_ip,
              dst_ip: point.dst_ip,
              itemStyle: {
                opacity: 1,
              },
            })),
        },
      ],
    });
  }, [chartData]);

  /**
   * Display loading spinner or no data message as appropriate.
   */
  if (loading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin tip="Loading global bandwidth chart data..." />
      </div>
    );
  }

  if (chartData.length === 0) {
    return <span>No Bandwidth Data</span>;
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div ref={chartRef} style={{ width: '100%', height: 400 }} />
    </div>
  );
};

export default React.memo(GlobalBandwidthChart);
