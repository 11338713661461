// usePromotableColumns.ts

import { useState, useEffect } from 'react';

// Define the structure of your main data
export interface EventRecord {
  id: string;
  timestamp: number;
  eventType: string;
  ss_severity: string;
  events: string;
  [key: string]: any; // For additional dynamic fields
}

interface UsePromotableColumnsReturn {
  allEventKeys: string[];
  promotedKeys: string[];
  handlePromoteKey: (key: string) => void;
}

/**
 * Custom hook to manage promotable columns in a table.
 *
 * @param data - The data source array containing event records.
 * @param fixedColumns - An array of column keys that are fixed and should not be promoted.
 * @returns An object containing all event keys, promoted keys, and a handler to promote/demote keys.
 */
export const usePromotableColumns = (
  data: EventRecord[],
  fixedColumns: string[] = []
): UsePromotableColumnsReturn => {
  const [allEventKeys, setAllEventKeys] = useState<string[]>([]);
  const [promotedKeys, setPromotedKeys] = useState<string[]>([]);

  // Extract all possible event keys on mount or when data/fixedColumns change
  useEffect(() => {
    const keys = new Set<string>();
    data.forEach(record => {
      if (record.events) {
        record.events.split('\n').forEach(line => {
          const key = line.split(': ')[0].trim();
          if (key) {
            keys.add(key);
          }
        });
      }
    });
    setAllEventKeys(Array.from(keys).filter(key => !fixedColumns.includes(key)));
  }, [data, fixedColumns]);

  /**
   * Toggles the promotion of a column key.
   *
   * @param key - The column key to promote or demote.
   */
  const handlePromoteKey = (key: string): void => {
    setPromotedKeys(prev =>
      prev.includes(key)
        ? prev.filter(k => k !== key)
        : [...prev, key]
    );
  };

  return {
    allEventKeys,
    promotedKeys,
    handlePromoteKey
  };
};
