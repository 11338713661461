// BasicLayout.tsx

import { Layout } from "antd";
import IdleMonitor from "common/IdleMonitor";
import LeaveReview from "components/LeaveReview/LeaveReview";
import Navbar from "../Navbar/Navbar";
import Version from "../../common/Version";
import TopBar from "../TopBar/TopBar";
import { useRecoilValue } from "recoil";
import { userAtom } from "../../common/Atoms";
import HandleOrganization from "../Organizations/HandleOrganization";

export function BasicLayout({ children }: { children: React.ReactNode }) {
  const { Content, Footer } = Layout;
  const user = useRecoilValue(userAtom);

  // Determine if the user has organizations
  const hasOrganizations = user.organizations.length > 0;

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <IdleMonitor />
      <TopBar />
        {hasOrganizations ? (
          <Layout>
            <Navbar />
            <Layout>
            <Content>{children}</Content>
            <Footer style={{ textAlign: "center" }}>
              <LeaveReview /> |{" "}
              <span style={{ marginLeft: "12px" }}>
                <Version />
              </span>
            </Footer>
          </Layout>
        </Layout>
        ) : (
          // If no organizations, prompt user to handle organizations
          <HandleOrganization />
        )}
    </Layout>
  );
}

export default BasicLayout;
