// ConnectionsChart.tsx

import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import * as echarts from 'echarts';
import { notification, Spin } from 'antd';
import moment from 'moment-timezone'; // Ensure timezone support
import { useRecoilState } from 'recoil';
import AxiosApiInstance from "../../common/Interceptors";
import { styleModeAtom, userAtom } from '../../common/Atoms';
import { URL_API_V1 } from '../../constants/global';
import { DEFAULT_DATETIME_FORMAT, TIMEZONE } from "../../constants/user";
import { useDeviceCharts } from '../../contexts/DeviceChartsContext';
import { EChartsOption, ScatterSeriesOption } from 'echarts'; // Import ScatterSeriesOption

interface ConnectionDataPoint {
  distinct_connection_count: number;
  src_ip: string;
  window_start: string;
}

interface ConnectionsChartProps {
  deviceIp: string;
}

const ConnectionsChart: React.FC<ConnectionsChartProps> = ({ deviceIp }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstanceRef = useRef<echarts.ECharts | null>(null); // Ref to store ECharts instance
  const [chartData, setChartData] = useState<ConnectionDataPoint[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [user] = useRecoilState(userAtom);
  const { axiosApiInstance } = AxiosApiInstance();
  const [themeState] = useRecoilState(styleModeAtom);
  const isDarkTheme = themeState.theme === "dark";

  const { updateChart } = useDeviceCharts();

  // Define the unit and scaling factor (Not used here but kept for consistency)
  type UnitType = 'KB' | 'MB';
  const UNIT: UnitType = 'MB' as UnitType; // Always use 'MB'
  const SCALING_FACTOR = 1024 * 1024; // 1 MB = 1048576 bytes

  /**
   * Aggregates data by ensuring chronological order.
   */
  const aggregateData = useCallback(
    (data: ConnectionDataPoint[]): ConnectionDataPoint[] => {
      if (data.length === 0) return [];

      // Sort data in ascending order based on window_start timestamp
      return data.sort((a, b) => moment(a.window_start).valueOf() - moment(b.window_start).valueOf());
    },
    []
  );

  /**
   * Fetches connections data for a specific device.
   */
  const fetchConnectionsData = useCallback(async () => {
    if (!user.organization) {
      notification.error({
        message: "Organization Not Selected",
        description: "Please select an organization before fetching connections data.",
        key: "organization-not-selected-connections",
        duration: 3,
      });
      setLoading(false);
      return;
    }

    try {
      const response = await axiosApiInstance.get(
        `${URL_API_V1}/r/${user.organization}/metrics/devices-connections`,
        { params: { src_ip: deviceIp } }
      );

      if (response && response.status === 200) {
        const aggregatedData = aggregateData(response.data);
        setChartData(aggregatedData);
      } else {
        notification.error({
          message: "Error fetching connections data",
          description: "Unable to fetch connections data.",
          key: "error-fetch-connections-data",
          duration: 3,
        });
      }
    } catch (error) {
      console.error("Error fetching connections data:", error);
      notification.error({
        message: "Error fetching connections data",
        description: "An error occurred while fetching connections data.",
        key: "error-fetch-connections-data",
        duration: 3,
      });
    } finally {
      setLoading(false);
    }
  }, [deviceIp, user.organization, aggregateData /* eslint-disable-line react-hooks/exhaustive-deps */]);

  /**
   * Fetch data on component mount and when `updateChart` changes.
   */
  useEffect(() => {
    fetchConnectionsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateChart]);

  /**
   * Generate a color map for each unique src_ip.
   * Uses HSL to ensure colors are evenly distributed and easy on the eyes.
   */
  const colorMap = useMemo(() => {
    const uniqueSrcIPs = Array.from(new Set(chartData.map(point => point.src_ip)));
    const map: { [key: string]: string } = {};

    uniqueSrcIPs.forEach((ip, index) => {
      const hue = (index * 360 / uniqueSrcIPs.length) % 360; // Evenly distribute hues
      const saturation = 70; // Fixed saturation for consistency
      const lightness = 50; // Fixed lightness for readability
      map[ip] = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    });

    return map;
  }, [chartData]);

  /**
   * useRef to track the previous theme.
   */
  const previousThemeRef = useRef<boolean>(isDarkTheme);

  /**
   * Initialize and manage the ECharts instance and event listeners.
   * This useEffect runs when the theme changes.
   */
  useEffect(() => {
    if (!chartRef.current) return;

    // Initialize chart if not already initialized
    if (!chartInstanceRef.current) {
      chartInstanceRef.current = echarts.init(chartRef.current, isDarkTheme ? 'dark' : 'light');
    } else if (previousThemeRef.current !== isDarkTheme) {
      // Theme has changed, dispose and re-initialize the chart
      chartInstanceRef.current.dispose();
      chartInstanceRef.current = echarts.init(chartRef.current, isDarkTheme ? 'dark' : 'light');
    }

    // Update the previous theme ref
    previousThemeRef.current = isDarkTheme;

    const myChart = chartInstanceRef.current;

    /**
     * Define the ECharts option.
     * The initial set is empty; options will be set in the next useEffect.
     */
    const options: EChartsOption = {
      backgroundColor: isDarkTheme ? '#2c343c' : '#ffffff', // Adjust background based on theme
      tooltip: {
        trigger: 'item', // Handle single data points
        formatter: (params: any) => {
          if (!params || typeof params !== 'object') return '';

          const data = params.data;
          const time = moment(data.value[0]).tz(TIMEZONE).format(DEFAULT_DATETIME_FORMAT);
          const count = data.value[1]; // No scaling needed for counts

          const tooltipText = `
            <strong>Distinct Connections</strong><br/>
            Time: ${time}<br/>
            Distinct Connections: ${count}<br/>
            Source IP: ${data.src_ip || 'N/A'}
          `;

          return tooltipText;
        },
        axisPointer: {
          type: 'cross',
        },
        backgroundColor: isDarkTheme ? '#333' : '#fff',
        textStyle: {
          color: isDarkTheme ? '#fff' : '#000',
        },
      },
      grid: {
        left: '10%',
        right: '10%',
        bottom: '15%',
        top: '10%',
        containLabel: true,
      },
      xAxis: {
        type: 'time',
        name: 'Time',
        axisLabel: {
          formatter: (value: number) => moment(value).format('HH:mm'),
          color: isDarkTheme ? '#ffffff' : '#000000',
        },
        splitLine: { show: true, lineStyle: { color: isDarkTheme ? '#444' : '#eee' } },
        splitNumber: 6,
        min: 'dataMin',
        max: 'dataMax',
      },
      yAxis: {
        type: 'value',
        name: 'Distinct Connections',
        axisLabel: {
          formatter: (value: number) => `${value}`,
          color: isDarkTheme ? '#ffffff' : '#000000',
        },
        splitLine: { show: true, lineStyle: { color: isDarkTheme ? '#444' : '#eee' } },
      },
      series: [
        {
          name: 'Distinct Connections',
          type: 'scatter',
          symbol: 'circle',
          symbolSize: 8, // Adjust size as needed
          itemStyle: {
            opacity: 1, // Default opacity
          },
          emphasis: {
            label: {
              show: false, // Disabled to prevent duplicate tooltip
            },
          },
          data: [], // Initially empty, to be updated in setOption
        },
      ] as ScatterSeriesOption[],
      legend: {
        data: ['Distinct Connections'],
        textStyle: {
          color: isDarkTheme ? '#ffffff' : '#000000',
        },
      },
      toolbox: {
        feature: {
          saveAsImage: { title: 'Save as Image' },
        },
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
        },
        {
          start: 0,
          end: 100,
        },
      ],
    };

    // Set the option on the current chart instance
    myChart.setOption(options, { notMerge: true });

    /**
     * Handle mouseover event to adjust opacity based on src_ip.
     * Only highlight datapoints with the same src_ip as the hovered one.
     */
    const handleMouseOver = (params: any) => {
      if (params && params.data && params.data.src_ip) {
        const hoveredSrcIp = params.data.src_ip;

        if (!chartInstanceRef.current) return; // Ensure chart is not disposed

        // Update the opacity of each datapoint based on src_ip
        const updatedData = chartData.map((point) => ({
          value: [moment(point.window_start).valueOf(), point.distinct_connection_count],
          src_ip: point.src_ip,
          itemStyle: {
            color: colorMap[point.src_ip],
            opacity: point.src_ip === hoveredSrcIp ? 1 : 0.2,
          },
        }));

        myChart.setOption({
          series: [
            {
              name: 'Distinct Connections',
              type: 'scatter',
              data: updatedData,
            },
          ],
        });
      }
    };

    /**
     * Handle mouseout event to reset opacity.
     */
    const handleMouseOut = () => {
      if (!chartInstanceRef.current) return; // Ensure chart is not disposed

      const resetData = chartData.map((point) => ({
        value: [moment(point.window_start).valueOf(), point.distinct_connection_count],
        src_ip: point.src_ip,
        itemStyle: {
          color: colorMap[point.src_ip],
          opacity: 1,
        },
      }));

      chartInstanceRef.current.setOption({
        series: [
          {
            name: 'Distinct Connections',
            type: 'scatter',
            data: resetData,
          },
        ],
      });
    };

    // Remove existing event listeners to prevent multiple bindings
    myChart.off('mouseover', handleMouseOver);
    myChart.off('mouseout', handleMouseOut);

    // Attach event listeners
    myChart.on('mouseover', handleMouseOver);
    myChart.on('mouseout', handleMouseOut);

    // Handle responsive resizing
    const resizeHandler = () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.resize();
      }
    };
    window.addEventListener('resize', resizeHandler);

    // Cleanup on unmount or theme change
    return () => {
      window.removeEventListener('resize', resizeHandler);
      myChart.off('mouseover', handleMouseOver);
      myChart.off('mouseout', handleMouseOut);
      myChart.dispose();
      chartInstanceRef.current = null;
    };
  }, [isDarkTheme, chartData, colorMap]);

  /**
   * Update the chart when chartData changes.
   */
  useEffect(() => {
    if (!chartInstanceRef.current) return;

    const myChart = chartInstanceRef.current;

    // Prepare the data with colors
    const preparedData = chartData.map((point) => ({
      value: [moment(point.window_start).valueOf(), point.distinct_connection_count],
      src_ip: point.src_ip,
      itemStyle: {
        color: colorMap[point.src_ip],
        opacity: 1, // Reset opacity
      },
    }));

    // Update the series data
    myChart.setOption({
      series: [
        {
          name: 'Distinct Connections',
          type: 'scatter',
          data: preparedData,
        },
      ],
    });
  }, [chartData, colorMap]);

  /**
   * Display loading spinner or no data message as appropriate.
   */
  if (loading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin tip="Loading connections chart data..." />
      </div>
    );
  }

  if (chartData.length === 0) {
    return <span>No Connections Data</span>;
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div ref={chartRef} style={{ width: '100%', height: 300 }} />
    </div>
  );
};

export default React.memo(ConnectionsChart);
