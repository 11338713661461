// First, define utility functions
export const getEventTypeColor = (type) => {
  const colors = {
    authentication: 'purple',
    system: 'cyan',
    security: 'red',
    database: 'blue',
    network: 'green',
    application: 'geekblue',
    compliance: 'gold'
  };
  return colors[type] || 'default';
};

export const getSeverityColor = (severity) => {
  const colors = {
    INFO: 'blue',
    WARNING: 'orange',
    ERROR: 'red',
    CRITICAL: 'red'
  };
  return colors[severity] || 'default';
};

// Add this utility function for sorting
export const getSorter = (key) => ({
  sorter: (a, b) => {
    // Handle nested event fields
    const aValue = a[key] || a.events?.match(new RegExp(`${key}: ([^\\n]+)`))?.[1];
    const bValue = b[key] || b.events?.match(new RegExp(`${key}: ([^\\n]+)`))?.[1];
    
    // Handle numeric values
    if (!isNaN(aValue) && !isNaN(bValue)) {
      return Number(aValue) - Number(bValue);
    }
    
    // Handle string values
    return String(aValue || '').localeCompare(String(bValue || ''));
  },
  sortDirections: ['ascend', 'descend'],
});


// Add this at the top level, after imports
export const SPACING_CONFIGS = {
  small: {
    padding: '2px 4px',
    margin: '2px',
    gap: '4px',
    fontSize: '12px',
    cardPadding: '4px 8px',
    eventItemPadding: '1px 3px',
    eventItemGap: '2px',
    eventItemFontSize: '12px',
  },
  medium: {
    padding: '8px 16px',
    margin: '8px',
    gap: '16px',
    fontSize: '14px',
    cardPadding: '16px 24px',
    eventItemPadding: '4px 8px',
    eventItemGap: '8px',
    eventItemFontSize: '13px',
  },
  large: {
    padding: '12px 24px',
    margin: '12px',
    gap: '24px',
    fontSize: '16px',
    cardPadding: '24px 32px',
    eventItemPadding: '8px 12px',
    eventItemGap: '12px',
    eventItemFontSize: '14px',
  },
};
