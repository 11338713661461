// EncryptionKeys.tsx

import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  ReloadOutlined,
  StopOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Space, Tooltip, Tour, Typography, Alert, Progress } from "antd";
import type { TourProps } from "antd";
import type { DatePickerProps } from "antd";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Layout,
  Modal,
  notification,
  Popconfirm,
  Popover,
  Row,
  Table,
  Tag,
} from "antd";
import { AES_GCM } from "common/AESEncryption";
import * as crypto from "crypto-browserify";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { userAtom } from "../../common/Atoms";
import AxiosApiInstance from "../../common/Interceptors";
import { URL_API_V1, DATA_SAMPLING_MASTER_KEY } from "../../constants/global";
import { SetupWizardContext } from "../SetupWizard/SetupWizardContext";
import styles from "./EncryptionKeys.module.scss";
import dayjs from "dayjs";
import { getDateWithTooltip } from "utils/utils";

const { Paragraph } = Typography;
const { Footer, Content } = Layout;
const MASTER_KEY = "masterKey";
const dateFormat = "YYYY-MM-DD";

// ==================================
// 1) interface & type definitions
// ==================================
interface EncryptionKeysProps {
  onEncryptionKeysChange?: (hasKeys: boolean) => void;
}

interface EncryptionKey {
  uuid: string;
  created_on: string;
  encryption_key: string;
  encryption_key_time_start: string;
  encryption_key_time_end: string;
  contains_encrypted_data: boolean;
  encryption_purpose: string;
  is_active: boolean;
}

interface SecurityKeysData {
  masterKey: string;
  dataEncryptionKey: string;
}

// New interface that combines public data with sensitive keys.
interface CombinedEncryptionKey extends SecurityKeysData {
  uuid: string;
  encryption_key: string;
}

enum CharacterType {
  LETTER_LOWERCASE = 0,
  LETTER_UPPERCASE = 1,
  LETTER_DIGIT = 2,
  LETTER_SPECIAL = 3,
}

const EncryptionKeys: React.FC<EncryptionKeysProps> = ({ onEncryptionKeysChange }) => {
  const context = useContext(SetupWizardContext);
  const setSecurityKeys = context?.setSecurityKeys;
  const [user] = useRecoilState(userAtom);
  const { axiosApiInstance } = AxiosApiInstance();
  const isUsingContext = Boolean(context);

  // State for the table of existing encryption keys
  const [state, setState] = useState({
    loading: true,
    hasData: false,
    data: [] as EncryptionKey[],
  });

  // Row selection states
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [newRecord, setNewRecord] = useState<EncryptionKey | undefined>();

  // Modal states
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState("");

  // Mapping of record UUIDs to generated (sensitive) keys
  const [generatedKeysMap, setGeneratedKeysMap] = useState<Record<string, SecurityKeysData>>({});

  // The Master Key that the user provides (or that we generate)
  const [masterKey, setMasterKey] = useState("");
  const [masterKeyVisible, setMasterKeyVisible] = useState(false);

  const [encryptionProgress, setEncryptionProgress] = useState(0);
  const [encryptionInProgress, setEncryptionInProgress] = useState(false);
  const [plaintextDEK, setPlaintextDEK] = useState<string | null>(null);

  // Tour references and tour step state (allowing dynamic modification)
  const addButtonRef = useRef<HTMLButtonElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const [openTour, setOpenTour] = useState(false);
  const tourShownRef = useRef(false);
  const [tourSteps, setTourSteps] = useState<TourProps["steps"]>([
    {
      title: "Add an Encryption Key",
      description: "Click here to add a new encryption key (Master Key).",
      target: () => addButtonRef.current!,
      placement: "left",
    },
    {
      title: "Select an Encryption Key",
      description: "Select from the list below.",
      target: () =>
        tableRef.current!.querySelector<HTMLElement>(".ant-table-selection-column")!,
    },
  ]);

  // antd Form for the modal
  const [form] = Form.useForm();

  // a) Generate ASCII-based Master Key or DEK
  function randomFloat() {
    const int = window.crypto.getRandomValues(new Uint32Array(1))[0];
    return int / 2 ** 32;
  }

  function getRandomArbitrary(min: number, max: number) {
    return Math.floor(randomFloat() * (max - min + 1) + min);
  }

  function generateEncryptionKeys(minSize: number, maxSize: number) {
    console.log("Generating keys...");
    // Generate Master Key
    let global_counter = 1000;
    let buffer: number[] = [];
    let length = getRandomArbitrary(minSize, maxSize);
    for (let i = 0; buffer.length < length; i++) {
      if (i > global_counter) break;
      let bytes = crypto.randomBytes(length);
      for (let j = 0; j < bytes.length; j++) {
        if (buffer.length === length) break;
        let choice = getRandomArbitrary(CharacterType.LETTER_LOWERCASE, CharacterType.LETTER_DIGIT);
        let charCodeAt = bytes.toString().charCodeAt(j);
        let value = Number(charCodeAt);
        if (isNaN(value) || value === -1) continue;
        switch (choice) {
          case CharacterType.LETTER_LOWERCASE:
            if (value >= 97 && value <= 122) buffer.push(value);
            break;
          case CharacterType.LETTER_UPPERCASE:
            if (value >= 65 && value <= 90) buffer.push(value);
            break;
          case CharacterType.LETTER_DIGIT:
            if (value >= 48 && value <= 57) buffer.push(value);
            break;
          default:
            break;
        }
      }
    }
    let mkResult = new TextDecoder().decode(new Uint8Array(buffer));
    form.setFieldsValue({ [MASTER_KEY]: mkResult });
    setMasterKey(mkResult);

    // Generate DEK (32 alphanumeric characters)
    let dekBuffer: number[] = [];
    let dekLength = 32;
    let dekBytes = crypto.randomBytes(dekLength);
    for (let i = 0; i < dekBytes.length; i++) {
      if (dekBuffer.length === dekLength) break;
      let choice = getRandomArbitrary(CharacterType.LETTER_LOWERCASE, CharacterType.LETTER_DIGIT);
      let charVal = dekBytes.toString().charCodeAt(i);
      if (!isNaN(charVal) && charVal !== -1) {
        switch (choice) {
          case CharacterType.LETTER_LOWERCASE:
            if (charVal >= 97 && charVal <= 122) dekBuffer.push(charVal);
            break;
          case CharacterType.LETTER_UPPERCASE:
            if (charVal >= 65 && charVal <= 90) dekBuffer.push(charVal);
            break;
          case CharacterType.LETTER_DIGIT:
            if (charVal >= 48 && charVal <= 57) dekBuffer.push(charVal);
            break;
          default:
            break;
        }
      }
    }
    let dekResult = new TextDecoder().decode(new Uint8Array(dekBuffer));
    console.log("DEK =>", dekResult);
    setPlaintextDEK(dekResult);
  }

  // b) Post to server
  const loadDataEncryptionKey = useCallback(async () => {
    setState((prev) => ({ ...prev, loading: true }));
    if (!user?.organization) {
      console.warn("User or organization not found.");
      setState({ loading: false, hasData: false, data: [] });
      return;
    }
    try {
      const response = await axiosApiInstance.get(
        `${URL_API_V1}/r/${user.organization}/security/encryption-keys/`
      );
      if (response.status === 200) {
        const entries = response.data?.entries || [];
        setState({
          loading: false,
          hasData: entries.length > 0,
          data: entries,
        });
      } else {
        console.warn(`Unexpected status code: ${response.status}`);
        setState({ loading: false, hasData: false, data: [] });
      }
    } catch (error) {
      console.error("Failed to load data encryption keys:", error);
      setState({ loading: false, hasData: false, data: [] });
    }
  }, [user]);

  useEffect(() => {
    console.log("useEffect visible loadDataEncryptionKey")
    loadDataEncryptionKey();
  }, [visible]);

  useEffect(() => {
    console.log("selectedRowKeys onEncryptionKeysChange")
    if (onEncryptionKeysChange) {
      onEncryptionKeysChange(selectedRowKeys.length > 0);
    }
  }, [selectedRowKeys]);

  // Create combined data that merges public data with sensitive keys.
  // Guarantee that dataEncryptionKey is never empty (fallback to encryption_key).
  const combinedData = useMemo<CombinedEncryptionKey[]>(() => {
    return state.data.map((item) => {
      const sensitive = generatedKeysMap[item.uuid];
      return {
        uuid: item.uuid,
        encryption_key: item.encryption_key,
        masterKey: sensitive ? sensitive.masterKey : "",
        dataEncryptionKey:
          sensitive && sensitive.dataEncryptionKey
            ? sensitive.dataEncryptionKey
            : item.encryption_key,
      };
    });
  }, [state.data, generatedKeysMap]);

  // Helper function to clear selection (both context keys and row selection)
  const clearEncryptionSelection = () => {
    setSecurityKeys?.((prev) => ({
      ...prev,
      masterKey: "",
      dataEncryptionKey: "",
    }));
    setSelectedRowKeys([]);
  };

  // Helper function to update keys from a clicked row using combined data
  const updateSecurityKeysFromRecord = (record: EncryptionKey) => {
    console.log("is_active log: ", record.is_active);
    if (record.is_active) {
      const combined = combinedData.find((item) => item.uuid === record.uuid);
      if (combined && combined.dataEncryptionKey) {
        setSecurityKeys?.((prev) => ({
          ...prev,
          masterKey: combined.masterKey, // May be empty – that's allowed
          dataEncryptionKey: combined.dataEncryptionKey,
        }));
      } else {
        clearEncryptionSelection();
      }
    }
  };

  const setKeyAsDefault = (uuid: string) => {
    axiosApiInstance
      .patch(`${URL_API_V1}/r/${user.organization}/security/encryption-keys/${uuid}`, {
        is_active: true,
      })
      .then(() => {
        notification.success({
          message: "Success",
          description: "Key set as default successfully",
        });
        loadDataEncryptionKey();
      })
      .catch(() => {
        notification.error({
          message: "Error",
          description: "Something went wrong",
        });
      });
  };

  const deactivateKey = (uuid: string) => {
    axiosApiInstance
      .patch(`${URL_API_V1}/r/${user.organization}/security/encryption-keys/${uuid}`, {
        is_active: false,
      })
      .then(() => {
        notification.success({
          message: "Success",
          description: "Key deactivated successfully",
        });
        clearEncryptionSelection();
        loadDataEncryptionKey();
      })
      .catch(() => {
        notification.error({
          message: "Error",
          description: "Something went wrong",
        });
      });
  };

  const markKeyForDeletion = (uuid: string) => {
    axiosApiInstance
      .patch(`${URL_API_V1}/r/${user.organization}/security/encryption-keys/${uuid}`, {
        mark_for_deletion: true,
      })
      .then(() => {
        notification.success({
          message: "Success",
          description: "Key marked for deletion successfully",
        });
        setGeneratedKeysMap((prev) => {
          const newMap = { ...prev };
          delete newMap[uuid];
          return newMap;
        });
        if (selectedRowKeys.includes(uuid)) {
          clearEncryptionSelection();
        }
        loadDataEncryptionKey();
      })
      .catch(() => {
        notification.error({
          message: "Error",
          description: "Failed to mark key for deletion",
        });
      });
  };

  // d) The main encryption step from version 1
  const encryptAndStore = async () => {
    setConfirmLoading(true);
    setEncryptionInProgress(true);
    setEncryptionProgress(0);
  
    if (!plaintextDEK || !masterKey) {
      notification.error({
        message: "Keys not generated. Please generate them first.",
      });
      setEncryptionInProgress(false);
      setConfirmLoading(false);
      return;
    }
  
    const progressTimer = setInterval(() => {
      setEncryptionProgress((prev) => {
        const next = prev + 5;
        return next < 90 ? next : 90;
      });
    }, 100);
  
    try {
      const dataEncryptionKeyCT = await new AES_GCM(masterKey).encrypt(plaintextDEK);
      clearInterval(progressTimer);
      setEncryptionProgress(100);
  
      const validFromValue = form.getFieldValue("validFrom");
      const payload = {
        encryption_key: dataEncryptionKeyCT,
        encryption_key_time_start:
          validFromValue?.format(dateFormat) || dayjs().format(dateFormat),
        encryption_purpose: "PRODUCTION",
      };
      console.log("Payload =>", payload);
  
      const res = await axiosApiInstance.post(
        `${URL_API_V1}/r/${user.organization}/security/encryption-keys/`,
        payload
      );
  
      if (res.status === 201) {
        notification.success({
          message: "Stored data encryption key in encrypted form",
        });
  
        const newRecord = res.data;
        setNewRecord(newRecord);
        downloadKeys(masterKey, plaintextDEK);
  
        setGeneratedKeysMap((prev) => ({
          ...prev,
          [newRecord.uuid]: {
            masterKey: masterKey,
            dataEncryptionKey: dataEncryptionKeyCT,
          },
        }));
  
        setSecurityKeys?.((prev) => ({
          ...prev,
          masterKey,
          dataEncryptionKey: dataEncryptionKeyCT,
        }));
  
        // NEW: If the newly created record is inactive,
        // clear the selection so that the "Next" button remains disabled.
        if (!newRecord.is_active) {
          clearEncryptionSelection();
        }
        
        // Optionally, add a tour step if needed (if no records existed before)
        if (!newRecord.is_active && state.data.length === 0 && !(tourSteps || []).some(step => step.title === "Activate Encryption Key")) {
          setTourSteps((prevSteps) => [
            ...(prevSteps || []),
            {
              title: "Activate Encryption Key",
              description:
                "This key is currently inactive. Click on the 'Actions' button in this row and select 'Activate' to enable it.",
              target: () =>
                tableRef.current?.querySelector(".actions-cell") || addButtonRef.current!,
              placement: "top",
            },
          ]);
          setOpenTour(true);
        }
      }
      form.resetFields();
      setVisible(false);
      setPlaintextDEK(null);
    } catch (err: any) {
      console.error("Error =>", err);
      setHasErrors(err.response?.data?.reason || JSON.stringify(err.response?.data));
      notification.error({
        message: "Failed to store encryption key",
      });
    } finally {
      clearInterval(progressTimer);
      setEncryptionInProgress(false);
      setConfirmLoading(false);
      setTimeout(() => setEncryptionProgress(0), 500);
    }
  };

  // e) “Download Keys” logic
  function downloadKeys(mk: string, dek: string) {
    const content = `MASTER_KEY:\n${mk}\n\nDATA_ENCRYPTION_KEY:\n${dek}`;
    const blob = new Blob([content], { type: "text/plain" });
    const element = document.createElement("a");
    element.href = URL.createObjectURL(blob);
    element.download = "encryption_keys.txt";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    notification.success({
      message: "Keys downloaded successfully",
    });
  }

  // Ensure context masterKey is kept in sync if there is a selection
  useEffect(() => {
    console.log("setSecurityKeyys: ", selectedRowKeys);
    console.log("masterKey: ", masterKey);
    if (context && masterKey && selectedRowKeys.length > 0) {
      setSecurityKeys?.((prevKeys) => ({
        ...prevKeys,
        masterKey,
      }));
    }
  }, [masterKey, selectedRowKeys]);

  const columns = [
    {
      title: "Data Encryption Key",
      dataIndex: "encryption_key",
      render: (text: string) =>
        text?.length > 12 ? text.substring(0, 12) + "..." : text,
    },
    {
      title: "Created On",
      dataIndex: "created_on",
      render: (text: string) => getDateWithTooltip(text),
    },
    {
      title: "Valid from",
      dataIndex: "encryption_key_time_start",
      render: (text: string) => getDateWithTooltip(text),
    },
    {
      title: "Valid to",
      dataIndex: "encryption_key_time_end",
      render: (text: string) => getDateWithTooltip(text),
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      key: "state",
      render: (_: any, row: EncryptionKey) => {
        if (row.encryption_key_time_end) {
          return <Tag color="red">Deactivated</Tag>;
        } else if (row.is_active) {
          return <Tag color="green">Active</Tag>;
        } else if (!row.encryption_key_time_start) {
          return <Tag color="red">Inactive</Tag>;
        }
        return <Tag color="orange">Inactive</Tag>;
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, row: EncryptionKey) => {
        const menuItems: React.ReactNode[] = [];
        if (row.encryption_key_time_end) {
          menuItems.push(
            <Menu.Item key="delete">
              <Tooltip title="Delete the encryption key" placement="right">
                <Popconfirm
                  title="Are you sure you want to delete this encryption key?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={(e) => {
                    e?.stopPropagation();
                    markKeyForDeletion(row.uuid);
                  }}
                >
                  <Button
                    type="text"
                    size="small"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={(e) => e.stopPropagation()}
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </Tooltip>
            </Menu.Item>
          );
        }
        if (row.is_active && !row.encryption_key_time_end) {
          menuItems.push(
            <Menu.Item key="deactivate">
              <Tooltip title="Deactivate the encryption key" placement="right">
                <Button
                  type="text"
                  size="small"
                  icon={<StopOutlined />}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    deactivateKey(row.uuid);
                  }}
                >
                  Deactivate
                </Button>
              </Tooltip>
            </Menu.Item>
          );
        }
        if (!row.is_active && !row.encryption_key_time_start) {
          menuItems.push(
            <Menu.Item key="activate">
              <Tooltip title="Activate the encryption key" placement="right">
                <Button
                  type="text"
                  size="small"
                  icon={<ReloadOutlined />}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setKeyAsDefault(row.uuid);
                  }}
                >
                  Activate
                </Button>
              </Tooltip>
            </Menu.Item>
          );
        }
        if (menuItems.length === 0) return null;
        // Add a custom className to the Actions cell for tour targeting.
        const menu = <Menu>{menuItems}</Menu>;
        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button className="actions-cell" size="small" onClick={(e) => e.stopPropagation()}>
              Actions <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  // Row selection handler – using combinedData for consistency.
  const onSelectChange = (newSelectedKeys: React.Key[], selectedRows: EncryptionKey[]) => {
    console.log("setSelectedRowKeys: ", newSelectedKeys);
    setSelectedRowKeys(newSelectedKeys);
    if (isUsingContext && setSecurityKeys) {
      const activeRows = selectedRows.filter((row) => row.is_active);
      console.log("Active rows: ", activeRows);
      if (activeRows.length > 0) {
        const selectedKey = activeRows[0];
        console.log("selectedKey: ", selectedKey);
        console.log("combinedData:", combinedData);
        const keysForSelected = combinedData.find((item) => item.uuid === selectedKey.uuid);
        console.log("keysForSelected:", keysForSelected);
        if (keysForSelected && keysForSelected.dataEncryptionKey) {
          setSecurityKeys((prev) => ({
            ...prev,
            masterKey: keysForSelected.masterKey,
            dataEncryptionKey: keysForSelected.dataEncryptionKey,
          }));
          console.log("Keys updated from combined data");
        } else {
          console.log("No sensitive keys for selected row");
          setSecurityKeys((prev) => ({
            ...prev,
            masterKey: "",
            dataEncryptionKey: "",
          }));
        }
      } else {
        console.log("No active rows selected");
        clearEncryptionSelection();
      }
    }
  };

  const rowSelection = {
    type: "radio" as const,
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: EncryptionKey) => ({
      disabled: !record.is_active,
      title: !record.is_active
        ? "This key is inactive. Activate it before selection."
        : undefined,
    }),
  };
  

  // Ensure clicking a row always updates security keys (even if already selected)
  const onRowClick = (record: EncryptionKey) => {
    if (isUsingContext) {
      updateSecurityKeysFromRecord(record);
    }
  };

  function showModal() {
    form.setFieldsValue({
      validFrom: dayjs(),
    });
    setVisible(true);
  }

  function handleCancel() {
    form.resetFields();
    setVisible(false);
    setPlaintextDEK(null);
  }

  const onChangeValidFrom: DatePickerProps["onChange"] = (date, dateString) => {
    console.log("Valid from =>", dateString);
  };

  useEffect(() => {
    console.log("useEffect stateData")
    if (!state.loading) {
      if (
        state.data.length === 0 &&
        !tourShownRef.current &&
        tableRef.current &&
        addButtonRef.current
      ) {
        setOpenTour(true);
        tourShownRef.current = true;
      } else {
        setOpenTour(false);
      }
    }
  }, [state.data, state.loading]);

  return (
    <div className={styles.EncryptionKeys}>
      <Tour open={openTour} onClose={() => setOpenTour(false)} steps={tourSteps || []} />

      <Content>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <h2>Encryption Keys</h2>
            <Paragraph>
              <strong>Important:</strong>
              <ul>
                <li>You will generate a Master Key, which is used to encrypt a Data Encryption Key (DEK).</li>
                <li>Keep the Master Key safe!</li>
                <li>We cannot recover the Master Key if it is lost.</li>
              </ul>
            </Paragraph>
          </Col>
          <Col span={24} style={{ textAlign: "right" }}>
            <Popover content="Add new encryption key" placement="left">
              <Button
                ref={addButtonRef}
                style={{ marginBottom: "1em" }}
                type="primary"
                onClick={showModal}
              >
                Add
              </Button>
            </Popover>
          </Col>
        </Row>

        <Modal
          className={styles.EncryptionKeys}
          maskClosable={false}
          closable
          title="Setup Encryption Key"
          centered
          open={visible}
          width={700}
          onCancel={handleCancel}
          footer={
            <Space style={{ width: "100%", justifyContent: "flex-end" }}>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button
                icon={<DownloadOutlined />}
                disabled={!masterKey || !plaintextDEK}
                onClick={() => {
                  if (masterKey && plaintextDEK) {
                    downloadKeys(masterKey, plaintextDEK);
                  } else {
                    notification.warning({
                      message: "No keys available for download",
                    });
                  }
                }}
              >
                Download Keys
              </Button>
              <Popconfirm
                title="Have you safely stored the Master Key?"
                okText="Yes"
                cancelText="No"
                onConfirm={encryptAndStore}
                okButtonProps={{ loading: confirmLoading }}
              >
                <Button type="primary">Save</Button>
              </Popconfirm>
            </Space>
          }
        >
          <Content>
            {encryptionInProgress && (
              <Progress percent={encryptionProgress} status="active" />
            )}
            <Alert
              message={
                <Space>
                  <WarningOutlined />
                  <span>Please keep your Master Key safe.</span>
                </Space>
              }
              description={
                <ul style={{ margin: 0, paddingLeft: "20px" }}>
                  <li>You will need it to decrypt sensitive data.</li>
                  <li>We cannot retrieve a lost Master Key for you.</li>
                  <li>Losing this key means losing access to your encrypted data.</li>
                </ul>
              }
              type="warning"
              showIcon
              style={{ marginBottom: "16px" }}
            />
            <Alert
              message={
                <Space>
                  <InfoCircleOutlined />
                  <span>Ensure your device is secure to prevent unauthorized access to your keys.</span>
                </Space>
              }
              type="info"
              showIcon
              style={{ marginBottom: "16px" }}
            />
            <Form
              form={form}
              initialValues={{
                validFrom: dayjs(),
              }}
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item
                    label="Master Key"
                    name={MASTER_KEY}
                    rules={[{ required: true, message: "Please provide a master key" }]}
                    tooltip={{
                      title: "Master Key is used to encrypt the DEK. Keep it very safe.",
                      icon: <InfoCircleOutlined />,
                    }}
                  >
                    <Input
                      type={masterKeyVisible ? "text" : "password"}
                      style={{ fontFamily: "monospace", fontSize: "large" }}
                      onChange={(e) => setMasterKey(e.target.value)}
                      prefix={[
                        <ReloadOutlined
                          key="generate"
                          style={{ paddingRight: 8, opacity: 0.7 }}
                          onClick={() => generateEncryptionKeys(16, 32)}
                          title="Generate a Master Key (ASCII) and a DEK behind the scenes"
                        />,
                        <CopyOutlined
                          key="copy"
                          style={{ paddingRight: 8, opacity: 0.7 }}
                          onClick={async () => {
                            const value = form.getFieldValue(MASTER_KEY);
                            if (value && "clipboard" in navigator) {
                              await navigator.clipboard.writeText(value);
                              notification.info({
                                message: "Master Key copied to clipboard",
                              });
                            }
                          }}
                          title="Copy Master Key"
                        />,
                      ]}
                    />
                  </Form.Item>
                  <Row gutter={[8, 8]}>
                    <Col>
                      <Button onClick={() => setMasterKeyVisible((prev) => !prev)}>
                        {masterKeyVisible ? "Hide" : "Show"}
                      </Button>
                    </Col>
                    <Col>
                      <Button type="primary" icon={<ReloadOutlined />} onClick={() => generateEncryptionKeys(16, 32)}>
                        Generate
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Divider />
                <Col span={12}>
                  <Form.Item
                    label="Valid from"
                    name="validFrom"
                    rules={[{ required: true, message: "Missing date" }]}
                    tooltip={{
                      title: "Starting date when your DEK is used for encryption",
                      icon: <InfoCircleOutlined />,
                    }}
                  >
                    <DatePicker defaultValue={dayjs()} onChange={onChangeValidFrom} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Content>
          <Footer style={{ background: "transparent", padding: "24px 0" }}>
            {hasErrors && <Alert type="error" message={hasErrors} banner />}
          </Footer>
        </Modal>

        <div ref={tableRef}>
          <Table
            rowSelection={isUsingContext ? rowSelection : undefined}
            rowKey="uuid"
            columns={columns}
            dataSource={state.data}
            loading={state.loading}
            onRow={(record) => ({
              onClick: () => {
                // Always update keys on row click and trigger row selection update.
                onRowClick(record);
                onSelectChange([record.uuid], [record]);
              },
              style: {
                cursor: isUsingContext && record.is_active ? "pointer" : "default",
              },
            })}
          />
        </div>
      </Content>
    </div>
  );
};

export default EncryptionKeys;
