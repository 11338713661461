// src/components/Dashboard/Dashboard.tsx

import { Card, Col, Row, Button, Spin, Alert } from 'antd';
import { Suspense, lazy, useCallback, useEffect, useState } from 'react';
import styles from './Dashboard.module.scss';
import { useRecoilState } from 'recoil';
import { userAtom } from '../../common/Atoms';
import { URL_API_V1 } from '../../constants/global';
import AxiosApiInstance from '../../common/Interceptors';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { SetupWizardProvider } from '../SetupWizard/SetupWizardContext';
import SetupWizard from '../SetupWizard/SetupWizard';

export interface Organization {
  uuid: string;
  slug: string;
  name: string;
  setup_completed: boolean;
}

interface DiskUsageCategory {
  label: string;
  count: number;
  totalSize: number;
}

interface DiskUsageResponse {
  [key: string]: DiskUsageCategory;
}

const ThroughputChart = lazy(() => import('components/Charts/ThroughputChart'));
// Removed StorageUsageChart as it's no longer needed

function Dashboard() {
  // 1. All Hooks at the Top Level
  const { axiosApiInstance } = AxiosApiInstance();
  const [user, setUser] = useRecoilState(userAtom);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [setupWizardVisible, setSetupWizardVisible] = useState(false);

  // Disk Usage States
  const [diskUsage, setDiskUsage] = useState<number | null>(null);
  const [diskLoading, setDiskLoading] = useState<boolean>(false);
  const [diskError, setDiskError] = useState<string | null>(null);

  // Devices Count States
  const [devicesCount, setDevicesCount] = useState<number | null>(null);
  const [devicesLoading, setDevicesLoading] = useState<boolean>(false);
  const [devicesError, setDevicesError] = useState<string | null>(null);

  // Threat Intelligence States
  const [threatCount, setThreatCount] = useState<number | null>(null);
  const [threatLoading, setThreatLoading] = useState<boolean>(false);
  const [threatError, setThreatError] = useState<string | null>(null);

  // Function to fetch organizations
  const getOrganizations = async () => {
    setLoading(true);
    setError(null); // Reset error before fetching
    try {
      const response = await axiosApiInstance.get(`${URL_API_V1}/organizations/`);
      if (response.status === 200) {
        const data: Organization[] = response.data.results;
        setOrganizations(data);

        // Extract slugs and update user if necessary
        const organizationSlugs = data.map((org) => org.slug);
        if (JSON.stringify(user.organizations) !== JSON.stringify(organizationSlugs)) {
          setUser((prevUser) => ({ ...prevUser, organizations: organizationSlugs }));
        }
      }
    } catch (error) {
      console.error("Error fetching organizations:", error);
      setError("Failed to load organizations. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch disk usage
  const getDiskUsage = async () => {
    setDiskLoading(true);
    setDiskError(null); // Reset any previous errors

    try {
      const response = await axiosApiInstance.get<DiskUsageResponse>(
        `${URL_API_V1}/r/${user.organization}/disk-usage/`,
        {
          params: { 'data-type': 'all' }, // Adjust or remove if not needed
        }
      );

      if (response.status === 200 && response.data) {
        // Sum all totalSize values
        const totalSizeInBytes = Object.values(response.data).reduce(
          (sum, category) => sum + category.totalSize,
          0
        );
        const totalSizeInMB = totalSizeInBytes / (1024 * 1024); // Convert bytes to megabytes
        setDiskUsage(totalSizeInMB);
      } else {
        console.warn(`Unexpected response status: ${response.status}`);
        setDiskError(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching disk usage:", error);
      setDiskError("Failed to fetch disk usage data.");
    } finally {
      setDiskLoading(false);
    }
  };

  // Function to fetch devices count
  const fetchDevicesCount = useCallback(async () => {
    setDevicesLoading(true);
    setDevicesError(null); // Reset any previous errors
  
    try {
      const response = await axiosApiInstance.get(`${URL_API_V1}/r/${user.organization}/devices/`, {
        params: { limit: 1 }, // Fetching one device to get the total count
      });
  
      if (response.status === 200 && response.data) {
        const { total } = response.data;
        setDevicesCount(total);
      } else {
        console.warn(`Unexpected response status: ${response.status}`);
        setDevicesError(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching devices count:", error);
      setDevicesError("Failed to fetch devices count.");
    } finally {
      setDevicesLoading(false);
    }
  }, [user.organization]);

  // Function to fetch Threat Intelligence count
  const fetchThreatCount = useCallback(async () => {
    setThreatLoading(true);
    setThreatError(null); // Reset any previous errors

    try {
      const response = await axiosApiInstance.get(`${URL_API_V1}/threat-feeds/count/`);
      if (response.status === 200 && response.data) {
        // Assuming the response contains a 'count' field
        const { threat_key_count } = response.data;
        setThreatCount(threat_key_count);
      } else {
        console.warn(`Unexpected response status: ${response.status}`);
        setThreatError(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching Threat Intelligence count:", error);
      setThreatError("Failed to fetch Threat Intelligence data.");
    } finally {
      setThreatLoading(false);
    }
  }, []);

  // Helper function to get organization by slug
  const getOrganizationBySlug = (organization_slug: string): Organization | undefined => {
    return organizations.find((org) => org.slug === organization_slug);
  };

  // Handler for the setup button
  const handleSetup = () => {
    navigate('/app-settings/setup');
  };

  // Handler for wizard completion
  const hideSetupComplete = () => {
    setSetupWizardVisible(false);
    // Optionally refresh organizations to update setup status
    getOrganizations();
  };

  // 3. useEffect Hooks
  useEffect(() => {
    getOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user.organization) {
      getDiskUsage();
      fetchThreatCount(); // Fetch Threat Intelligence count when organization is set
    } else {
      setDiskUsage(null); // Reset disk usage if no organization is selected
      setThreatCount(null); // Reset Threat Intelligence count
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.organization]);

  useEffect(() => {
    if (user.organization) {
      fetchDevicesCount();
    } else {
      setDevicesCount(null); // Reset devices count if no organization is selected
    }
  }, [user.organization, fetchDevicesCount]);

  // 4. Determine the current organization
  const currentOrganization = user.organization ? getOrganizationBySlug(user.organization) : undefined;

  // 5. Conditional Returns

  // Render loading spinner
  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <Spin size="large" />
      </div>
    );
  }

  // Render error message if any
  if (error) {
    return (
      <div className={styles.setupContainer}>
        <div>
          <Alert message={error} type="error" showIcon />
          <Button type="primary" onClick={getOrganizations} style={{ marginTop: '16px' }}>
            Retry
          </Button>
        </div>
      </div>
    );
  }

  // If setup is not completed, show the setup button and wizard
  if (currentOrganization && !currentOrganization.setup_completed) {
    return (
      <>
        <div
          className={styles.setupContainer}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '200px', // Adjust as needed
            textAlign: 'center',
          }}
        >
          <h4 style={{ marginBottom: '20px' }}>
            Setup is incomplete for this organization. Please click on the button to be redirected to the Setup Page.
          </h4>
          <Button type="primary" size="large" onClick={handleSetup}>
            Go to Setup Page
          </Button>
        </div>
    
        <SetupWizardProvider>
          <SetupWizard
            visible={setupWizardVisible}
            onComplete={hideSetupComplete}
            onCancel={() => setSetupWizardVisible(false)}
            notificationUuid={undefined}
          />
        </SetupWizardProvider>
      </>
    );
       
  }

  // 6. Main Return
  return (
    <Card title="Dashboard" style={{ margin: '0px 25px' }}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title="Events & Volume in bytes" style={{ margin: '5px' }}>
            <Suspense fallback={<div>Loading Throughput Chart...</div>}>
              <ThroughputChart />
            </Suspense>
          </Card>
        </Col>

        <Col span={12}>
          <Card title="Data Storage Usage (MB)" style={{ margin: '5px' }}>
            {diskLoading ? (
              <Spin />
            ) : diskError ? (
              <Alert message={diskError} type="error" showIcon />
            ) : diskUsage !== null ? (
              <div className={styles.diskUsageValue} style={{ fontSize: '2.5rem', fontWeight: 'bold', textAlign: 'center' }}>
                {diskUsage.toFixed(2)} MB
              </div>
            ) : (
              <div>No data available.</div>
            )}
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Number of Devices" style={{ margin: '5px' }}>
            {devicesLoading ? (
              <Spin />
            ) : devicesError ? (
              <Alert message={devicesError} type="error" showIcon />
            ) : devicesCount !== null ? (
              <div
                className={styles.devicesCountValue}
                style={{
                  fontSize: '2.5rem',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                {devicesCount} Devices
              </div>
            ) : (
              <div>No data available.</div>
            )}
          </Card>
        </Col>

        {/* New Card for Threat Intelligence */}
        <Col span={12}>
          <Card title="Threat Indicators Data Size" style={{ margin: '5px' }}>
            {threatLoading ? (
              <Spin />
            ) : threatError ? (
              <Alert message={threatError} type="error" showIcon />
            ) : threatCount !== null ? (
              <div
                className={styles.threatCountValue}
                style={{
                  fontSize: '2.5rem',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                {threatCount}
              </div>
            ) : (
              <div>No data available.</div>
            )}
          </Card>
        </Col>
      </Row>
    </Card>
  );
}

export default Dashboard;
