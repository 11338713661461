// useFetchEvents.ts

import { useState, useCallback, useRef, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { userSecretsAtom, userAtom } from '../common/Atoms';
import AxiosApiInstance from '../common/Interceptors';
import { URL_API_V2 } from '../constants/global';

export const FIELD_TO_EXCLUDE = ['_timestamp', 'meta_ss_event_type', 'other'];

export const useFetchEvents = (initialQueryTerms: any) => {
  console.log('useFetchEvents initialized with initialQueryTerms:', JSON.stringify(initialQueryTerms, null, 2));
  
  const { axiosApiInstance } = AxiosApiInstance();
  const [events, setEvents] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [histogram, setHistogram] = useState<any[]>([]);
  const [queryTerms, setQueryTerms] = useState(initialQueryTerms);
  const [user] = useRecoilState(userAtom);
  
  // Keep track of the latest queryTerms
  const queryTermsRef = useRef(queryTerms);
  useEffect(() => {
    queryTermsRef.current = queryTerms;
  }, [queryTerms]);

  const processRawData = (rawData: any[]) => {
    return rawData.map((item, idx) => {
      const other = Object.entries(item)
        .filter(([key]) => !FIELD_TO_EXCLUDE.includes(key))
        .map(([key, value]) => `${key}: ${value}`)
        .join(', ');
      const seq = 1 + idx;
      return { ...item, key: item.ingestion_id, other, seq };
    });
  };


  const fetchEvents = useCallback(
    async (
      startTime: number,
      endTime: number,
      offset: number,
      pageSize: number,
      currentQueryTerms: any = queryTermsRef.current
    ): Promise<{ hits: any[]; aggs: { histogram: any[] } }> => {
      console.log('fetchEvents called with params:', JSON.stringify({
        startTime,
        endTime,
        offset,
        pageSize,
        currentQueryTerms,
        defaultQueryTerms: queryTermsRef.current
      }, null, 2));

      setLoading(true);
      setError(null);

      const params = {
        size: pageSize,
        from: offset,
        startTime,
        endTime,
        queryTerms: JSON.stringify(currentQueryTerms),
      };

      console.log('API request being made with params:', JSON.stringify(params, null, 2));
      console.log('queryTerms being sent to API:', JSON.stringify(currentQueryTerms, null, 2));

      try {
        const response = await axiosApiInstance.get(
          `${URL_API_V2}/r/${user.organization}/events`,
          { params }
        );

        console.log('API response received:', JSON.stringify({
          status: response.status,
          queryTermsUsed: currentQueryTerms,
          dataLength: response.data?.hits?.length
        }, null, 2));

        if (response.status === 200) {
          const rawData: any[] = response.data.hits;
          const histogramData: any[] = response.data.aggs?.histogram || [];
          const updatedData: any[] = processRawData(rawData);

          const totalRecords = histogramData.reduce(
            (sum, item) => sum + item.value,
            0
          );
          setTotalCount(totalRecords);

          setEvents(updatedData);
          setHistogram(histogramData);

          return { hits: updatedData, aggs: { histogram: histogramData } };
        } else {
          throw new Error("Failed to fetch events");
        }
      } catch (err: any) {
        console.error("Error fetching events:", err);
        console.error("Query terms at time of error:", JSON.stringify(currentQueryTerms, null, 2));
        setError(err);
        return {
          hits: [],
          aggs: { histogram: [] },
        };
      } finally {
        setLoading(false);
      }
    },
    [user.organization]
  );

  return { 
    events, 
    totalCount, 
    loading, 
    error, 
    histogram, 
    fetchEvents, 
    setQueryTerms,
    queryTerms: queryTermsRef.current
  };
};
