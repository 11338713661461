// DynamicColumns.tsx

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Table, Tag } from "antd";
import { ColumnsType } from 'antd/es/table';
import { useRecoilState } from "recoil";
import { userSecretsAtom } from "common/Atoms";
import { PAGE_SIZE } from "./HistogramV3";
import { FIELD_TO_EXCLUDE } from "hooks/getEvents";
import { getEventTypeColor, getSeverityColor } from "../../utils/helpers";
import { useEventsTableContext } from "../../contexts/EventsTableContext";
import ModalPromptMasterKey from "common/ModalPromptMasterKey";
import { usePIIFields } from "../../contexts/PIIFieldsContext";

interface EventRecord {
  ingestion_id: string; // Ensure this field exists and is unique
  events?: string;
  other?: any;
  // Add other fields as per your data structure
  [key: string]: any;
}

interface EventTableV1Props {
  fetchEvents: (
    startTime: number,
    endTime: number,
    page: number,
    pageSize: number,
    queryTerms?: any
  ) => Promise<any>;
  setTableData: React.Dispatch<React.SetStateAction<any[]>>; // Updated type
  tableData: any[];
  totalCount: number;
  startTime: number;
  endTime: number;
  searchVersion: number;
  queryTerms: any;
}

const DynamicColumns: React.FC<EventTableV1Props> = ({
  fetchEvents,
  setTableData,
  tableData,
  totalCount,
  startTime,
  endTime,
  searchVersion,
  queryTerms,
}) => {
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const [userSecrets] = useRecoilState(userSecretsAtom);

  const {
    page,
    setPage,
    isLoading,
    isSelectFieldsViewVisible,
    setIsSelectFieldsViewVisible,
  } = useEventsTableContext();

  const {
    decryptedPiiFields,
    showPIIsInClear,
    setShowPIIsInClear,
    maskingTechniques,
    piiFields,
    getIconIfPiiField,
    showModalMasterKey,
    setShowModalMasterKey,
  } = usePIIFields();

  // Function to load data based on page and pageSize
  const loadMoreData = useCallback(
    async (pageNumber: number, pageSizeNumber: number, currentQueryTerms: any) => {
      if (loading) return;
      setLoading(true);
      try {
        const result = await fetchEvents(
          startTime,
          endTime,
          pageNumber,
          pageSizeNumber,
          currentQueryTerms
        );

        if (result && result.data) {
          // Process data to include a stable 'key' property
          const processedData = result.data.map((item: EventRecord) => ({
            ...item,
            key: item.ingestion_id, // Use 'ingestion_id' as the key
          }));
          setTableData(processedData);
          //setTotalCount(result.total);
          setPage(pageNumber);
          setPageSize(pageSizeNumber);
        }
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false);
      }
    },
    [loading, fetchEvents, startTime, endTime, setPage, setPageSize, setTableData]
  );

  // Initial data load and when dependencies change
  useEffect(() => {
    loadMoreData(1, pageSize, queryTerms);
  }, [searchVersion, pageSize, queryTerms]);

  // Process raw table data
  const flattenedData = useMemo(() => {
    return tableData.map((record: EventRecord) => {
      const flatRecord = { ...record };
      delete flatRecord.events;
      delete flatRecord.other;

      const eventPairs = (record.events || "")
        .split("\n")
        .reduce((acc, line) => {
          const [key, value] = line.split(": ");
          if (key && key.trim() !== "") { // Ensure key is not empty
            acc[key.trim()] = value ? value.trim() : "";
          } else {
            console.warn(`Empty or invalid key found in line: "${line}"`);
          }
          return acc;
        }, {} as Record<string, any>);

      return { ...flatRecord, ...eventPairs };
    });
  }, [tableData]);

  // Define table columns dynamically based on flattenedData
  const columns: ColumnsType<any> = useMemo(() => {
    if (flattenedData.length === 0) return [];

    return Object.keys(flattenedData[0]).map((key) => {
      const column: any = {
        title: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " "),
        dataIndex: key,
        key: key,
        ellipsis: true,
        sorter: (a: any, b: any) => {
          const aValue = a[key] || "";
          const bValue = b[key] || "";

          // Handle numeric sorting
          if (!isNaN(Number(aValue)) && !isNaN(Number(bValue))) {
            return Number(aValue) - Number(bValue);
          }

          // Default string sorting
          return String(aValue).localeCompare(String(bValue));
        },
        sortDirections: ["ascend", "descend"] as ("ascend" | "descend")[],
        render: (value: any) => {
          // Handle objects
          if (value && typeof value === "object") {
            return (
              <pre style={{ margin: 0, whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                {JSON.stringify(value, null, 2)}
              </pre>
            );
          }

          // Handle tags for specific fields
          if (key === "eventType") {
            return <Tag color={getEventTypeColor(value)}>{value}</Tag>;
          }
          if (key === "ss_severity") {
            return <Tag color={getSeverityColor(value)}>{value}</Tag>;
          }

          // Default rendering for strings or numbers
          return <span>{value}</span>;
        },
      };

      // Add filter to specific columns if needed (e.g., 'details')
      if (key === "details") { // Replace "details" with your actual key
        const uniqueTypes = Array.from(
          new Set(flattenedData.map((item: any) => item[key]?.type))
        ).filter(Boolean);

        column.filters = uniqueTypes.map((type: string) => ({ text: String(type), value: type }));
        column.onFilter = (value: string, record: any) => record[key]?.type === value;

        column.render = (value: any) => {
          if (value && typeof value === "object") {
            return value.type || <pre>{JSON.stringify(value, null, 2)}</pre>;
          }
          return <span>{value}</span>;
        };
      }

      return column;
    });
  }, [flattenedData, getEventTypeColor, getSeverityColor]);

  // Handle table and pagination changes
  const tableChangeHandler = useCallback(
    async (page: number, pageSize: number) => {
      // Convert page->offset
      const offset = (page - 1) * pageSize;
      
      // Now pass offset:
      try {
        const result = await fetchEvents(
          startTime,
          endTime,
          offset,
          pageSize,
          queryTerms
        );
        // handle result...

        //const processedData = processRawData(result.hits);
        setTableData(result.hits);
        setPage(page);
        setPageSize(pageSize);

      } catch (err) {
        console.error(err);
      }
    },
    [fetchEvents, startTime, endTime, queryTerms]
  );
  
  return (
    <>
      {isLoading ? (
        <div style={{ textAlign: "center" }}>Loading...</div>
      ) : (
        <Table
          dataSource={flattenedData}
          columns={columns}
          pagination={{
            current: page,
            pageSize: pageSize,
            total: totalCount,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            onChange: tableChangeHandler,
            onShowSizeChange: tableChangeHandler, // Handle pageSize changes
          }}
          loading={loading}
          scroll={{ x: "max-content" }} // Enable horizontal scrolling
          rowKey={(record) => record.ingestion_id} // Ensure each row has a unique key
        />
      )}
      <ModalPromptMasterKey
        visible={showModalMasterKey}
        onCancel={() => setShowModalMasterKey(false)}
      />
    </>
  );
};

export default DynamicColumns;
