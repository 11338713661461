import {
  EyeInvisibleTwoTone,
  EyeTwoTone,
  LockTwoTone,
} from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import { userAtom, userSecretsAtom } from "../common/Atoms";
//import { DECRYPTION_KEYS, KEY_NAME_TEXT_DECRYPTION_ENC_KEY } from '../../common/PseudonymizerService';
import {
  KEY_NAME_IP_DECRYPTION,
  KEY_NAME_TEXT_DECRYPTION_ENC_KEY,
} from "common/PseudonymizerService";
import { usePseudonymizationService } from "hooks/usePseudonymizationService";
import { useRecoilState } from "recoil";
import { EncryptDecrypt } from "../common/Encryption";
import {
  useMaskingTechniques,
  usePiiFields,
} from "../hooks/piiManagementHooks";
import {
  IDecryptedPiiField,
  IDecryptedPiiDevice,
  IFieldNameMaskingTechnique,
  IMaskingTechniques,
} from "../types/PIIFieldsTypes";

const PIIFieldsContext = createContext<
  | {
      decryptedPiiFields: IDecryptedPiiField[];
      piiFields: IFieldNameMaskingTechnique[];
      maskingTechniques: IMaskingTechniques[];
      showPIIsInClear: boolean;
      isLoading: boolean;
      setShowPIIsInClear: React.Dispatch<React.SetStateAction<boolean>>;
      decryptField: (
        fieldName: string,
        fieldValue: string,
        element: any
      ) => void;
      showModalMasterKey: boolean;
      setShowModalMasterKey: React.Dispatch<React.SetStateAction<boolean>>;
      getIconIfPiiField: (
        originFunctionName: string,
        fieldName: string,
        fieldValue: string,
        recordId: string
      ) => React.ReactNode;
      decryptValue: (fieldName: string, fieldValue: string) => string;
      getIconIfPiiFieldDevices: (fieldName, fieldValue) => React.ReactNode;
      decryptedPiiFieldsDevices: IDecryptedPiiDevice[];
    }
  | undefined
>(undefined);

export const PIIFieldsProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {

  const [showPIIsInClear, setShowPIIsInClear] = useState(false);

  const [showModalMasterKey, setShowModalMasterKey] = useState(false);

  const [piiFields, setPiiFields] = useState<IFieldNameMaskingTechnique[]>([]);
  const [decryptedPiiFieldsDevices, setDecryptedPiiFieldsDevices] = useState<
  IDecryptedPiiDevice[]
  >([]);
  const [decryptedPiiFields, setDecryptedPiiFields] = useState<
    IDecryptedPiiField[]
  >([]);
  const [maskingTechniques, setMaskingTechniques] = useState<
    IMaskingTechniques[]
  >([]);

  const [user] = useRecoilState(userAtom);
  const [userSecrets] = useRecoilState(userSecretsAtom);

  const { fetchKeys } = usePseudonymizationService();

  const IS_DEBUG = true;

  const { fetchPiiFields } = usePiiFields();
  const { fetchMaskingTechniques } = useMaskingTechniques();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    async function fetchKeysAndCheck() {
      try {
        const result = await fetchKeys();
        if (isMounted) {
          if (result.success && result.data) {
            //setDecryptionKeys(result.data as any);
          }
          //setShowModalMasterKey(true);
          
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          console.error("Error fetching keys:", error);
          //setShowModalMasterKey(true);
          setIsLoading(false);
        }
      }
    }

    fetchKeysAndCheck();

    return () => {
      isMounted = false;
    };
  }, [userSecrets]);

  useEffect(() => {
    let isMounted = true;
    async function fetchInitialData() {
      try {
        const [fetchedPiiFields, fetchedMaskingTechniques] = await Promise.all([
          fetchPiiFields(),
          fetchMaskingTechniques(),
        ]);
        if (isMounted) {
          const aggregatedPiiFieldsArray: IFieldNameMaskingTechnique[] =
            Object.values(fetchedPiiFields);
          setPiiFields(aggregatedPiiFieldsArray);
          setMaskingTechniques(fetchedMaskingTechniques);
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        if (isMounted) setIsLoading(false);
      }
    }
    fetchInitialData();
    return () => {
      isMounted = false;
    };
  }, []);

  function hidePiiField(fieldName, fieldValue, recordId) {
    let decryptedPiiFieldsUpdated = decryptedPiiFields.filter((item) => {
      let result =
        item["recordId"] == recordId && item["fieldName"] === fieldName;
      return result;
    });
    hideItem(fieldName, recordId);
    //setDecryptedPiiFields(decryptedPiiFieldsUpdated);
  }
  const decryptPiiField = useCallback(
    (fieldName, fieldValue, recordId) => {
      if (IS_DEBUG) console.log("decryptPiiField()");

      if (
        !userSecrets?.DECRYPTION_KEYS_OK &&
        !userSecrets?.DECRYPTION_KEYS &&
        !showModalMasterKey
      ) {
        setShowModalMasterKey(true);
        return;
      }

      if (
        !userSecrets ||
        !userSecrets.DECRYPTION_KEYS_OK ||
        !userSecrets.DECRYPTION_KEYS
      ) {
        setShowModalMasterKey(true);
        return;
      }

      // Get the decryption field info
      const piiFieldToDecrypt = piiFields.find(
        (item) => item.fieldName === fieldName
      );

      if (!piiFieldToDecrypt) {
        return;
      }

      const recordIndex = decryptedPiiFields.findIndex(
        (item) => item.recordId === recordId && item.fieldName === fieldName
      );

      let decryptedValue;

      if (recordIndex !== -1) {
        // Record exists, only change visibility if already decrypted
        decryptedPiiFields[recordIndex].showInClear = true;
      } else {
        // Record does not exist, decrypt and add
        decryptedValue = decryptSinglePiiFieldValue(
          userSecrets.DECRYPTION_KEYS[KEY_NAME_IP_DECRYPTION],
          piiFieldToDecrypt,
          fieldValue
        );
        const newRecord = {
          recordId,
          fieldName,
          encryptedValue: fieldValue,
          decryptedValue: decryptedValue,
          showInClear: true,
        };
        decryptedPiiFields.push(newRecord);
      }

      // Update the state immutably
      setDecryptedPiiFields([...decryptedPiiFields]);
    },
    [userSecrets, showModalMasterKey, setDecryptedPiiFields, decryptedPiiFields]
  );

  const decryptValue = (fieldName, fieldValue) => {
    let matchingPiiFields = piiFields.filter(
      (piiField) => piiField["fieldName"] === fieldName
    );

    if (!matchingPiiFields || matchingPiiFields.length === 0) {
      return fieldValue;
    }

    if (
      !userSecrets ||
      !userSecrets.DECRYPTION_KEYS_OK ||
      !userSecrets.DECRYPTION_KEYS
    ) {
      console.warn("No decryption keys available");
      return;
    }

    const piiFieldToDecrypt = piiFields.find(
      (item) => item.fieldName === fieldName
    );

    const decryptedValue = decryptSinglePiiFieldValue(
      userSecrets.DECRYPTION_KEYS[KEY_NAME_IP_DECRYPTION],
      piiFieldToDecrypt,
      fieldValue
    );

    return decryptedValue;
  };

  const addItem = useCallback((newItem: IDecryptedPiiField) => {
    setDecryptedPiiFields((prev) => {
      if (
        !prev.some(
          (item) =>
            item.recordId === newItem.recordId &&
            item.fieldName === newItem.fieldName
        )
      ) {
        return [...prev, newItem];
      }
      return prev;
    });
  }, []);

  const hideItem = useCallback((fieldName: string, recordId: string) => {
    setDecryptedPiiFields((prev) =>
      prev.map((item: IDecryptedPiiField) =>
        item.recordId === recordId && item.fieldName === fieldName
          ? { ...item, showInClear: false }
          : item
      )
    );
  }, []);

  const getIconIfPiiField = useCallback(
    (originFunctionName, fieldNameParam, fieldValue, recordId) => {
      const fieldName = fieldNameParam.replace(/^(payload_|meta_)/, "");
      //console.log("getFieldIconIfPiiField(): ", originFunctionName, fieldName, fieldValue, recordId);

      if (piiFields.length === 0 || maskingTechniques.length === 0) {
        //console.log("getIconIfPiiField(): No PII fields or masking techniques available");
        return null;
      }

      if (recordId.hasOwnProperty("key") && recordId["fieldName"] == "key") {
        console.log("key in recordId getIconIfPiiField recordId(): ", recordId);
        console.log("key => ", recordId);
        //console.log("key => ", recordId["key"])
        recordId = recordId["value"];
      }

      //console.log("getIconIfPiiField() decryptedPiiFields: ", decryptedPiiFields);

      let isPiiFieldDecrypted = decryptedPiiFields.some(
        (decryptedPiiField) =>
          decryptedPiiField.recordId === recordId &&
          decryptedPiiField.fieldName === fieldName &&
          decryptedPiiField.showInClear
      );
      //console.log("getIconIfPiiField() isPiiFieldDecrypted: ", isPiiFieldDecrypted);

      let matchingPiiFields = piiFields.filter(
        (piiField) => piiField["fieldName"] === fieldName
      );

      let isFieldPiiDecryptable = false;
      let isFieldPii = false;
      if (matchingPiiFields.length > 0) {
        isFieldPii = true; // Field is found, marking as PII Field

        let piiField = matchingPiiFields[0];
        let isPiiFieldDecryptable = maskingTechniques.some((entry) => {
          return (
            entry.value === piiField["maskingTechnique"] &&
            entry.is_two_way_function
          );
        });
        if (isPiiFieldDecryptable) {
          isFieldPiiDecryptable = true; // Field is a two way function, i.e. decryptable
        }
      }

      if (isPiiFieldDecrypted || (isFieldPiiDecryptable && showPIIsInClear)) {
        return (
          <Tooltip style={{ fontSize: 8 }} title="Hide">
            <Tag
              style={{
                padding: 0,
                margin: 0,
                border: "none",
                background: "transparent",
              }}
            >
              {!showPIIsInClear ? (
                <EyeTwoTone
                  color=""
                  onClick={(event) => {
                    event.stopPropagation();
                    hidePiiField(fieldName, fieldValue, recordId);
                  }}
                />
              ) : (
                <EyeTwoTone color="" />
              )}
            </Tag>
          </Tooltip>
        );
      } else if (isFieldPiiDecryptable) {
        return (
          <Tooltip style={{ fontSize: 8 }} title="Display in cleartext">
            <Tag
              style={{
                padding: 0,
                margin: 0,
                border: "none",
                background: "transparent",
              }}
            >
              <EyeInvisibleTwoTone
                onClick={(event) => {
                  event.stopPropagation();
                  decryptPiiField(fieldName, fieldValue, recordId);
                }}
              />
            </Tag>
          </Tooltip>
        );
      } else if (isFieldPii) {
        return (
          <Tooltip style={{ fontSize: 8 }} title="Not decryptable">
            <Tag
              style={{
                padding: 0,
                margin: 0,
                border: "none",
                background: "transparent",
              }}
            >
              <LockTwoTone />
            </Tag>
          </Tooltip>
        );
      }
      return <React.Fragment />;
    },
    [
      piiFields,
      maskingTechniques,
      decryptedPiiFields,
      userSecrets,
      showPIIsInClear,
    ]
  );

  const getIconIfPiiFieldDevices = useCallback(
    (fieldName, fieldValue) => {
      let returnValue = fieldValue;


      const isPiiFieldDecrypted = decryptedPiiFieldsDevices.some(
        (item) => item.decryptedValue === fieldValue && item.showInClear
      );

      const piiFieldToDecrypt = piiFields.find(
        (item) =>
          item.fieldName === "src_ip" ||
          item.fieldName === "dst_ip" ||
          item.fieldName === "ip_address"
      );

      const isIpAddress = maskingTechniques.some(
        (entry) =>
          entry.value ===
          (piiFieldToDecrypt && piiFieldToDecrypt["maskingTechnique"])
      );

      if (!isIpAddress) {
        return <React.Fragment />;
      }

      if (showPIIsInClear) {
        return <EyeTwoTone color="" />;
      }

      if (isPiiFieldDecrypted) {
        return (
          <Tooltip style={{ fontSize: 8 }} title="Hide">
            <Tag
              style={{
                padding: 0,
                margin: 0,
                border: "none",
                background: "transparent",
              }}
            >
              {!showPIIsInClear ? (
                <EyeTwoTone
                  color=""
                  onClick={(event) => {
                    event.stopPropagation();
                    // remove record maching decryptedValue
                    hideDevicePiiField(fieldName, fieldValue);
                  }}
                />
              ) : (
                <EyeTwoTone color="" />
              )}
            </Tag>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip style={{ fontSize: 8 }} title="Display in cleartext">
            <Tag
              style={{
                padding: 0,
                margin: 0,
                border: "none",
                background: "transparent",
              }}
            >
              <EyeInvisibleTwoTone
                onClick={(event) => {
                  event.stopPropagation();

                  if (
                    !userSecrets?.DECRYPTION_KEYS_OK &&
                    !userSecrets?.DECRYPTION_KEYS &&
                    !showModalMasterKey
                  ) {
                    setShowModalMasterKey(true);
                    return;
                  }

                  if (
                    !userSecrets ||
                    !userSecrets.DECRYPTION_KEYS_OK ||
                    !userSecrets.DECRYPTION_KEYS
                  ) {
                    console.warn("No decryption keys available");
                    return;
                  }

                  const decryptedPiiField = decryptedPiiFieldsDevices.filter(
                    (item) => item.decryptedValue === fieldValue || item.encryptedValue === fieldValue
                  );

                  if (decryptedPiiField.length > 0) {

                    if(!decryptedPiiField[0].showInClear) {
                      // Record exists, only change visibility if already decrypted
                      decryptedPiiField[0].showInClear = true;
                    }

                  } else {
                    returnValue = decryptSinglePiiFieldValue(
                      userSecrets.DECRYPTION_KEYS[KEY_NAME_IP_DECRYPTION],
                      piiFieldToDecrypt,
                      fieldValue
                    );

                    decryptedPiiFieldsDevices.push({
                      encryptedValue: fieldValue,
                      decryptedValue: returnValue,
                      showInClear: true,
                    });
                  }

                  setDecryptedPiiFieldsDevices([...decryptedPiiFieldsDevices]);
                }}
              />
            </Tag>
          </Tooltip>
        );
      }

    },
    [maskingTechniques, decryptedPiiFieldsDevices, userSecrets, showPIIsInClear]
  );

  const hideDevicePiiField = useCallback((fieldName, fieldValue) => {
    // let decryptedPiiFieldsUpdated = decryptedPiiFieldsDevices.filter(
    //   (item) => item["decryptedValue"] !== fieldValue
    // );

    setDecryptedPiiFieldsDevices((prev) =>
      prev.map((item: IDecryptedPiiDevice) =>
        item.decryptedValue === fieldValue
          ? { ...item, showInClear: false }
          : item
      )
    );
  }, []);

  useEffect(() => {
  }, [decryptedPiiFieldsDevices]);

  function decryptSinglePiiFieldValue(
    cmacEncKey,
    piiFieldToDecrypt,
    valueToDecrypt
  ) {
    const maskingTechnique = piiFieldToDecrypt["maskingTechnique"];

    if (!userSecrets) {
      return;
    }

    return EncryptDecrypt.decrypt(maskingTechnique, cmacEncKey, valueToDecrypt);
  }

  const decryptField = useCallback(
    (fieldName: string, fieldValue: string, element: any) => {
      if (!userSecrets?.DECRYPTION_KEYS_OK) {
        return;
      }

      const fieldToDecrypt = decryptedPiiFields.find(
        (pii) => pii.fieldName === fieldName && pii.recordId === element.id
      );
      if (fieldToDecrypt) {
        return;
      }
      if (!userSecrets || !userSecrets.DECRYPTION_KEYS) {
        return;
      }
      const decryptionKey =
        userSecrets.DECRYPTION_KEYS[KEY_NAME_TEXT_DECRYPTION_ENC_KEY];
      const decryptedValue = EncryptDecrypt.decrypt(
        fieldName,
        decryptionKey,
        fieldValue
      );
      const newDecryptedField: IDecryptedPiiField = {
        recordId: element.id,
        fieldName,
        encryptedValue: fieldValue,
        decryptedValue,
        showInClear: true,
      };

      setDecryptedPiiFields((prev) => [...prev, newDecryptedField]);
    },
    [decryptedPiiFields]
  );

  return (
    <PIIFieldsContext.Provider
      value={{
        decryptedPiiFields,
        piiFields,
        maskingTechniques,
        showPIIsInClear,
        isLoading,
        setShowPIIsInClear,
        decryptField,
        showModalMasterKey,
        setShowModalMasterKey,
        getIconIfPiiField,
        decryptValue,
        getIconIfPiiFieldDevices,
        decryptedPiiFieldsDevices,
      }}
    >
      {children}
    </PIIFieldsContext.Provider>
  );
};

export const usePIIFields = () => {
  const context = React.useContext(PIIFieldsContext);

  if (!context) {
    throw new Error("usePIIFields must be used within a PIIFieldsProvider");
  }

  return context;
};
