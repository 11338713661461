import {
  Button,
  Card,
  Col,
  notification,
  Row,
  Table,
  Tooltip,
  Dropdown,
  Menu,
  Popconfirm,
  Tag,
} from "antd";
import { DeleteOutlined, DownloadOutlined, DownOutlined } from "@ant-design/icons";
import React, { useEffect, useMemo, useState, useContext } from "react";
import { useRecoilState } from "recoil";
import { userAtom } from "../../common/Atoms";
import { URL_API_V1 } from "../../constants/global";
import AxiosApiInstance from "../../common/Interceptors";
import ToggleableKey from "common/ToggleableKey";
import { SetupWizardContext } from "components/SetupWizard/SetupWizardContext";
import { getDateWithTooltip } from "utils/utils";

interface APIKeysProps {} // Not used here

// Certificate interface as returned by the server
interface Certificate {
  uuid: string;
  name: string;
  created_on: string;
  principal: string;
  is_active: boolean;
  not_valid_before: string;
  not_valid_after: string;
  serial: string;
  deactivated_on: string | null;
}

const Certificates: React.FC = () => {
  const [user] = useRecoilState(userAtom);
  const { axiosApiInstance } = AxiosApiInstance();
  const [loading, setLoading] = useState(true);
  const [agentCerts, setAgentCerts] = useState<Certificate[]>([]);
  const [newCertName, setNewCertName] = useState("");

  const context = useContext(SetupWizardContext);
  const isUsingContext = Boolean(context);

  // Selected certificate UUIDs from table row selection
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  // When a certificate is deleted we want to clear the selection.
  const clearSelection = () => setSelectedRowKeys([]);

  // Function that fetches certificates (assumed to be defined on your API)
  const getAgentCertificates = async () => {
    try {
      setLoading(true);
      const response = await axiosApiInstance.get(
        `${URL_API_V1}/r/${user.organization}/kafka/agent-certs/`
      );
      if (response.status === 200) {
        const certs: Certificate[] = response.data.certificates || [];
        setAgentCerts(certs);
      } else {
        setAgentCerts([]);
      }
    } catch (error) {
      console.error("Failed to fetch certificates:", error);
      setAgentCerts([]);
    } finally {
      setLoading(false);
    }
  };

  // When the component loads or certificates update, if no row is selected, preselect the first certificate.
  useEffect(() => {
    if (agentCerts.length > 0 && selectedRowKeys.length === 0) {
      setSelectedRowKeys([agentCerts[0].uuid]);
      if (context) {
        context.setSecurityKeys((prev) => ({
          ...prev,
          certificateUuid: agentCerts[0].uuid,
        }));
      }
    }
  }, [agentCerts, selectedRowKeys, context]);

  // If the selected certificate gets deleted, clear selection.
  useEffect(() => {
    if (selectedRowKeys.length > 0) {
      const exists = agentCerts.some((cert) => cert.uuid === selectedRowKeys[0]);
      if (!exists) {
        clearSelection();
        if (context) {
          context.setSecurityKeys((prev) => ({
            ...prev,
            certificateUuid: "",
          }));
        }
      }
    }
  }, [agentCerts, selectedRowKeys, context]);


  function generateRandomWord(): string {
    const vowels = "aeiou";
    const consonants = "bcdfghjklmnpqrstvwxyz";
    
    // Random length between 6 and 12
    const length = Math.floor(Math.random() * 7) + 6;
    let result = "";
  
    // Randomly decide if the first character is a vowel or consonant
    let useVowel = Math.random() < 0.5;
  
    for (let i = 0; i < length; i++) {
      if (useVowel) {
        // Append a random vowel (keeping case random for variety)
        const char = vowels[Math.floor(Math.random() * vowels.length)];
        result += Math.random() < 0.5 ? char.toUpperCase() : char;
      } else {
        // Append a random consonant
        const char = consonants[Math.floor(Math.random() * consonants.length)];
        result += Math.random() < 0.5 ? char.toUpperCase() : char;
      }
      // Alternate between vowel and consonant for better pronounceability
      useVowel = !useVowel;
    }
    
    return result;
  }

  // Automatically add a certificate when "Add Certificate" is clicked.
  // This replaces the modal prompt.
  const addCertificate = async () => {
    try {
      // Generate an automatic name using random alphanumeric characters.
      const autoName = generateRandomWord();

      setNewCertName(autoName);
      // Directly post to the API without showing a modal.
      const response = await axiosApiInstance.post(
        `${URL_API_V1}/r/${user.organization}/kafka/agent-certs/`,
        { name: autoName }
      );
      if (response.status === 201) {
        const entry: Certificate = response.data;
        notification.success({
          message: "Success",
          description: "Certificate created successfully.",
        });
        // Append new certificate to the list.
        setAgentCerts((prev) => [...prev, entry]);
        // Refresh certificates (or you can use the new list directly)
        await getAgentCertificates();
      }
    } catch (error) {
      console.error("Error creating certificate:", error);
      notification.error({
        message: "Error",
        description: "Failed to create certificate.",
      });
    }
  };

  // Function to delete a certificate.
  const handleDelete = async (uuid: string) => {
    try {
      setLoading(true);
      const response = await axiosApiInstance.delete(
        `${URL_API_V1}/r/${user.organization}/kafka/agent-certs/${uuid}/`
      );
      if (response.status === 204) {
        notification.success({
          message: "Success",
          description: "Certificate deleted successfully.",
        });
        // Clear selection if the deleted certificate is currently selected.
        if (selectedRowKeys.includes(uuid)) {
          clearSelection();
          if (context) {
            context.setSecurityKeys((prev) => ({
              ...prev,
              certificateUuid: "",
            }));
          }
        }
        // Reload certificates.
        await getAgentCertificates();
      } else {
        notification.error({
          message: "Error",
          description: "Failed to delete certificate.",
        });
      }
    } catch (error) {
      console.error("Error deleting certificate:", error);
      notification.error({
        message: "Error",
        description: "Failed to delete certificate.",
      });
    } finally {
      setLoading(false);
    }
  };

  // Columns definition for the table.
  const columns = [
    {
      title: "ID",
      dataIndex: "uuid",
      key: "uuid",
      render: (uuid: string) => uuid.substring(0, 5),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: string) => name,
    },
    {
      title: "Principal",
      dataIndex: "principal",
      key: "principal",
      render: (text: string) => extractCN(text),
    },
    {
      title: "Created",
      dataIndex: "created_on",
      key: "created_on",
      render: (text: string) => getDateWithTooltip(text),
    },
    {
      title: "Not valid before",
      dataIndex: "not_valid_before",
      key: "not_valid_before",
      render: (text: string) => getDateWithTooltip(text),
    },
    {
      title: "Not valid after",
      dataIndex: "not_valid_after",
      key: "not_valid_after",
      render: (text: string) => getDateWithTooltip(text),
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      key: "is_active",
      render: (is_active: boolean) =>
        is_active ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: Certificate) => {
        const menu = (
          <Menu>
            <Menu.Item key="download">
              <Tooltip title="Download the certificate" placement="right">
                <Button
                  type="text"
                  size="small"
                  icon={<DownloadOutlined />}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    handleDownload(record.uuid);
                  }}
                >
                  Download
                </Button>
              </Tooltip>
            </Menu.Item>
            <Menu.Item key="delete">
              <Tooltip title="Delete the certificate" placement="right">
                <Popconfirm
                  title="Are you sure you want to delete this certificate?"
                  onConfirm={(e) => {
                    e?.stopPropagation();
                    handleDelete(record.uuid);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined /> Delete
                </Popconfirm>
              </Tooltip>
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button size="small" onClick={(e) => e.stopPropagation()}>
              Actions <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  // Utility: extract CN from principal string.
  const extractCN = (principal: string) => {
    const cnMatch = principal.match(/CN=([^,]+)/);
    return cnMatch ? cnMatch[1] : principal;
  };

  // Dummy implementation for handleDownload, since it's not provided.
  const handleDownload = (uuid: string) => {
    // Typically, call your API to download the certificate.
    console.log("Downloading certificate with UUID:", uuid);
  };

  // On component mount, load certificates.
  useEffect(() => {
    getAgentCertificates();
  }, []);

  return (
    <Card>
      <Row>
        <Col>
          <h3>Agent Certificates</h3>
        </Col>
      </Row>
      <Row
        style={{
          marginBottom: "8px", // Reduced margin
          display: "flex",
          alignItems: "center",
        }}
      >
        <Col span={12}>
          {/* You can add additional controls here if needed */}
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Button type="primary" onClick={() => {
                // Automatically generate a name and add the certificate.
                const autoName = "Certificate " + new Date().toLocaleTimeString();
                setNewCertName(autoName);
                // Directly call handleOk functionality (renamed to addCertificate here)
                addCertificate();
              }}>
            Add Certificate
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            rowSelection={{
              type: "radio",
              selectedRowKeys,
              onChange: (keys, rows) => {
                setSelectedRowKeys(keys);
                if (context && rows && rows.length > 0) {
                  const selectedCert = rows[0];
                  context.setSecurityKeys((prevKeys) => ({
                    ...prevKeys,
                    certificateUuid: selectedCert.uuid,
                  }));
                }
              },
            }}
            onRow={(record) => ({
              onClick: () => {
                if (isUsingContext) {
                  setSelectedRowKeys([record.uuid]);
                }
              },
              style: {
                cursor: isUsingContext ? "pointer" : "default",
              },
            })}
            rowKey="uuid"
            columns={columns}
            dataSource={agentCerts}
            bordered
          />
        </Col>
      </Row>
    </Card>
  );
};

export default Certificates;
