import { atom } from 'recoil';
import { User } from 'types/User';
import { UserSecrets } from "../types/UserSecretsTypes";

// Define a type for the effect parameters for better type safety
interface EffectParams {
  setSelf: (value: any) => void;
  onSet: (callback: (newValue: any, oldValue: any) => void) => void;
}


// Session Storage Effect
const sessionStorageEffect = (key: string) => ({ setSelf, onSet }: any) => {
  const savedValue = sessionStorage.getItem(key);
  if (savedValue != null) {
    try {
      const parsed = JSON.parse(savedValue);
      setSelf(parsed);
    } catch (error) {
      console.error(`Error parsing sessionStorage key "${key}":`, error);
      sessionStorage.removeItem(key);
    }
  } else {
    // If no saved value, initialize with system theme
    setSelf(getSystemTheme());
  }

  onSet((newValue: any) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(newValue));
    } catch (error) {
      console.error(`Error setting sessionStorage key "${key}":`, error);
    }
  });
};

// Atoms Definitions

// User Atom
export const userAtom = atom<User>({
  key: "user",
  default: {
    username: "",
    token: "",
    refresh: "",
    uuid: "",
    is_authenticated: false,
    organization: undefined,
    organizations: [],
    is_2fa_activated: false,
    is_2fa_authenticated: false,
  },
  effects_UNSTABLE: [sessionStorageEffect("user")],
});

// Notifications Refresh Atom
export const notificationsRefreshAtom = atom({
  key: 'notificationsRefreshAtom',
  default: 0,
});

// User Secrets Atom
export const userSecretsAtom = atom<UserSecrets>({
  key: "userSecrets",
  default: {
    DECRYPTION_KEYS_OK: false,
    DECRYPTION_KEYS: {},
    ENCRYPTED_DDK: {},
  },
  // If you want to persist userSecrets, you can add the effect here
  // effects_UNSTABLE: [sessionStorageEffect("userSecrets")],
});

// Utility function to detect system theme
const getSystemTheme = (): { theme: 'light' | 'dark' } => {
  if (typeof window !== 'undefined' && window.matchMedia) {
    return window.matchMedia('(prefers-color-scheme: dark)').matches
      ? { theme: 'dark' }
      : { theme: 'light' };
  }
  return { theme: 'light' }; // default to light if matchMedia is not supported
};

// Style Mode Atom (Theme)
export const styleModeAtom = atom({
  key: 'theme',
  default: { theme: 'light' }, // Placeholder; actual default set by effect
  effects_UNSTABLE: [sessionStorageEffect('theme')],
});

// Selected Event Atom
export const selectedEventAtom = atom({
  key: "selectedEvent",
  default: {},
});

// Current Menu Item Atom
export const currentMenuItemAtom = atom({
  key: "currentMenuItem",
  default: { currentMenuItem: "dashboard", openKeys: [] as any[], collapsed: false },
  effects_UNSTABLE: [sessionStorageEffect("currentMenuItem")],
});