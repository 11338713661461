// src/components/PiiFields.tsx

import React, { useContext, useEffect, useRef, useState, useMemo } from "react";
import {
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Modal,
  notification,
  Row,
  Select,
  Slider,
  Space,
  Switch,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { useRecoilState } from "recoil";
import { userAtom } from "../../common/Atoms";
import AxiosApiInstance from "../../common/Interceptors";
import { URL_API_V1 } from "../../constants/global";
import ModalAddField from "./ModalAddField";
import { Tour } from "antd";
import type { TourProps } from "antd";
import { SetupWizardContext } from "components/SetupWizard/SetupWizardContext";
import type { ColumnsType } from "antd/es/table";
import { Typography } from "antd";
import { Organization } from "types/Organization";

const { Option } = Select;
const { Content } = Layout;

/**
 * The structure for each masking technique.
 */
interface IMaskingTechniques {
  id: number;
  name: string;
  value: string;
  description: string;
  is_two_way: boolean;
}

/**
 * Possible actions when user hits Submit in the modal: Add or Edit.
 */
enum ActionType {
  ADD = "add",
  EDIT = "edit",
}

/**
 * Row data for the table, extended to include:
 * - active: indicates whether the group is active.
 */
interface IPiiFieldGroupRow {
  key: string;
  groupId: number;
  groupName: string;
  identifier: string;
  isActive: boolean;
  maskingTechniqueName: string;
  maskingTechniqueId: number;
  piiFieldId: number;
  piiFieldName: string;
  normalizedField: string;
  rowSpan: number;
  isFirstRow: boolean;
}

interface PrivacyMarkData {
  label: string;
  color: string;
  description: React.ReactNode;
}

/**
 * Slider marks including "Custom" at index 4.
 */

// Centralized data for each privacy level
const privacyMarkData: Record<number, PrivacyMarkData> = {
  0: {
    label: "None",
    color: "red",
    description: (
      <div>
        <strong>None</strong>: Privacy feature disabled.
        <br />
        <strong>Advantages:</strong> Full functionality and searching.
        <br />
        <strong>Drawbacks:</strong> No encryption.
      </div>
    ),
  },
  1: {
    label: "Low",
    color: "orange",
    description: (
      <div>
        <strong>Low</strong>: Encrypts Only Usernames, Emails.
        <br />
        Minimal performance impact, but partial privacy.
      </div>
    ),
  },
  2: {
    label: "Medium",
    color: "yellow",
    description: (
      <div>
        <strong>Medium</strong>: Encrypts Internal IPs, Usernames, Emails.
        <br />
        Good searching capabilities remain.
      </div>
    ),
  },
  3: {
    label: "High",
    color: "green",
    description: (
      <div>
        <strong>High</strong>: Encrypts All IPs, Hostnames, Domains, Usernames, Emails.
        <br />
        <strong>Drawbacks:</strong> Very limited external search or threat intelligence.
      </div>
    ),
  },
  4: {
    label: "Custom",
    color: "blue",
    description: (
      <div>
        <strong>Custom</strong>: Manually select which fields to encrypt.
        <br />
        Offers flexible privacy but requires advanced setup.
      </div>
    ),
  },
};



// A simple component that renders the Tooltip and Tag
const PrivacyMark: React.FC<{ level: number }> = ({ level }) => {
  const { label, color, description } = privacyMarkData[level];
  return (
    <Tooltip title={description}>
      <Tag style={{marginTop:"5px"}} color={color}>{label}</Tag>
    </Tooltip>
  );
};

// Define privacyMarks for use in your Slider
const privacyMarks: Record<number, React.ReactNode> = {
  0: <PrivacyMark level={0} />,
  1: <PrivacyMark level={1} />,
  2: <PrivacyMark level={2} />,
  3: <PrivacyMark level={3} />,
  4: <PrivacyMark level={4} />,
};


/**
 * Textual descriptions for each slider value to display alongside the slider.
 */
const privacyDescriptions: Record<number, string> = {
  0: "No privacy enabled.",
  1: "Encrypt only usernames and emails. Low overhead.",
  2: "Encrypt internal IPs, usernames, emails while retaining search capabilities.",
  3: "Encrypt all PII, including IPs, hostnames, domains, etc. Maximum privacy, limited utility.",
  4: "Custom: Manually configure which fields get encrypted.",
};

// Define the mapping between slider values and PrivacyLevel choices
const privacyLevelMap: { [key: number]: string } = {
  0: "NONE",
  1: "LOW",
  2: "MEDIUM",
  3: "HIGH",
  4: "CUSTOM",
};

// Reverse mapping for fetching current privacy level
const privacyLevelMapReverse: { [key: string]: number } = {
  NONE: 0,
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
  CUSTOM: 4,
};

export default function PiiFields() {
  const addButtonRef = useRef(null);
  const [openTour, setOpenTour] = useState(false);
  const tourShownRef = useRef(false); // To track if the Tour has been shown

  const { Text } = Typography;

  const [maskingTechniques, setMaskingTechniques] = useState<
    IMaskingTechniques[]
  >([]);
  const { axiosApiInstance } = AxiosApiInstance();
  const [user] = useRecoilState(userAtom);
  const [organizaton, setOrganization] = useState<Organization | null>(null);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [isAddOrEdit, setIsAddOrEdit] = useState<ActionType>(ActionType.ADD);
  const [showAddField, setShowAddField] = useState(false);
  const [hasErrors, setHasErrors] = useState("");
  const [availableFields, setAvailableFields] = useState<Record<number, any>>(
    {}
  );

  const context = useContext(SetupWizardContext);
  const isUsingContext = Boolean(context);

  // The main table state.
  const [state, setState] = useState({
    loading: true,
    hasData: false,
    data: [] as IPiiFieldGroupRow[],
  });

  // Steps for Tour usage
  const steps: TourProps["steps"] = [
    {
      title: "Add a Privacy Entry",
      description: "Click here to add a new privacy entry.",
      target: () => addButtonRef.current,
    },
  ];

    // Define the mapping between privacy levels and active identifiers
  const privacyLevelActiveIdentifiers: { [key: string]: string[] } = {
    NONE: [],
    LOW: ["ip_address_source", "usernames_deterministic"],
    MEDIUM: ["ip_address_source", "usernames_deterministic", "hostnames_deterministic"],
    HIGH: ["ip_address_source", "ip_address_destination", "usernames", "hostnames"],
    CUSTOM: [], // Handled separately
  };

  const [currentPrivacyLevel, setCurrentPrivacyLevel] =
    useState<string>("MEDIUM"); // Original privacy level
  const [sliderValue, setSliderValue] = useState<number>(
    privacyLevelMapReverse["MEDIUM"]
  ); // New slider value
  const [privacyLevelChanged, setPrivacyLevelChanged] =
    useState<boolean>(false); // Tracks if change occurred

  // Track unsaved changes
  const [changedGroups, setChangedGroups] = useState<Record<number, boolean>>(
    {}
  );
  const [saving, setSaving] = useState(false); // To manage loading state during save

  /**
   * Toggles the 'active' state for a given groupId.
   * Only allows toggling when in 'Custom' mode.
   */
  const onToggleActive = (groupId: number, checked: boolean) => {
    if (sliderValue !== 4) {
      // Do not allow toggling unless in Custom mode
      notification.warning({
        message: "Action Restricted",
        description:
          "You can only modify active status in Custom privacy mode.",
      });
      return;
    }

    setChangedGroups((prev) => ({
      ...prev,
      [groupId]: checked,
    }));
  };

  /**
   * Fetch organization details to get the current privacy_level.
   */
  const fetchOrganizationDetails = async () => {
    try {
      // 1. Fetch all organizations
      const orgsResponse = await axiosApiInstance.get(`${URL_API_V1}/organizations/`);
      if (orgsResponse.status === 200) {
        const organizations = orgsResponse.data.results || [];
        const organizationFound = organizations.find(
          (org: any) => org.slug === user.organization
        );

        if (organizationFound) {
          // 2. Get detail of the chosen org
          const detailResponse = await axiosApiInstance.get(
            `${URL_API_V1}/r/${user.organization}/organizations/${organizationFound.uuid}`
          );
          if (detailResponse.status === 200) {
            const organization = detailResponse.data;
            setOrganization(organization);

            // 3. If the privacy level is "NONE", for instance
            const privacyLevel = organization.privacy_level; // e.g. "NONE"
            setCurrentPrivacyLevel(privacyLevel);

            // 4. Convert "NONE"/"LOW"/"MEDIUM" etc. to numeric slider (0..4)
            const newSliderVal = 
              privacyLevelMapReverse[privacyLevel] !== undefined
                ? privacyLevelMapReverse[privacyLevel]
                : 2; // default to MEDIUM if undefined

            setSliderValue(newSliderVal);

            // 5. Re-apply that logic to the table rows
            setState((prev) => {
              const updatedRows = applyPrivacyLevelToData(newSliderVal, prev.data);
              return {
                ...prev,
                data: updatedRows,
                hasData: updatedRows.length > 0,
              };
            });
          }
        } else {
          console.error("Organization not found for the given slug.");
        }
      } else {
        console.error("Error fetching organization details:", orgsResponse);
      }
    } catch (error) {
      console.error("Error fetching organization details:", error);
    }
  };

  /**
   * Adjust privacy level using the Slider.
   * When switching to non-Custom modes, reset active statuses accordingly.
   */
  const onChangePrivacyLevel = (value: number) => {
    const selectedPrivacyLevel = privacyLevelMap[value];        // e.g., "NONE", "LOW"
    const hasChanged = selectedPrivacyLevel !== currentPrivacyLevel;
    setPrivacyLevelChanged(hasChanged);
  
    setSliderValue(value);
  
    // Re-apply privacy-level logic to the table data
    const updatedRows = applyPrivacyLevelToData(value, state.data);
    setState((prev) => ({
      ...prev,
      data: updatedRows,
      hasData: updatedRows.length > 0,
    }));
  
    // If you want to clear toggles when leaving custom mode:
    if (value !== 4) {
      setChangedGroups({});
    }
  };

  function applyPrivacyLevelToData(
    sliderValue: number,
    data: IPiiFieldGroupRow[]
  ): IPiiFieldGroupRow[] {
    const newData = [...data];
    
    // Get the privacy level string from the slider value
    const privacyLevel = privacyLevelMap[sliderValue];
    
    // Get the active identifiers for this privacy level
    const activeIdentifiers = privacyLevelActiveIdentifiers[privacyLevel];
    
    // If we're in custom mode, don't modify anything
    if (privacyLevel === 'CUSTOM') {
      return newData;
    }
    
    // Update each row's active status based on whether its identifier
    // is in the activeIdentifiers array
    newData.forEach((row) => {
      row.isActive = activeIdentifiers.includes(row.identifier.toLowerCase());
    });
    
    return newData;
  }
  


  /**
   * Fetch all required data: privacy_level, PiiFieldGroups, Masking Techniques, and Fields.
   */
  useEffect(() => {
    const fetchData = async () => {
      await fetchOrganizationDetails();
      getAllFields();
      fetchMaskingTechniques();
      getPiiFieldGroups();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!state.loading) {
      if (state.data.length === 0 && isUsingContext && !tourShownRef.current) {
        setOpenTour(true);
        tourShownRef.current = true;
      } else {
        setOpenTour(false);
      }
    }
  }, [state.loading, state.data, isUsingContext]);

  /**
   * Function to set the Modal in edit mode and populate form fields.
   */
  const setEditVisible = (row: IPiiFieldGroupRow) => {
    setVisible(true);
    setIsAddOrEdit(ActionType.EDIT);

    // Gather all field IDs for the selected group
    const groupRows = state.data.filter((item) => item.groupId === row.groupId);
    const fieldIds = groupRows.map((item) => item.piiFieldId);

    form.setFieldsValue({
      id: row.groupId,
      name: row.groupName,
      maskingTechnique: row.maskingTechniqueId,
      fields: fieldIds,
    });
  };

  /**
   * Function to set the Modal in add mode.
   */
  const setAddVisible = () => {
    if (sliderValue !== 4) {
      notification.warning({
        message: "Action Restricted",
        description:
          "You can only add or edit PII Field Groups in Custom privacy mode.",
      });
      return;
    }

    setIsAddOrEdit(ActionType.ADD);
    setVisible(true);
    form.resetFields();
  };

  /**
   * Function to handle creating or updating a PII Field Group.
   */
  const createOrUpdate = (action: ActionType) => {
    if (sliderValue !== 4) {
      notification.warning({
        message: "Action Restricted",
        description:
          "You can only add or edit PII Field Groups in Custom privacy mode.",
      });
      return;
    }

    setHasErrors("");
    setIsAddOrEdit(action);

    if (action === ActionType.ADD) {
      addPiiField();
    } else {
      updatePiiField();
    }
  };

  /**
   * Function to update a PII Field Group.
   */
  const updatePiiField = () => {
    form.validateFields().then((values: any) => {
      const data: any = {
        name: values["name"],
        masking_technique_id: values["maskingTechnique"],
      };

      if (Array.isArray(values["fields"]) && values["fields"].length > 0) {
        data["fields"] = values["fields"].map((field: any) =>
          typeof field === "object" && field.hasOwnProperty("value")
            ? field.value
            : field
        );
      }

      axiosApiInstance
        .put(
          `${URL_API_V1}/r/${user.organization}/events/pii/field-groups/${values["id"]}/`,
          data
        )
        .then((response) => {
          if (response && (response.status === 200 || response.status === 204)) {
            getPiiFieldGroups();
            setVisible(false);
            form.resetFields();
            notification.success({
              message: "Success",
              description: "PII Field Group updated successfully.",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.response?.data?.reason) {
            setHasErrors(err.response.data.reason);
          } else if (err.response?.data) {
            setHasErrors(err.response.data[0]);
          } else {
            setHasErrors("An unexpected error has occurred.");
          }
        });
    });
  };

  /**
   * Function to add a new PII Field Group.
   */
  const addPiiField = () => {
    form.validateFields().then((values: any) => {
      const data: any = {
        name: values["name"],
        masking_technique_id: values["maskingTechnique"],
      };

      if (Array.isArray(values["fields"]) && values["fields"].length > 0) {
        data["fields"] = values["fields"].map(
          (field: any) => field.value || field
        );
      }

      axiosApiInstance
        .post(
          `${URL_API_V1}/r/${user.organization}/events/pii/field-groups`,
          data
        )
        .then((response) => {
          if (response && response.status === 201) {
            getPiiFieldGroups();
            setVisible(false);
            form.resetFields();
            notification.success({
              message: "Success",
              description: "PII Field Group added successfully.",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          if (err.response?.data?.reason) {
            setHasErrors(err.response.data.reason);
          } else if (err.response?.data) {
            setHasErrors(err.response.data[0]);
          } else {
            setHasErrors("An unexpected error has occurred.");
          }
        });
    });
  };

  /**
   * Function to delete a PII Field Group.
   */
  const deleteItem = (groupId: number) => {
    if (sliderValue !== 4) {
      notification.warning({
        message: "Action Restricted",
        description:
          "You can only delete PII Field Groups in Custom privacy mode.",
      });
      return;
    }

    Modal.confirm({
      title: "Are you sure you want to delete this PII Field Group?",
      icon: <DeleteOutlined />,
      onOk: () => {
        setState((prev) => ({ ...prev, loading: true }));
        axiosApiInstance
          .delete(
            `${URL_API_V1}/r/${user.organization}/events/pii/field-groups/${groupId}/`
          )
          .then((response) => {
            setState((prev) => ({ ...prev, loading: false }));
            if (response.status === 200 || response.status === 204) {
              getPiiFieldGroups();
              notification.success({
                message: "Deleted",
                description: "PII Field Group deleted successfully.",
              });
            } else {
              setState((prev) => ({ ...prev, data: [] }));
              notification.error({
                message: "Error",
                description: "Failed to delete the PII Field Group.",
              });
            }
          })
          .catch((err) => {
            console.error("Delete Error:", err);
            setState((prev) => ({ ...prev, loading: false }));
            notification.error({
              message: "Error",
              description: "An error occurred while deleting.",
            });
          });
      },
    });
  };

  /**
   * Handle saving all changed active statuses and updating privacy_level.
   */
/**
 * Handle saving all changed active statuses and updating privacy_level.
 */
const handleSaveChanges = async () => {
  // If no changes, do nothing
  if (!privacyLevelChanged && Object.keys(changedGroups).length === 0) {
    notification.info({
      message: "No Changes",
      description: "There are no changes to save.",
    });
    return;
  }

  setSaving(true);

  try {
    // Determine new privacy level from slider
    const selectedPrivacyLevel = privacyLevelMap[sliderValue]; // e.g., "NONE", "LOW"
    const hasPrivacyLevelChanged = selectedPrivacyLevel !== currentPrivacyLevel;

    const patches: Promise<any>[] = [];

    // If privacy level has changed, patch the organization's privacy_level
    if (hasPrivacyLevelChanged) {
      console.log(`Updating privacy level to: ${selectedPrivacyLevel}`);
      patches.push(
        axiosApiInstance.patch(
          `${URL_API_V1}/r/${user.organization}/organizations/${organizaton?.uuid}`,
          { privacy_level: selectedPrivacyLevel }
        )
      );

      // Determine desired active identifiers based on the new privacy level
      const desiredActiveIdentifiers =
        privacyLevelActiveIdentifiers[selectedPrivacyLevel] || [];

      console.log(
        `Desired active identifiers for ${selectedPrivacyLevel}:`,
        desiredActiveIdentifiers
      );

      // Fetch all current PII field groups
      const allGroupRows = state.data;

      // Extract unique group IDs and their identifiers
      const uniqueGroupIds = Array.from(
        new Set(allGroupRows.map((row) => row.groupId))
      );

      uniqueGroupIds.forEach((groupId) => {
        // Find the first row of the group to get its identifier
        const groupRow = allGroupRows.find((row) => row.groupId === groupId);
        if (groupRow) {
          const shouldBeActive = desiredActiveIdentifiers.includes(
            groupRow.identifier
          );
          console.log(
            `Group ID: ${groupId}, Identifier: ${groupRow.identifier}, Should Be Active: ${shouldBeActive}`
          );

          patches.push(
            axiosApiInstance.put(
              `${URL_API_V1}/r/${user.organization}/events/pii/field-groups/${groupId}/`,
              { is_active: shouldBeActive }
            )
          );
        } else {
          console.warn(
            `No groupRow found for groupId: ${groupId}. Skipping update.`
          );
        }
      });
    }

    // Handle any manual changes to individual group active statuses in Custom mode
    if (selectedPrivacyLevel === "CUSTOM" && Object.keys(changedGroups).length > 0) {
      console.log("Handling manual changes in Custom mode:", changedGroups);
      const piiFieldGroupPatches = Object.entries(changedGroups).map(
        ([groupId, isActive]) => {
          console.log(`Updating Group ID: ${groupId}, is_active: ${isActive}`);
          return axiosApiInstance.put(
            `${URL_API_V1}/r/${user.organization}/events/pii/field-groups/${groupId}/`,
            { is_active: isActive }
          );
        }
      );
      patches.push(...piiFieldGroupPatches);
    }

    if (patches.length === 0) {
      notification.info({
        message: "No Updates Needed",
        description: "All PII Field Groups are already up-to-date.",
      });
      setSaving(false);
      return;
    }

    // Execute all patch requests concurrently
    await Promise.all(patches);

    notification.success({
      message: "Success",
      description: "All changes have been saved successfully.",
    });

    // Refresh data to reflect the latest changes
    await getPiiFieldGroups();
    await fetchOrganizationDetails(); // Updates the current privacy level

    // Reset the change tracking states
    setChangedGroups({});
    setPrivacyLevelChanged(false);
  } catch (error) {
    console.error("Error saving changes:", error);
    notification.error({
      message: "Error",
      description: "An error occurred while saving changes.",
    });
  } finally {
    setSaving(false);
  }
};


  /**
   * Function to transform API data into table rows.
   */
/**
 * Function to transform API data into table rows.
 */
const transformData = (apiData: any[]): IPiiFieldGroupRow[] => {
  const transformedData: IPiiFieldGroupRow[] = [];

  // First sort the API data to ensure consistent group ordering
  const sortedApiData = [...apiData].sort((a, b) => {
    // Sort by group name first
    const groupNameCompare = a.name.localeCompare(b.name);
    if (groupNameCompare !== 0) return groupNameCompare;
    
    // If group names are equal, sort by identifier
    return a.identifier.localeCompare(b.identifier);
  });

  sortedApiData.forEach((group: any) => {
    const {
      id: groupId,
      name: groupName,
      identifier,
      masking_technique_id: maskingTechniqueId,
      masking_technique_name: maskingTechniqueName,
      fields_names,
      normalized_fields,
      is_active = true,
    } = group;

    console.log(`Processing Group ID: ${groupId}, Identifier: ${identifier}`);

    // Sort fields_names array for consistent field ordering
    const sortedFields = [...(fields_names || [])].sort((a, b) => 
      a.name.localeCompare(b.name)
    );

    const totalPiiFields = sortedFields.length;

    sortedFields.forEach((field: any, index: number) => {
      // Sort normalizations for consistent ordering
      const normalizations = normalized_fields
        .filter((nf: any) => nf.old_field === field.name)
        .sort((a: any, b: any) => a.new_field.localeCompare(b.new_field));

      if (normalizations.length > 0) {
        normalizations.forEach((nf: any, normIndex: number) => {
          transformedData.push({
            key: `${groupId}-${field.id}-${nf.id}`,
            groupId,
            groupName,
            identifier,
            maskingTechniqueName,
            maskingTechniqueId,
            piiFieldId: field.id,
            piiFieldName: field.name,
            normalizedField: nf.new_field,
            rowSpan:
              index === 0 && normIndex === 0
                ? totalPiiFields * normalizations.length
                : 0,
            isFirstRow: index === 0 && normIndex === 0,
            isActive: is_active,
          });
        });
      } else {
        transformedData.push({
          key: `${groupId}-${field.id}`,
          groupId,
          groupName,
          identifier,
          maskingTechniqueName,
          maskingTechniqueId,
          piiFieldId: field.id,
          piiFieldName: field.name,
          normalizedField: field.name,
          rowSpan: index === 0 ? totalPiiFields : 0,
          isFirstRow: index === 0,
          isActive: is_active,
        });
      }
    });
  });

  // Final sort of transformed data for absolute consistency
  return transformedData.sort((a, b) => {
    // Sort by group name first
    const groupNameCompare = a.groupName.localeCompare(b.groupName);
    if (groupNameCompare !== 0) return groupNameCompare;
    
    // Then by PII field name
    const fieldNameCompare = a.piiFieldName.localeCompare(b.piiFieldName);
    if (fieldNameCompare !== 0) return fieldNameCompare;
    
    // Finally by normalized field
    return a.normalizedField.localeCompare(b.normalizedField);
  });
};


  /**
   * Memoize the columns to optimize performance and ensure they update when sliderValue changes
   */
  const columns: ColumnsType<IPiiFieldGroupRow> = useMemo(() => {
    // Define the base columns that always appear
    const baseColumns: ColumnsType<IPiiFieldGroupRow> = [
      {
        title: "Active",
        dataIndex: "active",
        key: "active",
        width: 120,
        render: (value: boolean, row: IPiiFieldGroupRow) => ({
          children: (
            <Switch
              checked={changedGroups[row.groupId] !== undefined ? changedGroups[row.groupId] : row.isActive}
              onChange={(checked) => onToggleActive(row.groupId, checked)}
              checkedChildren="ON"
              unCheckedChildren="OFF"
              disabled={sliderValue !== 4}
              style={{ width: 70, opacity: sliderValue !== 4 ? 0.5 : 1 }}
            />
          ),
          props: { rowSpan: row.rowSpan },
        }),
      },
      {
        title: "Name",
        dataIndex: "groupName",
        key: "groupName",
        render: (_: any, row: IPiiFieldGroupRow) => ({
          children: row.groupName,
          props: { rowSpan: row.rowSpan },
        }),
      },
      {
        title: "Masking Technique Name",
        dataIndex: "maskingTechniqueName",
        key: "maskingTechniqueName",
        render: (_: any, row: IPiiFieldGroupRow) => ({
          children: <Tag key={row.maskingTechniqueName}>{row.maskingTechniqueName}</Tag>,
          props: { rowSpan: row.rowSpan },
        }),
      },
      {
        title: "PII Field",
        dataIndex: "piiFieldName",
        key: "piiFieldName",
        render: (text: string) => <Tag>{text}</Tag>,
      },
      {
        title: "Normalized Field",
        dataIndex: "normalizedField",
        key: "normalizedField",
        render: (text: string) => <Tag>{text}</Tag>,
      },
    ];
  
    // Only add the Action column if sliderValue is 4 (Custom)
    if (sliderValue === 4) {
      baseColumns.push({
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (_: any, row: IPiiFieldGroupRow) =>
          row.isFirstRow ? (
            <Space size="middle">
              <EditOutlined
                style={{ color: "#1890ff", cursor: "pointer" }}
                onClick={() => setEditVisible(row)}
              />
              <DeleteOutlined
                style={{ color: "#ff4d4f", cursor: "pointer" }}
                onClick={() => deleteItem(row.groupId)}
              />
            </Space>
          ) : null,
      });
    }
    return baseColumns;
  }, [sliderValue, state.data, changedGroups]); // Dependencies include sliderValue, data, and changedGroups to re-render when they change

  /**
   * Function to fetch masking techniques.
   */
  function fetchMaskingTechniques() {
    axiosApiInstance
      .get(`${URL_API_V1}/r/${user.organization}/events/masking-techniques/`)
      .then((response) => {
        let results: IMaskingTechniques[] = [];
        if (response.status === 200) {
          results = response.data.entries || [];
        }
        setMaskingTechniques(results);
      })
      .catch((err) => {
        console.error("Error fetching masking techniques:", err);
      });
  }

  /**
   * Function to fetch all PII Fields.
   */
  function getAllFields() {
    if (!user.organization) {
      notification.error({
        message: "Organization Not Selected",
        description: "Please select an organization before fetching fields.",
        key: "organization-not-selected",
      });
      return;
    }

    axiosApiInstance
      .get(`${URL_API_V1}/r/${user.organization}/events/fields/`)
      .then((response) => {
        let results: any[] = [];
        if (response.status === 200) {
          results = response.data.entries || [];
        }
        const fields: Record<number, any> = {};
        results.forEach((field) => {
          fields[field.id] = field;
        });
        setAvailableFields(fields);
      })
      .catch((exception) => {
        console.error("Error fetching available fields:", exception);
      });
  }

  /**
   * Function to fetch PII Field Groups.
   */
  function getPiiFieldGroups() {
    setState((prev) => ({ ...prev, loading: true }));
    axiosApiInstance
      .get(`${URL_API_V1}/r/${user.organization}/events/pii/field-groups/`)
      .then((response) => {
        let results: any[] = [];
        if (response && response.status === 200) {
          results = response.data.entries || [];
        }

        const transformedData = transformData(results);

        setState((prev) => ({
          ...prev,
          loading: false,
          data: transformedData,
          hasData: transformedData.length > 0,
        }));
      })
      .catch((exception) => {
        console.error("Error fetching PII Field Groups:", exception);
        setState((prev) => ({
          ...prev,
          loading: false,
          data: [],
          hasData: false,
        }));
      });
  }

  /**
   * Function called when form submission finishes.
   */
  function onFinish() {
    getPiiFieldGroups();
  }

  /**
   * Function to get the Modal title based on action type.
   */
  const getTitle = () =>
    isAddOrEdit === ActionType.ADD
      ? "Add new PII Field Group"
      : "Edit PII Field Group";

  /**
   * Function to cancel the Modal.
   */
  const cancel = () => {
    form.resetFields();
    setHasErrors("");
    setVisible(false);
  };


  return (
    <div>
      {/* Tour Component */}
      <Tour open={openTour} onClose={() => setOpenTour(false)} steps={steps} />

      {/* Main Content */}
      <Row gutter={[24, 24]}>
        {/* Header Section */}
        <Col span={24}>
          <h3>Privacy</h3>
          <p>Manage protection of Personally Identifiable Information (PII)</p>
        </Col>

        {/* Form and Table Section */}
        <Col span={24}>
          <Form form={form} onFinish={onFinish}>
            <Row gutter={[16, 16]}>
              {/* The table plus Add button & slider */}
              <Col span={24}>
                {/* The Add button is placed on the top-right corner of the table wrapper */}
                <span>
                  {privacyLevelChanged ? (
                    <>
                      Change Privacy Level from{" "}
                      <b>
                        <span
                          
                          style={{ fontSize: "18px", marginLeft: "8px" }}
                        >
                          {privacyMarks[privacyLevelMapReverse[currentPrivacyLevel]]}
                        </span>
                      </b>{" "}
                      to{" "}
                      <b>
                        <span
                          style={{ fontSize: "18px", marginLeft: "8px" }}
                        >
                          {privacyMarks[sliderValue]}
                        </span>
                      </b>
                    </>
                  ) : (
                    <>
                      Adjust Privacy Level by moving the slider. Current value:{" "}
                      <b>
                        {/* <Tag
                          color="blue"
                          style={{ fontSize: "18px", marginLeft: "8px" }}
                        > */}
                          {privacyMarks[sliderValue]}
                        {/* </Tag> */}
                      </b>
                    </>
                  )}
<span style={{ paddingLeft: "25px", flexDirection: "row", alignItems: "center" }}>
                      {/* Save Changes Button */}
                      <Button
                        type="primary"
                        onClick={handleSaveChanges}
                        disabled={
                          (!privacyLevelChanged && Object.keys(changedGroups).length === 0) ||
                          saving
                        }
                        loading={saving}
                        style={{
                          width: "150px",
                          marginTop: "1em",
                          opacity:
                            (!privacyLevelChanged && Object.keys(changedGroups).length === 0) ||
                            saving
                              ? 0.5
                              : 1, // Reduce opacity when disabled
                          cursor:
                            (!privacyLevelChanged && Object.keys(changedGroups).length === 0) ||
                            saving
                              ? "not-allowed"
                              : "pointer", // Change cursor based on state
                        }}
                      >
                        Save Changes
                        {(privacyLevelChanged || Object.keys(changedGroups).length > 0) && (
                          <Tooltip title="You have unsaved changes">
                            <span style={{ marginLeft: "8px", color: "#faad14" }}>⚠️</span>
                          </Tooltip>
                        )}
                      </Button>
                      </span>
                </span>
                <Row wrap={false} gutter={[16, 16]}>
                  {/* Table Column */}
                  <Col flex="1 1">
                    {/* Slider Column */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {/* Slider and Description */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        {/* Slider Column */}
                        <Col flex="0 0 950%">
                          {/* Adjust the width as needed */}
                          <div
                            style={{
                              marginLeft: "35px",
                              marginTop: "55px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              height: "100px",
                            }}
                          >
                            {/* Slider and Description */}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                width: "100%",
                                paddingBottom:"10px"
                              }}
                            >
                              {/* Slider */}
                              
                              <Slider
                                marks={privacyMarks}
                                min={0}
                                max={4}
                                value={sliderValue}
                                tooltip={{
                                  open: false, // Disable default tooltip
                                }}
                                onChange={onChangePrivacyLevel}
                                style={{
                                  width: "100%",
                                }}
                              />

                              {/* Tradeoff Line with Labels */}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                  marginTop: "15px",
                                }}
                              >
                                {/* Left Label */}
                                <span
                                  style={{
                                    flex: "0 1 200px", // Can shrink but won't grow beyond 200px
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    color: "#555",
                                    minWidth: "150px", // Minimum width to prevent text wrapping too early
                                  }}
                                >
                                  Low Privacy, High Utility
                                </span>

                                {/* Blue Horizontal Line */}
                                <div
                                  style={{
                                    height: "2px",
                                    flex: "2 1 auto", // Takes up more space (2x) when available
                                    margin: "0 24px", // Slightly larger margins
                                    position: "relative",
                                    background:
                                      "linear-gradient(to right, rgba(24, 144, 255, 0) 0%, #1890ff 50%, rgba(24, 144, 255, 0) 100%)",
                                  }}
                                ></div>

                                {/* Right Label */}
                                <span
                                  style={{
                                    flex: "0 1 200px", // Can shrink but won't grow beyond 200px
                                    textAlign: "right",
                                    fontWeight: "bold",
                                    color: "#555",
                                    minWidth: "150px", // Minimum width to prevent text wrapping too early
                                  }}
                                >
                                  High Privacy, Low Utility
                                </span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </div>

                      {/* Description Text */}
                      <div
                        style={{
                          maxWidth: "280px",
                          height: "100px",
                          overflow: "hidden",
                          textAlign: "left",
                          fontWeight: "bold",
                          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)",
                          color: sliderValue === 0 ? "red" : "inherit", // Sets text color to red if no privacy is enabled
                        }}
                      >
                        {privacyDescriptions[sliderValue]}
                      </div>

                    </div>
                    {/* Vertical Divider */}
                    <Divider type="horizontal" />
                    {/* Spacer Between Buttons and Table */}
                    <div style={{}} />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        marginBottom: "1em",
                      }}
                    >
                      {/* Add Button */}
                      <Button
                        ref={addButtonRef}
                        type="primary"
                        onClick={setAddVisible}
                        disabled={sliderValue !== 4} // Disable Add button if not in Custom mode
                        title={
                          sliderValue !== 4
                            ? "Enable Custom privacy mode to add fields"
                            : "Add new PII Field Group"
                        }
                        style={{ marginBottom: "10px" }} // Spacer between Add and Save Changes buttons
                      >
                        Add
                      </Button>
                    </div>
                    {/* Table */}
                    <Table
                      rowKey="key"
                      columns={columns}
                      dataSource={state.data}
                      loading={state.loading}
                      pagination={false}
                      bordered
                      scroll={{ x: "max-content", y: 400 }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      {/* Add/Edit Modal */}
      <Modal
        title={getTitle()}
        centered
        open={visible}
        width={600}
        maskClosable={false}
        onOk={() => createOrUpdate(isAddOrEdit)}
        onCancel={cancel}
        footer={[
          <Button key="back" onClick={cancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => createOrUpdate(isAddOrEdit)}
          >
            Submit
          </Button>,
        ]}
        bodyStyle={{ paddingBottom: "40px" }}
      >
        <Form form={form} onFinish={onFinish}>
          <Row gutter={[24, 24]}>
            {/* Hidden ID Field */}
            <Col span={24}>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
            </Col>

            {/* Name Field */}
            <Col span={24}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  { required: true, message: "Please input the group name!" },
                ]}
              >
                <Input placeholder="PII Field Group Name" />
              </Form.Item>
            </Col>

            {/* Masking Technique Field */}
            <Col span={24}>
              <Form.Item
                label="Masking Technique"
                name="maskingTechnique"
                rules={[
                  {
                    required: true,
                    message: "Please select a masking technique!",
                  },
                ]}
              >
                <Select
                  placeholder="Select a masking technique"
                  disabled={
                    isAddOrEdit === ActionType.EDIT && sliderValue !== 4
                  } // Disable if not in Custom mode
                >
                  {maskingTechniques.map((maskingTechnique) => (
                    <Option
                      value={maskingTechnique.id}
                      key={maskingTechnique.value}
                    >
                      {maskingTechnique.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* PII Fields and Add Field Button */}
            <Col span={24}>
              <Row gutter={8} align="middle">
                <Col span={20}>
                  <Form.Item
                    label="PII Fields"
                    name="fields"
                    rules={[
                      {
                        required: true,
                        message: "Please select at least one PII field!",
                      },
                    ]}
                    style={{ marginBottom: 0 }}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="Select PII fields"
                      filterOption={(input, option) =>
                        option?.label
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={Object.keys(availableFields).map((key) => ({
                        value: parseInt(key, 10),
                        label: availableFields[key]["name"],
                      }))}
                      style={{ width: "100%" }}
                      disabled={sliderValue !== 4} // Disable if not in Custom mode
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Tooltip
                    title={
                      sliderValue === 4
                        ? "Add new field"
                        : "Enable Custom privacy mode to add fields"
                    }
                  >
                    <Button
                      type="primary"
                      onClick={() => setShowAddField(true)}
                      style={{ width: "100%" }}
                      disabled={sliderValue !== 4} // Disable if not in Custom mode
                    >
                      Add field
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* Error Alert */}
          {hasErrors && (
            <Alert
              type="error"
              message={hasErrors}
              banner
              style={{ marginTop: "16px" }}
            />
          )}
        </Form>
      </Modal>

      {/* Add Field Modal */}
      <ModalAddField
        isShowing={showAddField}
        hide={() => setShowAddField(false)}
        getAllFields={getAllFields}
      />
    </div>
  );
}
