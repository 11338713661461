import { SearchOutlined } from "@ant-design/icons";
import type { InputRef, TableColumnsType, TableColumnType } from "antd";
import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Input,
  Layout,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tooltip
} from "antd";
import type { FilterDropdownProps } from "antd/es/table/interface";

import Moment from "moment";
import { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { useRecoilState } from "recoil";
import { Device, DeviceModal } from "types/DeviceTypes";
import { styleModeAtom, userAtom } from "../../common/Atoms";
import AxiosApiInstance from "../../common/Interceptors";
import { URL_API_V1 } from "../../constants/global";
import {
  useEventsTableContext
} from "../../contexts/EventsTableContext";

import TimeRangeOptions from "components/TimeComponents/TimeRangeOptions";
import { useTimeRange } from "contexts/TimeRangeContext";
import { useDeviceCharts } from "contexts/DeviceChartsContext"
import { useFetchEvents } from "hooks/getEvents";
import { useDevice } from "contexts/DeviceContext";
import ScatterChartECharts from "./ScatterChartECharts";
import { DEFAULT_DATETIME_FORMAT } from "constants/user";

import "./CustomTooltip.css";
import { getDateWithTooltip } from "../../utils/utils"
import GlobalBandwidthChart from "./GlobalBandwidthChart";
import ConnectionsChart from "./ConnectionsChart";

const { Content, Footer } = Layout;
const { Panel } = Collapse;


interface DataPoint {
  x: number;
  y: number;
  src_ip: string;
  src_port: string;
  dst_ip: string;
  dst_port: string;
  field: string;
  table: number;
}

interface GroupedData {
  name: string;
  data: DataPoint[];
}

interface DeviceModalComponentProps {
  device: DeviceModal;
}

enum TrafficDirection {
  INBOUND = "inbound",
  OUTBOUND = "outbound",
}

interface OpenPortType {
  key: string;
  port: number;
  protocol: string;
  service: string;
  createdOn: {
    $date: string;
  };
  updatedOn: {
    $date: string;
  };
}

const DevicesModalComponent: React.FC<DeviceModalComponentProps> = ({
  device,
}) => {
  const { axiosApiInstance } = AxiosApiInstance();
  const [user] = useRecoilState(userAtom);
  const [form] = Form.useForm();
  const { showDeviceModal, setShowDeviceModal, selectedHost, setSelectedHost } = useDevice();

  const [selectedPort, setSelectedPort] = useState<number>(0);
  const [openPorts, setOpenPorts] = useState<number[]>([]);

  const { doUpdateChart } = useDeviceCharts();
  
  const [themeState, setThemeState] = useRecoilState(styleModeAtom);
  const isDarkMode = themeState.theme === "dark";

  const [openPortsOptions, setOpenPortsOptions] = useState([] as any);
  const [selectedOpenPorts, setSelectedOpenPorts] = useState([] as any);
  const [networkTrafficDirection, setNetworkTrafficDirection] =
    useState<TrafficDirection>(TrafficDirection.INBOUND);

  const {
    queryTerms,
    setQueryTerms,
    timeRangeType,
    setTimeRangeType,
    selectedAbsoluteDates,
    setSelectedAbsoluteDates,
    selectedRelativeTime,
    setSelectedRelativeTime,
  } = useTimeRange();

  const { calculateTimeRange, getSuggestionsForField } =
    useEventsTableContext();

  const { startTime: startTimeInital, endTime: endTimeInitial } =
    calculateTimeRange(
      timeRangeType,
      selectedRelativeTime,
      selectedAbsoluteDates
    );
  const [startTime, setStartTime] = useState(startTimeInital);
  const [endTime, setEndTime] = useState(endTimeInitial);

  const { fetchEvents } =
    useFetchEvents(queryTerms);

  const initialData: any[] = [];

  const [propsVulns, setPropsVulns] = useState({
    loading: true,
    hasData: false,
    data: initialData,
  });

  const [propsOpenPorts, setPropsOpenPorts] = useState({
    loading: true,
    hasData: false,
    data: initialData,
  });

  const [propsInboundOutbound, setPropsInboundOutbound] = useState({
    loading: true,
    hasData: false,
    data: initialData,
  });

  const [selectedChartType, setSelectedChartType] = useState<'bandwidth' | 'connections'>('bandwidth');

  function fetchVulnerabilitiesByDevice(values?) {
    setPropsVulns({ ...propsVulns, loading: true });
    let ipAddress = values["ipAddress"];


    const data = { params: { ipAddress } };
    setPropsVulns({ ...propsVulns, loading: false });
    // axiosApiInstance
    //   .get(`${URL_API_V1}/r/${user.organization}/vulnerabilities/`, data)
    //   .then((response) => {
    //     let data = [] as any;
    //     if (response.status === 200) {
    //       data = response.data;
    //       setPropsVulns({ ...propsVulns, data, loading: false });
    //     } else {
    //       setPropsVulns({ ...propsVulns, data, loading: false });
    //     }
    //   });
  }

  useEffect(() => {
    if (device) {
      if (device && device.ipAddress) {
        setSelectedHost(device.ipAddress);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  useEffect(() => {
    console.log("DeviceModalViewForm showDeviceModal: ", showDeviceModal)
    if (!showDeviceModal) {
      setSelectedHost(undefined);
      setSelectedPort(0);
    }    
    doUpdateChart();
  }, [showDeviceModal]);

  const deviceMetricsEndpoint = device.ipAddress
  ? `${URL_API_V1}/r/${user.organization}/metrics/devices/${device.ipAddress}`
  : '';

  useEffect(() => {
    if (showDeviceModal) {
      function fetchListeningPortsByDevice() {
        setPropsOpenPorts((prevProps) => ({ ...prevProps, loading: true }));
  
        if (typeof selectedHost === "string" && selectedHost.trim().length > 0) {
          axiosApiInstance
            .get(`${URL_API_V1}/r/${user.organization}/devices/${selectedHost}`)
            .then((response) => {
              if (response.status === 200 && response.data?.devices?.length > 0) {
                const device = response.data.devices[0]; // Adjusted to match API response structure
                const resultOpenPorts: OpenPortType[] = device.openPorts || [];
                const uniquePorts = Array.from(
                  new Set(resultOpenPorts.map((item) => item.port))
                );
  
                setOpenPorts(uniquePorts); // Set deduplicated ports
                setPropsOpenPorts({
                  ...propsOpenPorts,
                  data: resultOpenPorts,
                  loading: false,
                });
              } else {
                setPropsOpenPorts({ ...propsOpenPorts, loading: false });
              }
            })
            .catch((error) => {
              console.error("Error fetching listening ports:", error);
              setPropsOpenPorts({ ...propsOpenPorts, loading: false });
            });
        } else {
          setPropsOpenPorts({ ...propsOpenPorts, loading: false });
        }
      }
  
      fetchListeningPortsByDevice();
    }
  }, [showDeviceModal, selectedHost, user]);
  useEffect(() => {
    const options = openPorts.map((port) => ({
      value: port,
      label: port,
    }));
    setOpenPortsOptions(options);
  }, [openPorts]);

  useEffect(() => {
    setSelectedOpenPorts(openPorts);
  }, [openPorts]);

  const handleSelectedPorts = (selectedPorts) => {
    setSelectedOpenPorts(selectedPorts);
  };

  const handleClearAll = () => {
    setSelectedOpenPorts([]);
  };

  const handleSelectAll = () => {
    setSelectedOpenPorts(openPortsOptions.map((option) => option.value));
  };

  useEffect(() => {
    const fetchEventData = async () => {
      if (startTime && endTime) {
        const eventsOutput = await fetchEvents(
          startTime,
          endTime,
          0,
          10,
          queryTerms
        );
        setPropsInboundOutbound({
          ...propsInboundOutbound,
          data: eventsOutput.hits,
          loading: false,
        });
      }
    };

    fetchEventData();
  }, [queryTerms, startTime, endTime, fetchEvents]);

  useEffect(() => {
    if (selectedHost) {
      const getInboundTraffic = async () => {
        setPropsInboundOutbound({ ...propsInboundOutbound, loading: true });

        let newQueryTerms;
        if (networkTrafficDirection === TrafficDirection.INBOUND) {
          newQueryTerms = [
            {
              booleanOperator: "AND",
              field: "payload_dst_ip",
              comparisonOperator: "=",
              value: selectedHost,
            },
          ];

          if (selectedOpenPorts && selectedOpenPorts.length > 1) {
            const portTerms = selectedOpenPorts.map((port) => ({
              booleanOperator: "OR",
              field: "payload_dst_port",
              comparisonOperator: "=",
              value: port,
            }));
            newQueryTerms = [...newQueryTerms, ...portTerms];
          } else {
            newQueryTerms.push({
              booleanOperator: "AND",
              field: "payload_dst_port",
              comparisonOperator: "=",
              value: selectedOpenPorts.length > 0 ? selectedOpenPorts[0] : "",
            });
          }
        } else {
          newQueryTerms = [
            {
              field: "payload_src_ip",
              comparisonOperator: "=",
              value: selectedHost,
            },
          ];
        }

        const { startTime, endTime } = calculateTimeRange(
          timeRangeType,
          selectedRelativeTime,
          selectedAbsoluteDates
        );
        setStartTime(startTime);
        setEndTime(endTime);

        setQueryTerms(newQueryTerms);
      };

      getInboundTraffic();
    }
  }, [
    selectedHost,
    selectedOpenPorts,
    networkTrafficDirection,
    calculateTimeRange,
    timeRangeType,
    selectedRelativeTime,
    selectedAbsoluteDates,
  ]);

  useEffect(() => {
    setShowDeviceModal(showDeviceModal);
    if (showDeviceModal) {
      fetchVulnerabilitiesByDevice(selectedHost);
      setTimeout(() => {}, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHost, showDeviceModal]);

  const handleCancel = () => {
    form.resetFields();
    setShowDeviceModal(false);
  };

  const columnsVulns = [
    { title: "Host", dataIndex: "ipAddress" },
    { title: "Name", dataIndex: "name" },
    { title: "Plugin ID", dataIndex: "pluginId" },
    { title: "Port", dataIndex: "port" },
  ];

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  type DataOpenPortIndex = keyof OpenPortType;

  const getColumnSearchProps = (
    dataIndex: DataOpenPortIndex
  ): TableColumnType<OpenPortType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataOpenPortIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const filteredOpenPorts = propsOpenPorts.data.filter((item) =>
    item.port.toString().includes(searchText)
  );

  const columnsOpenPorts: TableColumnsType<OpenPortType> = [
    {
      title: "Port",
      key: "port",
      ...getColumnSearchProps("port"),
      sorter: (a, b) => a.port - b.port,
      dataIndex: "port",
    },
    { title: "Protocol", dataIndex: "protocol" },
    { title: "Service", dataIndex: "service" },
    {
      title: "First seen",
      dataIndex: "createdOn",
      sorter: (a, b) => a.createdOn.$date.length - b.createdOn.$date.length,
      render: (text) => {
        return getDateWithTooltip(text)
      },
    },
    {
      title: "Last seen",
      dataIndex: "updatedOn",
      sorter: (a, b) => a.createdOn.$date.length - b.createdOn.$date.length,
      render: (text) => {
        return getDateWithTooltip(text)
      },
    },
  ];

  const columnsInboundOutboundTraffic = [
    {
      title: "Date",
      dataIndex: "_timestamp",
      key: "id",
      render: (text, row) => {
        return Moment(row._timestamp / 1000).format(DEFAULT_DATETIME_FORMAT);
      },
      sorter: (a, b) =>
        new Date(a.inserted_at).getTime() - new Date(b.inserted_at).getTime(),
    },
    {
      title: "Origin IP address",
      dataIndex: "payload_src_ip",
      key: "id",
      render: (text, row) => {
        return <Button type="link">{row["payload_src_ip"]}</Button>;
      },
    },
    {
      title: "Origin port",
      dataIndex: "payload_port",
      render: (text, row) => {
        return row["payload_src_port"];
      },
    },
    {
      title: "Resp IP address",
      dataIndex: "payload_dst_ip",
      key: "id",
      render: (text, row) => {
        return <Button type="link">{row["payload_dst_ip"]}</Button>;
      },
    },
    {
      title: "Resp port",
      dataIndex: "payload_port",
      render: (text, row) => {
        return row["payload_dst_port"];
      },
    },
    {
      title: "Protocol",
      dataIndex: "payload.protocol",
      render: (text, row) => {
        return "payload_proto" in row ? row["payload_proto"] : " ";
      },
    },
    {
      title: "Conn. state",
      dataIndex: "payload.conn_state",
      render: (text, row) => {
        return "payload_conn_state" in row ? row["payload_conn_state"] : "";
      },
    },
    {
      title: "Resp bytes",
      dataIndex: "payload_resp_bytes",
      render: (text, row) => {
        return "payload_resp_bytes" in row ? row["payload_resp_bytes"] : "";
      },
    },
  ];

  const rowClassName = (record) => {
    return record.port === selectedPort ? 'selected-row' : '';
  };

  const vulnerabilitiesView = (
    <Table
      loading={propsVulns.loading}
      style={{ fontSize: "small" }}
      rowKey="_id"
      columns={columnsVulns}
      dataSource={propsVulns.data}
    />
  );

  const listeningPorts = (
    <>
      <Content>
        <Table
          loading={propsOpenPorts.loading}
          style={{ fontSize: "small" }}
          rowKey={(obj) => obj.port + "_" + obj.protocol + "_" + obj.service}
          columns={columnsOpenPorts}
          dataSource={filteredOpenPorts}
          onRow={(record) => {
            return {
              onClick: () => {
                setSelectedPort(record.port);
              },
            };
          }}
          rowClassName={rowClassName}
        />
      </Content>
      <Content
        style={{ padding: "5px", margin: "5px", textAlign: "right" }}
      ></Content>
    </>
  );

  const openPortSelectOption = (
    <div style={{ marginTop: "5px" }}>
      <span style={{ paddingRight: "15px" }}>Open ports</span>
      <Select
        style={{ minWidth: "200px" }}
        mode="multiple"
        placeholder="Please select"
        value={selectedOpenPorts}
        onChange={handleSelectedPorts}
        options={openPortsOptions}
      />
      <Button style={{ marginLeft: "8px" }} onClick={handleClearAll}>
        Clear All
      </Button>
      <Button style={{ marginLeft: "8px" }} onClick={handleSelectAll}>
        Select All
      </Button>
    </div>
  );

  const lastActivityView = (
    <>
      {" "}
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ paddingRight: "15px" }}>Time</div>
        <TimeRangeOptions
          timeRangeType={timeRangeType}
          setTimeRangeType={setTimeRangeType}
          setSelectedAbsoluteDates={setSelectedAbsoluteDates}
          selectedAbsoluteDates={selectedAbsoluteDates}
          selectedRelativeTime={selectedRelativeTime}
          setSelectedRelativeTime={setSelectedRelativeTime}
          queryTerms={queryTerms}
          setQueryTerms={setQueryTerms}
        />
      </div>
      <div style={{ paddingRight: "5px" }}>
        <span style={{ paddingRight: "15px" }}>Inbound/Outbound</span>
        <Radio.Group
          value={networkTrafficDirection}
          onChange={(value) => setNetworkTrafficDirection(value.target.value)}
        >
          <Radio.Button value={TrafficDirection.INBOUND}>Inbound</Radio.Button>
          <Radio.Button value={TrafficDirection.OUTBOUND}>
            Outbound
          </Radio.Button>
        </Radio.Group>
      </div>
      {networkTrafficDirection === TrafficDirection.INBOUND &&
        openPortSelectOption}
      <div style={{ margin: "8px" }} />
      <Content>
        <Table
          loading={propsInboundOutbound.loading}
          style={{ fontSize: "small" }}
          rowKey={(obj) => obj.id}
          columns={columnsInboundOutboundTraffic}
          dataSource={propsInboundOutbound.data}
          pagination={{ pageSize: 10 }}
        />
      </Content>
      <Content
        style={{ padding: "5px", margin: "5px", textAlign: "right" }}
      ></Content>
    </>
  );
  

  return showDeviceModal ? (
    <div>
      <Modal
        title="Device information"
        centered={true}
        closable={true}
        maskClosable={true}
        destroyOnClose={true}
        open={true}
        width="75em"
        onOk={form.submit}
        onCancel={handleCancel}
        footer={[
          <Button style={{ margin: "5px" }} key="cancel" onClick={handleCancel}>
            Close
          </Button>,
        ]}
      >
        <div>
          <Content>
            <Row>
              <Col span={6}>
                <Card>
                  <div>IP Address: {device.ipAddress}</div>
                  <div>
                    Created On:{" "}
                    {Moment(device.createdAt).format(DEFAULT_DATETIME_FORMAT)}
                  </div>
                  <div>
                    Updated On:{" "}
                    {Moment(device.updatedAt).format(DEFAULT_DATETIME_FORMAT)}
                  </div>
                </Card>
              </Col>
              <Col span={18}>
                <Card style={{ marginBottom: "20px", marginLeft: "10px" }}>
                  <Space style={{ marginBottom: 16 }}>
                    <Button
                      type={selectedChartType === 'bandwidth' ? 'primary' : 'default'}
                      onClick={() => setSelectedChartType('bandwidth')}
                    >
                      Bandwidth
                    </Button>
                    <Button
                      type={selectedChartType === 'connections' ? 'primary' : 'default'}
                      onClick={() => setSelectedChartType('connections')}
                    >
                      Number of Connections
                    </Button>
                  </Space>

                  {selectedChartType === 'bandwidth' ? (
                    <GlobalBandwidthChart deviceIp={selectedHost  || ''} />
                  ) : (
                    <ConnectionsChart 
                      deviceIp={selectedHost || ''} 
                    />
                  )}
                </Card>

              </Col>
            </Row>

            <Tabs
              defaultActiveKey="1"
              items={[
                {
                  label: `Listening ports`,
                  key: "openPorts",
                  children: listeningPorts,
                },
                {
                  label: `Vulnerabilities`,
                  key: "vulns",
                  children: vulnerabilitiesView,
                },
                {
                  label: (
                    <Tooltip title="Incoming and outgoing connections">
                      Inbound/Outbound traffic
                    </Tooltip>
                  ),
                  key: "activity",
                  children: lastActivityView,
                },
              ]}
            />
          </Content>
        </div>
      </Modal>
    </div>
  ) : null;
};
export default DevicesModalComponent;
