import React, { useState, useRef, useEffect } from 'react';
import { DEFAULT_DATETIME_FORMAT, TIMEZONE } from 'constants/user';
import * as echarts from 'echarts';
import moment from 'moment';

type HistogramItemEpoch = {
  date: number; // Assuming date is a number (epoch time)
  value: number; // Adjust the type as needed
};

type HistogramData = {
  date: number;
  value: number;
};

type ChartDataItem = {
  value: [number, number]; // [timestamp, value]
  series: string;
};

export const PAGE_SIZE = 10;
export const CACHE_SIZE = 100;

const HistogramV3 = ({ fetchEvents, setTableData, startTime, endTime, setStartTime, setEndTime, currentData, queryTerms }) => {

  const [aggsHistoryData, setAggsHistoryData] = useState<HistogramData[]>([]);
  const [histogramData, setHistogramData] = useState<HistogramItemEpoch[]>([]);
  // const echartsRef = useRef<ReactECharts>(null);
  const chartRef = useRef<HTMLDivElement>(null);
  const [chartData, setChartData] = useState<ChartDataItem[]>([]);
  const determineHistogramInterval = (startTime: number, endTime: number): string => {
    const duration = endTime - startTime; // Duration in milliseconds
    const durationMinutes = duration / (60 * 1000); // Convert to minutes correctly

    if (durationMinutes <= 30) {
      // Up to 1 hour
      return '10 second';
    } else if (durationMinutes <= 1 * 60) {
      // Up to 3 hours
      return '30 second';
    } else if (durationMinutes <= 6 * 60) {
      // Up to 6 hours
      return '1 minute';
    } else if (durationMinutes <= 12 * 60) {
      // Up to 12 hours
      return '5 minute';
    } else if (durationMinutes <= 24 * 60) {
      // Up to 24 hours
      return '10 minute';
    } else {
      // More than 24 hours
      return '1 hour';
    }
  };

  //console.log('HistogramV3: currentData:', currentData);

  useEffect(() => {
    setAggsHistoryData(currentData);
  }, [currentData]);

  useEffect(() => {
    console.log('Histogram startTime:', startTime);
    console.log('Histogram endTime:', endTime);
  }, [startTime, endTime]);

  const convertIntervalToMilliseconds = (interval: string): number => {
    const [count, unit] = interval.split(' ');
    const unitToMs = {
      second: 1000,
      minute: 60 * 1000,
      hour: 60 * 60 * 1000,
    };
    return parseInt(count, 10) * (unitToMs[unit] ?? 0);
  };

  const roundToNearestInterval = (time: number, intervalString: string): number => {
    const intervalMs = convertIntervalToMilliseconds(intervalString);
    return Math.floor(time / intervalMs) * intervalMs; // Use floor to round down to the nearest interval
  };

  const generateHistogramData = (startTime: number, endTime: number, existingData: HistogramData[]): HistogramData[] => {
    const intervalString = determineHistogramInterval(startTime, endTime);
    const intervalMs = convertIntervalToMilliseconds(intervalString);

    startTime = roundToNearestInterval(startTime, intervalString);
    endTime = roundToNearestInterval(endTime, intervalString) + intervalMs;

    let currentTime = startTime;
    const newTable: HistogramData[] = [];
    while (startTime > 0 && currentTime <= endTime) {
      newTable.push({ date: currentTime, value: 0 }); // Keep date as epoch time for sorting
      currentTime += intervalMs;
    }

    // Merge logic
    const mergedData = newTable.map((tableItem) => {
      const existingItem = existingData.find((dataItem) => dataItem.date === tableItem.date);
      return existingItem || tableItem;
    });

    return mergedData;
  };

  useEffect(() => {
    //console.log('histogram data:', data);
  }, [aggsHistoryData]);

  useEffect(() => {

    let start = moment(startTime / 1000)
      .tz(TIMEZONE)
      .format();
    let end = moment(endTime / 1000)
      .tz(TIMEZONE)
      .format();
    const processedData = aggsHistoryData.map((item: any) => ({
      ...item,
      date: moment.utc(item.timestamp).valueOf(), // Convert to epoch time in milliseconds
      value: item.value,
    }));

    let mergedData = generateHistogramData(startTime / 1000, endTime / 1000, processedData);
    setHistogramData([...mergedData]);

    //console.log('histogramData:', histogramData);
  }, [aggsHistoryData]);

  const formatter = (params: any[]) => {
    // console.log("params:", params)
    let result = params.length > 0 ? `${params[0].name}<br/>` : '';

    params.forEach((param) => {
      if (param.value !== 0) {
        result += `${param.marker} ${param.seriesName}: ${param.value}<br/>`;
      }
    });

    return result;
  };

  const formattedXAxisData = histogramData.map((item) => moment(item.date).tz(TIMEZONE).format(DEFAULT_DATETIME_FORMAT));

  useEffect(() => {
    if (!chartRef.current) return;
    const myChart = echarts.init(chartRef.current);

    const options = {
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'cross', label: { backgroundColor: '#6a7985' } },
      },
      brush: { type: 'lineX', xAxisIndex: 0 },
      toolbox: {
        feature: {
          brush: {
            type: ['lineX'],
            title: { lineX: 'Horizontal Selection' },
          },
        },
        right: 10,
      },
      formatter,
      xAxis: {
        type: 'category',
        data: histogramData.length > 0 ? formattedXAxisData : {},
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: false,
        },
      },
      series: [
        {
          name: 'Value',
          type: 'bar',
          data: histogramData.map((item) => item.value),
        },
      ],
    };

    myChart.setOption(options);
    myChart.dispatchAction({
      type: 'takeGlobalCursor',
      key: 'brush',
      brushOption: {
        brushType: 'lineX',
        brushMode: 'single',
      },
    });

    myChart.on('brushEnd', (params: any) => {
      (async () => {
        if (!params || !params.areas || params.areas.length === 0) {
          console.log('No selection made');
          return;
        }

        const area = params.areas[0];
        const range = area.coordRange;
        const filteredData = histogramData.filter((_, index) => index >= range[0] && index <= range[1]);

        if (filteredData.length === 0) {
          console.log('No data within the selected range.');
          return;
        }

        const startIndex = filteredData[0];
        const endIndex = filteredData[filteredData.length - 1];

        const dateFrom = moment(startIndex.date).tz(TIMEZONE).format();
        const dateTo = moment(endIndex.date).tz(TIMEZONE).format();

        const epochFrom = moment(dateFrom).valueOf() * 1000;
        const epochTo = moment(dateTo).valueOf() * 1000;

        setStartTime(epochFrom);
        setEndTime(epochTo);

        console.log(`Date range: ${dateFrom} - ${dateTo}`);
        console.log(`Epoch range: ${epochFrom} - ${epochTo}`);

        try {
          console.log('Histogram brush selection - using queryTerms:', JSON.stringify(queryTerms, null, 2));
          
          const res = await fetchEvents(
            epochFrom,
            epochTo,
            0,
            PAGE_SIZE,
            queryTerms
          );
          
          console.log('Histogram brush selection - API response:', JSON.stringify(res, null, 2));
          if (res && res.hits) {
            setTableData(res.hits);
          }
        } catch (error) {
          console.error('Error fetching events during brush selection:', error);
        }
      })();
    });

    const handleResize = () => myChart.resize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      myChart.dispose();
    };
  }, [histogramData]);

  return (
    <>
      <div ref={chartRef} style={{ height: '250px', width: '100%' }}></div>
    </>
  );
};

export default HistogramV3;
