import {
  Button,
  Card,
  Col,
  notification,
  Row,
  Table,
  Tooltip,
  Dropdown,
  Menu,
  Popconfirm,
  Tag,
} from "antd";
import { DeleteOutlined, DownloadOutlined, DownOutlined, StopOutlined } from "@ant-design/icons";
import React, { useEffect, useMemo, useState, useContext } from "react";
import { useRecoilState } from "recoil";
import { userAtom } from "../../common/Atoms";
import { URL_API_V1 } from "../../constants/global";
import AxiosApiInstance from "../../common/Interceptors";
import ToggleableKey from "common/ToggleableKey";
import { SetupWizardContext } from "components/SetupWizard/SetupWizardContext";
import { getDateWithTooltip } from "utils/utils";

interface APIKeysProps {
  // Optional props for standalone usage
  onAPIKeysUpdate?: (
    apiAccessKey: string,
    apiSecretKey: string,
    organizationKey: string
  ) => void;
  securityKeys?: {
    apiAccessKey: string;
    apiSecretKey: string;
    organizationKey: string;
  };
}

interface APIKey {
  uuid: string;
  access_key: string;
  secret_key: string | null;
  created_at: string;
  last_used_time: string | null;
  is_active: boolean;
  deactivated_at: string | null;
}

interface PropsConfig {
  loading: boolean;
  data: APIKey[];
}

// New interface that combines public APIKey data with sensitive keys.
// Guaranteeing that secret_key is a non-null string.
interface CombinedAPIKey extends APIKey {
  access_key: string;
  secret_key: string;
}

const APIKeys: React.FC<APIKeysProps> = ({
  onAPIKeysUpdate,
  securityKeys: propsSecurityKeys,
}) => {
  const [user] = useRecoilState(userAtom);
  const { axiosApiInstance } = AxiosApiInstance();
  const [loading, setLoading] = useState(true);
  const [organizationKey, setOrganizationKey] = useState("");

  const [visibleKeys, setVisibleKeys] = useState<{ [key: string]: boolean }>({});
  const [propsConfigs, setPropsConfigs] = useState<PropsConfig>({
    loading: true,
    data: [],
  });

  const context = useContext(SetupWizardContext);
  const { setSecurityKeys } = context || { setSecurityKeys: () => {} };

  // Determine if context is available
  const isUsingContext = Boolean(context);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  // New mapping: record UUID -> generated (sensitive) keys.
  // This might hold values for a newly created API key.
  const [generatedApiKeysMap, setGeneratedApiKeysMap] = useState<
    Record<string, { access_key: string; secret_key: string }>
  >({});

  // Create combined data that merges public data with sensitive keys.
  // For secret_key, if the mapping does not have a value, fallback to the public secret_key or an empty string.
  const combinedData = useMemo<CombinedAPIKey[]>(() => {
    return propsConfigs.data.map((item) => ({
      ...item,
      access_key: generatedApiKeysMap[item.uuid]?.access_key ?? item.access_key,
      secret_key: generatedApiKeysMap[item.uuid]?.secret_key ?? (item.secret_key || ""),
    }));
  }, [propsConfigs.data, generatedApiKeysMap]);

  // Clear context if no active API key is found.
  useEffect(() => {
    if (isUsingContext && setSecurityKeys) {
      const hasActive = propsConfigs.data.some((key) => key.is_active);
      if (!hasActive) {
        setSecurityKeys((prev) => ({
          ...prev,
          apiAccessKey: "",
          apiSecretKey: "",
        }));
      }
    }
  }, [propsConfigs.data, isUsingContext, setSecurityKeys]);

  // Row selection handler – using combinedData for consistency.
  const onRowSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: APIKey[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    if (context && selectedRows && selectedRows.length > 0) {
      const selectedKey = selectedRows[0];
      // Look up the combined record by uuid.
      const combined = combinedData.find((item) => item.uuid === selectedKey.uuid);
      console.log("onRowSelectChange APIKeys: Selected API Key:", combined);
      if (combined) {
        context.setSecurityKeys((prevKeys) => ({
          ...prevKeys,
          apiAccessKey: combined.access_key,
          apiSecretKey: combined.secret_key,
        }));
      } else {
        // If not found, clear the keys.
        context.setSecurityKeys((prevKeys) => ({
          ...prevKeys,
          apiAccessKey: "",
          apiSecretKey: "",
        }));
      }
    }
  };


    // Row selection handler – using combinedData for consistency.
    const onSelectChange = (newSelectedKeys: React.Key[], selectedRows: APIKey[]) => {
      console.log("setSelectedRowKeys: ", newSelectedKeys);
      setSelectedRowKeys(newSelectedKeys);
      if (isUsingContext && setSecurityKeys) {
        const activeRows = selectedRows.filter((row) => row.is_active);
        console.log("Active rows: ", activeRows);
        if (activeRows.length > 0) {
          const selectedKey = activeRows[0];
          console.log("selectedKey: ", selectedKey);
          console.log("combinedData:", combinedData);
          const combined = combinedData.find((item) => item.uuid === selectedKey.uuid);
          console.log("combined:", combined);
          if (combined && combined.access_key && combined.secret_key) {
            setSecurityKeys((prev) => ({
              ...prev,
              apiAccessKey: combined.access_key,
              apiSecretKey: combined.secret_key,
            }));
            console.log("Keys updated from combined data");
          } else {
            console.log("No sensitive keys for selected row");
            setSecurityKeys((prev) => ({
              ...prev,
              apiAccessKey: "",
              apiSecretKey: "",
            }));
          }
        } else {
          console.log("No active rows selected");
          clearEncryptionSelection();
        }
      }
    };


  const rowSelection = {
    type: "radio" as const,
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: APIKey) => ({
      disabled: !record.is_active,
      title: !record.is_active
        ? "This key is inactive. Activate it before selection."
        : undefined,
    }),
  };

  // Add a useEffect so that whenever combinedData or selectedRowKeys changes,
  // we update the context if a row is selected.
  useEffect(() => {
    if (selectedRowKeys.length > 0 && isUsingContext && setSecurityKeys) {
      const selectedUuid = selectedRowKeys[0];
      console.log("combinedData: ", combinedData);
      console.log("Updating context with selected UUID:", selectedUuid);
      const combined = combinedData.find((item) => item.uuid === selectedUuid);
      console.log("Updating context from combinedData:", combined);
      if (combined) {
        setSecurityKeys((prev) => ({
          ...prev,
          apiAccessKey: combined.access_key,
          apiSecretKey: combined.secret_key,
        }));
      }
    }
  }, [combinedData, selectedRowKeys, isUsingContext, setSecurityKeys]);

  // Function to fetch API keys
  const getApiKeys = async () => {
    setLoading(true);
    try {
      const response = await axiosApiInstance.get(
        `${URL_API_V1}/r/${user.organization}/security/api-keys`
      );
      console.log("APIKeys: API keys response:", response.data);
      if (response.status === 200) {
        const apiKeys: APIKey[] = response.data.results || [];
        setPropsConfigs({ loading: false, data: apiKeys });

        // Extract active API key
        const activeKey = apiKeys.find((key) => key.is_active);
        console.log("APIKeys: Active key:", activeKey);

        if (activeKey) {
          if (isUsingContext && context) {
            console.log("APIKeys: Called onAPIKeysUpdate with:", {
              apiAccessKey: activeKey.access_key,
              apiSecretKey: activeKey.secret_key || "",
              organizationKey,
            });
            onRowSelectChange([activeKey.uuid], [activeKey]);
          } else if (onAPIKeysUpdate) {
            onAPIKeysUpdate(
              activeKey.access_key,
              activeKey.secret_key || "",
              organizationKey
            );
            console.log("APIKeys: Called onAPIKeysUpdate with active API key:", {
              apiAccessKey: activeKey.access_key,
              apiSecretKey: activeKey.secret_key || "",
              organizationKey,
            });
          }
        } else {
          console.warn("APIKeys: No active API key found");
        }
      } else {
        console.warn(`APIKeys: Unexpected response status: ${response.status}`);
        setPropsConfigs({ loading: false, data: [] });
      }
    } catch (error) {
      console.error("APIKeys: Error fetching API Keys:", error);
      notification.error({
        message: "Error",
        description: "Failed to fetch API Keys.",
      });
      setPropsConfigs({ loading: false, data: [] });
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch Organization Key
  const getOrgKey = async () => {
    try {
      const response = await axiosApiInstance.get(`${URL_API_V1}/organizations/`);
      console.log("APIKeys: Organizations response:", response.data);
      if (response.status === 200) {
        const organizations = response.data.results || [];
        const organization = organizations.find(
          (org: any) => org.slug === user.organization
        );
        if (organization) {
          const uuid = organization.uuid;
          const orgDetailResponse = await axiosApiInstance.get(
            `${URL_API_V1}/r/${user.organization}/organizations/${uuid}/`
          );
          console.log("APIKeys: Organization detail response:", orgDetailResponse.data);
          if (orgDetailResponse.status === 200) {
            const orgData = orgDetailResponse.data;
            const orgKey = orgData.client_key || "";
            setOrganizationKey(orgKey);
            console.log("APIKeys: Organization Key:", orgKey);

            if (isUsingContext && context) {
              context.setSecurityKeys((prevKeys) => ({
                ...prevKeys,
                organizationKey: orgKey,
              }));
              console.log("APIKeys: Updated context with organization key:", orgKey);
            } else if (onAPIKeysUpdate) {
              onAPIKeysUpdate(
                propsSecurityKeys?.apiAccessKey || "",
                propsSecurityKeys?.apiSecretKey || "",
                orgKey
              );
              console.log("APIKeys: Called onAPIKeysUpdate with organization key:", orgKey);
            }
          }
        } else {
          throw new Error("Organization not found");
        }
      } else {
        throw new Error(`Failed to fetch organizations, status: ${response.status}`);
      }
    } catch (error) {
      console.error("APIKeys: Error fetching Organization Key:", error);
      notification.error({
        message: "Error",
        description: "Failed to fetch Organization Key.",
      });
    }
  };

  // Function to create a new API key
  const createApiKey = async () => {
    try {
      const response = await axiosApiInstance.post(
        `${URL_API_V1}/r/${user.organization}/security/api-keys/`
      );
      console.log("APIKeys: Create API key response:", response.data);
      if (response.status === 201) {
        const newKey: APIKey = response.data;
        notification.success({
          message: "Success",
          description: "API Key created successfully.",
        });
        setPropsConfigs((prevState) => ({
          loading: false,
          data: [newKey, ...prevState.data],
        }));
        // Update the mapping with the new key – ensure secret_key is not null.
        setGeneratedApiKeysMap((prev) => ({
          ...prev,
          [newKey.uuid]: {
            access_key: newKey.access_key,
            secret_key: newKey.secret_key || "",
          },
        }));
        if (isUsingContext && context) {
          console.log("New Key: ", newKey);
          // context.setSecurityKeys((prevKeys) => ({
          //   ...prevKeys,
          //   apiAccessKey: newKey.access_key,
          //   apiSecretKey: newKey.secret_key || "",
          // }));
          console.log("APIKeys: Updated context with new API keys:", {
            apiAccessKey: newKey.access_key,
            apiSecretKey: newKey.secret_key || "",
          });
          console.log("selectedRowKeys: ", selectedRowKeys);
          if (selectedRowKeys.length === 0) {
            setSelectedRowKeys([newKey.uuid]);
          }
          
        } else if (onAPIKeysUpdate) {
          onAPIKeysUpdate(newKey.access_key, newKey.secret_key || "", organizationKey);
          console.log("APIKeys: Called onAPIKeysUpdate with new API keys:", {
            apiAccessKey: newKey.access_key,
            apiSecretKey: newKey.secret_key || "",
            organizationKey,
          });
        }
      }
    } catch (error) {
      console.error("APIKeys: Error creating API Key:", error);
      notification.error({
        message: "Error",
        description: "Failed to create API Key.",
      });
    }
  };

  // Function to deactivate an API key
  const deactivateApiKey = async (uuid: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${URL_API_V1}/r/${user.organization}/security/api-keys/${uuid}/deactivate/`
      );
      console.log(`APIKeys: Deactivate API key ${uuid} response:`, response.data);
      if (response.status === 200) {
        notification.success({
          message: "Success",
          description: "API Key deactivated successfully.",
        });
        if(selectedRowKeys.includes(uuid)) {
          setSelectedRowKeys([]);
        }
        getApiKeys();
      } else {
        console.warn(`APIKeys: Unexpected response status during deactivation: ${response.status}`);
        notification.error({
          message: "Error",
          description: "Failed to deactivate API Key.",
        });
      }
    } catch (error) {
      console.error(`APIKeys: Error deactivating API Key ${uuid}:`, error);
      notification.error({
        message: "Error",
        description: "Failed to deactivate API Key.",
      });
    }
  };

  // Function to delete an API key
// Function to delete an API key
const deleteApiKey = async (uuid: string) => {
    try {
      const response = await axiosApiInstance.delete(
        `${URL_API_V1}/r/${user.organization}/security/api-keys/${uuid}/`
      );
      console.log(`APIKeys: Delete API key ${uuid} response:`, response.data);
      
      // DELETE typically returns a 204 No Content status
      if (response.status === 204) {
        notification.success({
          message: "Success",
          description: "API Key deleted successfully.",
        });
        // Optionally clear the selection if the deleted key was selected
        if (selectedRowKeys.includes(uuid)) {
          setSelectedRowKeys([]);
        }
        // Refresh the API keys list
        getApiKeys();
      } else {
        console.warn(`APIKeys: Unexpected response status during deletion: ${response.status}`);
        notification.error({
          message: "Error",
          description: "Failed to delete API Key.",
        });
      }
    } catch (error) {
      console.error(`APIKeys: Error deleting API Key ${uuid}:`, error);
      notification.error({
        message: "Error",
        description: "Failed to delete API Key.",
      });
    }
  };


  useEffect(() => {
    const fetchAllKeys = async () => {
      await getOrgKey();
      await getApiKeys();
    };
    fetchAllKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Columns for the API Keys table
  const columns = [
    {
      title: "API Access Key",
      dataIndex: "access_key",
      key: "access_key",
      render: (value: string) => <ToggleableKey keyValue={value} />,
    },
    {
      title: "API Secret Key",
      dataIndex: "secret_key",
      key: "secret_key",
      render: (value: string | null) => (value ? value : "***"),
    },
    {
      title: "Created On",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: string) => getDateWithTooltip(text),
    },
    {
      title: "Last Time Used",
      dataIndex: "last_used_time",
      key: "last_used_time",
      render: (text: string | null) => getDateWithTooltip(text),
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      key: "is_active",
      render: (is_active: boolean) => (is_active ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: APIKey) => {
        const menu = (
          <Menu>
            {record.is_active ? (
              <Menu.Item key="deactivate">
                <Tooltip title="Deactivate the API Key" placement="right">
                  <Button
                    type="text"
                    size="small"
                    icon={<StopOutlined />}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation();
                      deactivateApiKey(record.uuid);
                    }}
                  >
                    Deactivate
                  </Button>
                </Tooltip>
              </Menu.Item>
            ) : null}
            {record.deactivated_at ? (
              <Menu.Item key="delete">
                <Tooltip title="Delete the API Key" placement="right">
                  <Button
                    type="text"
                    size="small"
                    icon={<DeleteOutlined />}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation();
                      console.log("Download key:", record);
                      deleteApiKey(record.uuid);
                    }}
                  >
                    Delete
                  </Button>
                </Tooltip>
              </Menu.Item>
            ) : null}
          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button size="small" onClick={(e) => e.stopPropagation()}>
              Actions <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const clearEncryptionSelection = () => {
    setSecurityKeys?.((prev) => ({
      ...prev,
      apiAccessKey: "",
      apiSecretKey: "",
    }));
    setSelectedRowKeys([]);
  };


  const onRowClick = (record: APIKey) => {
    console.log("active record: ", record);
    if (isUsingContext) {
      updateSecurityKeysFromRecord(record);
    } else {
      clearEncryptionSelection();
    }
  };

  const updateSecurityKeysFromRecord = (record: APIKey) => {
    console.log("is_active2 log: ", record.is_active);
    if (record.is_active) {
      const combined = combinedData.find((item) => item.uuid === record.uuid);
      if (combined && combined.access_key) {
        setSecurityKeys?.((prev) => ({
          ...prev,
          apiAccessKey: combined.access_key,
          apiSecretKey: combined.secret_key,
        }));
      } else {
        clearEncryptionSelection();
      }
    } else {
      clearEncryptionSelection();
    }
  };

  return (
    <Card>
      <Row>
        <Col>
          <h3>API Keys</h3>
        </Col>
      </Row>
      <Row>
        <Card
          title={"Organization Key"}
          style={{ width: "100%", marginBottom: 16 }}
        >
          <ToggleableKey keyValue={organizationKey} />
        </Card>
      </Row>
      <Row
        style={{
          marginBottom: "8px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Col span={12}></Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Button type="primary" onClick={createApiKey}>
            Add API Key
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            dataSource={combinedData} // Use combined data for consistency
            columns={columns}
            rowKey="uuid"
            loading={propsConfigs.loading}
            bordered
            rowSelection={rowSelection}
            onRow={(record) => ({
              onClick: () => {
                // Always update keys on row click and trigger row selection update.
                onRowClick(record);
                onSelectChange([record.uuid], [record]);
              },
              style: {
                cursor: isUsingContext && record.is_active ? "pointer" : "default",
              },
            })}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default APIKeys;
