import { useLocation, useNavigate } from "react-router-dom";
import PrivacyComponent from "./PrivacyComponent";
import EncryptionKeys from "components/EncryptionKeys/EncryptionKeys";
import Card from "antd/es/card";
import { Tabs } from "antd";
import APIKeys from "components/APIKeys/APIKeys";
import { Certificate } from "crypto";
import Certificates from "components/Certificates/Certificates";


export default function PiiFields() {
  const location = useLocation();
  const navigate = useNavigate();
  const tabKey = location.pathname.split('/').pop();

  const handleTabClick = (key) => {
    navigate(`/app-settings/privacy/${key}`);
  };

  const tabs = [
    {
      label: `Privacy`,
      key: `privacy`,
      children: <PrivacyComponent />,
    },
    {
      label: `Setup Encryption Key`,
      key: `encryption`,
      children: <EncryptionKeys />,
    },
    {
      label: `API Keys`,
      key: `api-keys`,
      children: <APIKeys />,
    },
    {
      label: `Certificates`,
      key: `certificates`,
      children: <Certificates />,
    },
  ];

  return (
    <div>
      <Card>
        <Tabs
          tabPosition="left"
          activeKey={tabKey || 'encryption'}
          onChange={handleTabClick}
          items={tabs}
        />
      </Card>
    </div>
  );
}