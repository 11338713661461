// DataUsage.tsx

import { Button, Card, Col, notification, Row, Spin } from "antd";
import { Utils } from "common/Utils";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { userAtom } from "../../common/Atoms";
import AxiosApiInstance from "../../common/Interceptors";
import { URL_API_V1 } from "../../constants/global";
import axios, { AxiosError } from "axios";

// Define the structure of each collection's data
interface DataItem {
  label: string;
  count: number;
  totalSize: number;
}

// Define the DiskUsageResponse as a record of collection names to DataItems
type DiskUsageResponse = Record<string, DataItem>;

// Define the processed data structure including "ALL", "Totals", and Categories
interface ProcessedDiskUsageResponse {
  ALL: DataItem;
  Totals: { [category: string]: DataItem };
  Categories: { [category: string]: { [collectionName: string]: DataItem } };
}

// Define the shape of dataProps state
interface DataProps {
  loading: boolean;
  hasData: boolean;
  data: ProcessedDiskUsageResponse | null;
}

const DataUsage: React.FC = () => {
  const { axiosApiInstance } = AxiosApiInstance();
  const [user] = useRecoilState(userAtom);

  const [dataProps, setDataProps] = useState<DataProps>({
    loading: true,
    hasData: false,
    data: null,
  });

  /**
   * Validates whether an object conforms to the DataItem interface.
   *
   * @param obj - The object to validate.
   * @returns True if valid, false otherwise.
   */
  const isValidDataItem = (obj: any): obj is DataItem => {
    return (
      obj &&
      typeof obj.label === "string" &&
      typeof obj.count === "number" &&
      typeof obj.totalSize === "number"
    );
  };

  /**
   * Mapping of collection names to categories.
   * Adjust this mapping based on your actual categories.
   */
  const categoryMap: { [collectionName: string]: string } = {
    "devices": "Devices",
    "devices_listening_port": "Devices",
    "vulnerabilities": "Security",
    "events": "Events",
    // Add more collections and their categories as needed
  };

  /**
   * Fetches disk usage data from the API.
   */
  const getDiskUsage = async () => {
    setDataProps((prevProps) => ({ ...prevProps, loading: true }));

    try {
      const response = await axiosApiInstance.get<DiskUsageResponse>(
        `${URL_API_V1}/r/${user.organization}/disk-usage/`,
        {
          params: { 'data-type': 'all' }, // Adjust or remove if not needed
        }
      );

      if (response.status === 200 && response.data) {
        const rawData = response.data;

        // Initialize categories and totals
        const categories: { [category: string]: { [collectionName: string]: DataItem } } = {};
        const totalsPerCategory: { [category: string]: DataItem } = {};
        let overallCount = 0;
        let overallTotalSize = 0;

        // Iterate over each collection
        Object.entries(rawData).forEach(([collectionName, dataItem]) => {
          if (isValidDataItem(dataItem)) {
            const category = categoryMap[collectionName] || "Others";
            if (!categories[category]) {
              categories[category] = {};
              totalsPerCategory[category] = {
                label: `${category} Totals`,
                count: 0,
                totalSize: 0,
              };
            }
            categories[category][collectionName] = dataItem;
            totalsPerCategory[category].count += dataItem.count;
            totalsPerCategory[category].totalSize += dataItem.totalSize;
            overallCount += dataItem.count;
            overallTotalSize += dataItem.totalSize;
          }
        });

        // Compute "ALL"
        const allData: DataItem = {
          label: "ALL",
          count: overallCount,
          totalSize: overallTotalSize,
        };

        // Compute "Totals" per category
        const totalsData = totalsPerCategory;

        // Construct the processed data
        const processedData: ProcessedDiskUsageResponse = {
          ALL: allData,
          Totals: totalsData,
          Categories: categories,
        };

        const hasValidData = overallCount > 0 || overallTotalSize > 0;

        setDataProps({
          loading: false,
          hasData: hasValidData,
          data: hasValidData ? processedData : null,
        });

      } else {
        console.warn(`Unexpected response status: ${response.status}`);
        setDataProps((prevProps) => ({ ...prevProps, loading: false }));
        notification.warning({
          message: "Warning",
          description: `Unexpected response status: ${response.status}`,
        });
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error("Error fetching disk usage:", axiosError.message);
      setDataProps((prevProps) => ({ ...prevProps, loading: false }));
      notification.error({
        message: "Error",
        description: "Failed to fetch disk usage data.",
      });
    }
  };

  /**
   * Deletes data based on the specified category and type.
   *
   * @param dataCategory - The category of data to delete.
   * @param dataType - The type of data to delete.
   */
  const deleteData = async (dataCategory: string, dataType: string) => {
    console.log("deleteData: " + dataCategory + " " + dataType);
    setDataProps((prevProps) => ({ ...prevProps, loading: true }));

    try {
      const response = await axiosApiInstance.delete(
        `${URL_API_V1}/r/${user.organization}/data/`,
        {
          params: { 'data-category': dataCategory, 'data-type': dataType },
        }
      );

      if (response.status === 200) {
        let typeOfData = dataType === "all" ? "" : Utils.titleCase(dataType);
        notification.success({
          message: `Deleted ${dataCategory} ${typeOfData}`,
        });
        getDiskUsage();
      } else {
        console.warn(`Unexpected response status: ${response.status}`);
        notification.warning({
          message: "Warning",
          description: `Unexpected response status: ${response.status}`,
        });
        setDataProps((prevProps) => ({ ...prevProps, loading: false }));
      }
    } catch (exception) {
      const axiosError = exception as AxiosError;
      console.error("deleteData error:", axiosError.message);
      notification.error({
        message: "Error",
        description: "Failed to delete data.",
      });
      setDataProps((prevProps) => ({ ...prevProps, loading: false }));
    }
  };

  useEffect(() => {
    getDiskUsage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Sorts the entries for consistent ordering.
   *
   * @param a - First entry.
   * @param b - Second entry.
   * @returns Sorting order.
   */
  const sortEntries = (a: string, b: string) => {
    if (a === "ALL") return -1;
    if (b === "ALL") return 1;
    return a.localeCompare(b);
  };

  /**
   * Generates a sorted list of entries including "ALL", "Totals", and categories.
   *
   * @returns An array containing "ALL", "Totals", and categorized collections.
   */
  const generateDisplayData = () => {
    const { data } = dataProps;
    if (!data) return [];

    const sortedKeys = Object.keys(data).sort(sortEntries);
    const displayData: Array<
      | { type: "ALL"; name: string; dataItem: DataItem }
      | { type: "Category"; name: string; categoryData: { [collectionName: string]: DataItem } }
    > = [];

    sortedKeys.forEach((key) => {
      if (key === "ALL") {
        const item = data[key];
        if (isValidDataItem(item)) {
          displayData.push({
            type: "ALL",
            name: item.label,
            dataItem: item,
          });
        }
      } else if (key === "Categories") {
        const categories = data[key] as unknown as { [category: string]: { [collectionName: string]: DataItem } };
        Object.keys(categories).sort().forEach((categoryName) => {
          displayData.push({
            type: "Category",
            name: Utils.titleCase(categoryName),
            categoryData: categories[categoryName],
          });
        });
      }
    });

    return displayData;
  };

  const displayData = generateDisplayData();

  return (
    <div>
      <Card>
        <Row>
          <Col>
            <h3>Disk Usage</h3>
            <p>View your disk usage and manage your data</p>
          </Col>
          <Col span={24}>
            <div style={{ textAlign: "right", marginBottom: "15px" }}>
              {/* Uncomment and adjust the Popover and CheckableTag as needed */}
              {/*
              <Popover
                placement="topLeft"
                arrowContent
                content="Activate filter to display only Demo-Data sampling"
              >
                <CheckableTag
                  onChange={() =>
                    setDataCategory(
                      dataCategory === "all" ? "demo-sampling" : "all"
                    )
                  }
                  key="demo-sampling"
                  checked={dataCategory === "demo-sampling"}
                >
                  Demo-Sampling
                </CheckableTag>
              </Popover>
              */}
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {displayData.map((entry, index) => {
            if (entry.type === "ALL") {
              const dataItem = entry.dataItem;
              return (
                <Col key="ALL" xs={24} sm={12} md={8} lg={6}>
                  <Card>
                    <Row>
                      <Col>
                        <h4>{dataItem?.label || "ALL"}</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col>Number of records: {dataItem?.count}</Col>
                    </Row>
                    <Row>
                      <Col>Disk usage: {Utils.formatBytes(dataItem?.totalSize || 0)}</Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <div style={{ textAlign: "right", marginTop: "10px" }}>
                          <Button
                            onClick={() => deleteData("ALL", "all")}
                            style={{ fontSize: "small" }}
                            type="dashed"
                            disabled={dataProps.loading}
                          >
                            Delete data
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            } else if (entry.type === "Category" && entry.categoryData) {
              const categoryName = entry.name;
              const sortedCollections = Object.keys(entry.categoryData).sort();
              return (
                <div key={`category-${index}`} style={{ width: "100%", marginBottom: "20px" }}>
                  <h4>{categoryName}</h4>
                  <Row gutter={[16, 16]}>
                    {sortedCollections.map((collectionName) => {
                      const dataItem = entry.categoryData![collectionName];
                      return (
                        <Col key={collectionName} xs={24} sm={12} md={8} lg={6}>
                          <Card>
                            <Row>
                              <Col>
                                <h4>{Utils.titleCase(dataItem.label)}</h4>
                              </Col>
                            </Row>
                            <Row>
                              <Col>Number of records: {dataItem.count}</Col>
                            </Row>
                            <Row>
                              <Col>Disk usage: {Utils.formatBytes(dataItem.totalSize)}</Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                <div style={{ textAlign: "right", marginTop: "10px" }}>
                                  <Button
                                    onClick={() => deleteData(categoryName, collectionName)}
                                    style={{ fontSize: "small" }}
                                    type="dashed"
                                    disabled={dataProps.loading} // Disable button while loading
                                  >
                                    Delete data
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              );
            } else {
              return null;
            }
          })}
        </Row>
        {dataProps.loading && (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Spin tip="Loading..." />
          </div>
        )}
        {!dataProps.loading && (!dataProps.data || Object.keys(dataProps.data).length === 0) && (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <p>No data available.</p>
          </div>
        )}
      </Card>
    </div>
  );
};

export default DataUsage;
